'use strict';

window.Components = function (Components) {
    class UniversalLoaderBase extends Components.Audioserver.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments); // services might be current targets, so watch out for changes.

            this.registerExtensionEv(this.component.ECEvent.ServiceChanged, this._handleServiceChanged.bind(this));
        }

        /**
         * The getAcquireCommandArguments of the MediaLoaderBase provides id, start and nItems, here we simply add
         * the targetUID.
         * @returns {string} the arguments that will be appended to the acquireCommand
         */
        getAcquireCommandArguments() {
            return this._getTargetUID() + "/" + super.getAcquireCommandArguments(...arguments);
        }

        requestContentBatch(id, nItems, mediaTypeDetails) {
            if (this._updateTargetType(mediaTypeDetails)) {
                return super.requestContentBatch(...arguments);
            } else {
                // the download won't be successful, since we couldn't update the target!
                var def = Q.defer();
                setTimeout(function () {
                    def.reject("App Error, no valid target provided.");
                }, 5);
                return {
                    promise: def.promise
                };
            }
        }

        /**
         * Intercepted to watch over the mediaTypeDetails which provide info on the target (service, playlists, ..)
         * @returns {*}
         */
        requestContent(id, nItems, mediaTypeDetails) {
            if (this._updateTargetType(mediaTypeDetails)) {
                return super.requestContent(...arguments);
            } else {
                // the download won't be successful, since we couldn't update the target!
                var def = Q.defer();
                setTimeout(function () {
                    def.reject("App Error, no valid target provided.");
                }, 5);
                return {
                    promise: def.promise
                };
            }
        }

        /**
         * Return all data that has currently been loaded.
         * @param id
         * @param mediaTypeDetails
         */
        getCurrentContent(id, mediaTypeDetails) {
            if (this._updateTargetType(mediaTypeDetails)) {
                return super.getCurrentContent(...arguments);
            } else {
                throw new Error("App Error, no valid target provided.");
            }

            return result;
        }

        /**
         * Returns the current targets UID. Returns 'false' if there is no current target. The UID consists of
         * the target-id (spotify, googlemusic) and the user. If there is no user, nouser is used.
         * @returns {*}     either the uid or false if no target is active.
         * @private
         */
        _getTargetUID() {
            if (!this.target) {
                return false;
            } else {
                return this.target[MusicServerEnum.Attr.SERVICE.UID].toLowerCase(); //e.g. lms/nouser vs lms/noUser
            }
        }

        /**
         * Used to updated the current target when a request is processed. It'll look for service in the details,
         * this is a relict of previous versions where only services where requested this way.
         * @param mediaTypeDetails
         * @returns {boolean}     weather or not a valid target could be determined.
         * @private
         */
        _updateTargetType(mediaTypeDetails) {
            var result = false;

            if (mediaTypeDetails && mediaTypeDetails.hasOwnProperty(MusicServerEnum.Attr.SERVICE._)) {
                var newService = mediaTypeDetails[MusicServerEnum.Attr.SERVICE._];

                if (!this.target) {
                    Debug.Media.Loader && console.log(this.name, "No service so far, nothing to do");
                } else if (this.target[MusicServerEnum.Attr.SERVICE.UID] !== newService[MusicServerEnum.Attr.SERVICE.UID]) {
                    Debug.Media.Loader && console.log(this.name, "The service has changed from " + this.target ? this.target[MusicServerEnum.Attr.SERVICE.UID] : "no service" + "" + " to " + newService[MusicServerEnum.Attr.SERVICE.UID] + " - resetting the cache"); // the service did change, respond by invalidating all the cache

                    this.cache = {};
                }

                result = true;
                this.target = newService;
            } else {
                console.error("No target info in mediaTypeDetails - resetting target!");
                this.target = null;
            }

            return result;
        }

        /**
         * Called when a service changes, e.g. a new one is added or an existing one is removed. Targets might be
         * services, so keep that in mind.
         * @param id
         * @param event contains the services UID.
         * @private
         */
        _handleServiceChanged(id, event) {
            if (this.target && this._getTargetUID() === event[MusicServerEnum.Attr.SERVICE.UID]) {
                // whoops, the current service is affected.
                var reason = event.action; //user add, del, update

                this.rejectAndDeleteAllRequests(reason);
                this.invalidateContentCachesOf(0, reason, true);
            }
        }

    }

    /**
     * Used to download both a listing of all playists on a music server (like the OldPlaylistLoader does), but this one
     * is also used to download the content of a playlist. Plus, using this request the response will be both browsable
     * and editable playlists.
     *
     * ID 0 === Return a list of playlists.
     * ID x === Return the content of the playlist x.
     */

    /**
     * This (abstract) baseclass can be used to download all data from all kinds of targets (services, library, playists..),
     * using commands such as getplaylists2/{target}/{user}/{id}/{start}/{howmany}.
     *
     * It also keeps in mind that services or alike could change while the UI is active and it'll dispatch a reload event.
     */
    Components.Audioserver.extensions.UniversalLoaderBase = UniversalLoaderBase;
    return Components;
}(window.Components || {});
