'use strict';

window.Components = function (Components) {
    class VoiceRecorderExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Sends a play command to the given or to the current MediaServer
         * @param settings
         * @param zonesToPlay
         * @returns promise
         */
        playRecordedFile(settings, zonesToPlay) {
            const cmd = Commands.format(MusicServerEnum.AudioCommands.ANNOUNCEMENT.PLAY, settings.filename, zonesToPlay.join(","));
            Debug.Media.Announcement && console.log(this.name, "playRecordedFile " + cmd);
            return this.component.sendMediaServerCommand(cmd);
        }

        /**
         * uploads a file to the given host or the current Media Server
         * @param settings
         * @returns {*}
         */
        uploadAudioFile(settings) {
            var url,
                weakThis = this,
                errorText = _('error.occured') + ' ' + _('please-try-again'),
                def = Q.defer();

            url = Commands.format(MusicServerEnum.AudioCommands.ANNOUNCEMENT.UPLOAD, this.component.connectionUrl, settings.filename); // Due to a bug on the Audioserver we can't upload the recording file locally in a direct peer to peer connection with HTTPS
            // There seems to be an issue with the HTTP2 implementation

            if (this.component.isP2PConnection) {
                url = url.replace("https://", "http://");
            }

            Debug.Media.Announcement && console.log(this.name, "uploadAudioFile to " + url);
            return this._getFileWithSettings(settings).then(function (fileBuffer) {
                Debug.Media.Announcement && console.log(weakThis.name, "uploadAudioFile > got file, not upload it");
                Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.AUDIO_HTTP, url, fileBuffer);
                $.ajax({
                    xhr: function () {
                        var xhr = new window.XMLHttpRequest(); //Upload progress

                        xhr.upload.addEventListener("progress", function (evt) {
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total * 100;
                                Debug.Media.Announcement && console.log(weakThis.name, "Upload Progress: " + percentComplete + "%");
                            }
                        }, false);
                        return xhr;
                    },
                    url: url,
                    type: 'PUT',
                    data: fileBuffer,
                    processData: false,
                    contentType: "audio/wav",
                    dataType: "json"
                }).done(function (response) {
                    Debug.Media.Announcement && console.log(weakThis.name, "uploadAudioFile > uploading done - " + url);
                    def.resolve();
                }).fail(function (xhr, errorText, error) {
                    console.error(weakThis.name, "uploadAudioFile > uploading failed! - " + url + ", txt=" + errorText)
                    console.error(error.message || JSON.stringify(error));

                    weakThis._showErrorPopup(_('error'), errorText);

                    def.reject(errorText);
                });
                return def.promise;
            }, function (e) {
                console.error(weakThis.name, "uploadAudioFile > failed to get file! " + JSON.stringify(settings));
                console.error(e.message || JSON.stringify(e));

                weakThis._showErrorPopup(_('error'), errorText);

                def.reject(errorText);
                return def.promise;
            }.bind(this));
        }

        _getFileWithSettings(settings) {
            Debug.Media.Announcement && console.log(this.name, "_getFileWithSettings: " + JSON.stringify(settings));
            var def = Q.defer(),
                platform = PlatformComponent.getPlatformInfoObj().platform,
                xhr = new XMLHttpRequest();

            switch (platform) {
                case PlatformType.IOS:
                case PlatformType.Android:
                    window.resolveLocalFileSystemURL(settings.pathWithoutName, function (dir) {
                        Debug.Media.Announcement && console.log(this.name, "_getFileWithSettings > resolveLocalFileSystemURL done, get file handle");
                        dir.getFile(settings.filename, {
                            create: false
                        }, function (fileEntry) {
                            Debug.Media.Announcement && console.log(this.name, "_getFileWithSettings > got file handle, read file");
                            fileEntry.file(function (file) {
                                Debug.Media.Announcement && console.log(this.name, "_getFileWithSettings > got file - start reader");
                                var reader = new FileReader();

                                reader.onloadend = function ({ target }) {
                                    Debug.Media.Announcement && console.log(this.name, "_getFileWithSettings > finished reading!");
                                    def.resolve(new Uint8Array(target.result));
                                }.bind(this);
                                reader.onerror = function (err) {
                                    console.error(this.name, "_getFileWithSettings > failed reading! " + (err.message || JSON.stringify(err)));
                                    def.reject(err);
                                }.bind(this);

                                reader.readAsArrayBuffer(file);
                            }.bind(this));
                        }.bind(this), (err) => {
                            console.error(this.name, "_getFileWithSettings > failed to get a file handle! " + (err.message || JSON.stringify(err)));
                            def.reject(err);
                        });
                    }.bind(this), (err) => {
                        console.error(this.name, "_getFileWithSettings > failed to resolveLocalFileSystemURL! " + (err.message || JSON.stringify(err)));
                        def.reject(err);
                    });
                    break;

                case PlatformType.DeveloperInterface:
                    this._resolveFsFilePath(settings).done(function (resolved) {
                        xhr.open("GET", resolved, true);

                        xhr.onreadystatechange = function () {
                            if (this.readyState === 4) {
                                if (this.status !== 404) {
                                    def.resolve(this.response);
                                } else if (this.responseText !== "") {
                                    def.reject(this.responseText);
                                }
                            }
                        };

                        xhr.send();
                    }, function (e) {
                        weakThis._showErrorPopup(_('error'), errorText);

                        def.reject(e);
                    });

                    break;
            }

            return def.promise;
        }

        _resolveFsFilePath(settings) {
            var def = Q.defer(),
                platform = PlatformComponent.getPlatformInfoObj().platform;

            switch (platform) {
                case PlatformType.IOS:
                case PlatformType.Android:
                    window.resolveLocalFileSystemURL(settings.path, function (dir) {
                        def.resolve(dir.nativeURL);
                    });
                    break;

                case PlatformType.DeveloperInterface:
                    def.resolve(settings.path);
                    break;
            }

            return def.promise;
        }

        /**
         * Deletes a file from the local file storage
         * @param settings
         */
        deleteFile(settings) {
            var def = Q.defer();

            switch (PlatformComponent.getPlatformInfoObj().platform) {
                case PlatformType.IOS:
                case PlatformType.Android:
                    window.resolveLocalFileSystemURL(settings.pathWithoutName, function (dir) {
                        dir.getFile(settings.filename, {
                            create: false
                        }, function (fileEntry) {
                            fileEntry.remove(function (res) {
                                def.resolve();
                                console.log("Removed file");
                            }, function (error) {
                                def.reject(error);
                                console.log("Error: file was not removed " + error);
                            });
                        });
                    }.bind(this));
                    break;

                default:
                    def.resolve();
            }

            return def.promise;
        }

        _showErrorPopup(title, message) {
            return NavigationComp.showPopup({
                title: title,
                message: message,
                buttonOk: true,
                buttonCancel: false,
                icon: Icon.WARNING,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL);
        }

    }

    Components.Audioserver.extensions.VoiceRecorderExt = VoiceRecorderExt;
    return Components;
}(window.Components || {});
