'use strict';

window.Components = function (Components) {
    class SearchDetailLoader extends Components.Audioserver.extensions.ServiceLoader {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        getAcquireCommand(mtd, doesHandleCheck) {
            if (!this.service) {
                !doesHandleCheck && console.error(this.name, "getAcquireCommand failed, no service specified yet! (SearchDetailLoader)");
                return false;
            } else {
                return 'searchdetails/' + this.service[MusicServerEnum.Attr.SERVICE.UID];
            }
        }

    }

    Components.Audioserver.extensions.SearchDetailLoader = SearchDetailLoader;
    return Components;
}(window.Components || {});
