'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var SEARCH_FILE_NAME = 'globalSearch',
            MAX_SEARCH_RESULTS = 5;

        class GlobalSearchExt extends Components.Audioserver.extensions.SearchExtBase {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.username = MusicServerEnum.NOUSER;
                this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                    if (result.oldCommand === MusicServerEnum.GlobalSearch && this.globalSearchDefer && result.unique === this.globalSearchDefer.unique) {
                        this.globalSearchDefer.notify(this._processResult(result));
                    }
                }.bind(this));
            }

            searchFor(delegate, keyword) {
                var command = "";
                this.delegate = delegate;
                this.cachedTables = {};

                if (this.globalSearchDefer) {
                    this.globalSearchDefer.reject();
                    this.globalSearchDefer = null;
                    this.globalSearchDefer = Q.defer();
                } else {
                    this.globalSearchDefer = Q.defer();
                }

                this._getAvailableSources().then(function (path) {
                    // Music Server only handles encoded urls
                    command = path + '/' + encodeURIComponent(keyword);
                    Debug.Control.AudioZone.GlobalSearch && console.log(this.name + "GlobalSearch Command: " + command);
                    return this.component.sendMediaServerCommand(command).then(function (res) {
                        this.globalSearchDefer.unique = res.globalsearch_result.unique;
                    }.bind(this));
                }.bind(this));

                return this.globalSearchDefer.promise;
            }

            saveKeyword(identifier, keyword, controlUuid) {
                if (!keyword) {
                    return Q.resolve();
                }

                return this._getCurrentSearchFile(identifier, controlUuid).then(function (currentFile) {
                    if (currentFile[controlUuid]) {
                        if (currentFile[controlUuid][identifier]) {
                            if (!currentFile[controlUuid][identifier].includes(keyword)) {
                                currentFile[controlUuid][identifier].push(keyword);
                                currentFile[controlUuid][identifier].splice(0, currentFile[controlUuid][identifier].length - 4);
                            }
                        } else {
                            currentFile[controlUuid][identifier] = [];
                            currentFile[controlUuid][identifier].push(keyword);
                        }
                    } else {
                        currentFile[controlUuid] = {};
                        currentFile[controlUuid][identifier] = [];
                        currentFile[controlUuid][identifier].push(keyword);
                    }

                    return PersistenceComponent.setShared(MusicServerEnum.CUSTOMIZATION_KEYS.SEARCH_HISTORY, currentFile);
                });
            }

            deleteKeywords(identifier, controlUuid) {
                return this._getCurrentSearchFile(identifier, controlUuid).then(function (currentFile) {
                    if (currentFile[controlUuid]) {
                        if (currentFile[controlUuid][identifier]) {
                            delete currentFile[controlUuid][identifier];
                            return PersistenceComponent.setShared(MusicServerEnum.CUSTOMIZATION_KEYS.SEARCH_HISTORY, currentFile);
                        }
                    }
                }.bind(this));
            }

            loadLastUsedKeywords(identifier, controlUuid) {
                return this._getCurrentSearchFile(identifier, controlUuid).then(function (currentFile) {
                    if (currentFile.hasOwnProperty(controlUuid)) {
                        return currentFile[controlUuid][identifier];
                    } else {
                        return [];
                    }
                });
            }

            getCurrentSpotifyUsername() {
                return this.username;
            }

            getPreparedTableContent(type) {
                var returnContent;

                if (this.cachedTables) {
                    switch (type) {
                        case MusicServerEnum.Search_Result.LOCAL:
                            returnContent = this.cachedTables[MusicServerEnum.Search_Result.LOCAL];
                            break;

                        case MusicServerEnum.Search_Result.TUNE_IN:
                            returnContent = this.cachedTables[MusicServerEnum.Search_Result.TUNE_IN];
                            break;

                        case MusicServerEnum.Search_Result.SPOTIFY:
                            returnContent = this.cachedTables[MusicServerEnum.Search_Result.SPOTIFY];
                            break;

                        default:
                            returnContent = [];
                            break;
                    }
                }

                return returnContent;
            }

            createAudioResultCell(item, type) {
                Controls.AudioZoneV2Control.MediaBrowserV2Base.applyContentTypeToItem(item);

                var isBrowsable = this.component.isFileTypeBrowsable(item[MusicServerEnum.Event.FILE_TYPE]),
                    identifier = this._getIdentifierFromType(type),
                    username = this._getUsernameForType(type),
                    buttonTapped,
                    action,
                    showStateArgs,
                    weakThis = this,
                    details = {
                        username: username,
                        lastSelectedItem: item,
                        identifier: identifier,
                        contentTypes: [item.contentType],

                        get mediaType() {
                            return weakThis.delegate.mediaType;
                        },

                        get service() {
                            return weakThis.delegate.getMediaInfo(item.contentType).service;
                        },

                        control: this.delegate.control
                    };

                if (this.delegate.ViewController instanceof GUI.AudioZoneV2ControlContentViewController || this.delegate.ViewController instanceof GUI.AudioZoneV2ControlDetailedContentViewController) {
                    if (this.delegate._showContextMenuForItem) {
                        buttonTapped = this.delegate._showContextMenuForItem.bind(this.delegate, item, details);
                    }
                } else if (this.delegate.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                    buttonTapped = this.delegate.ViewController.addItem.bind(this.delegate.ViewController, item, this.delegate.getMediaInfo());
                }

                if (isBrowsable) {
                    action = function () {
                        showStateArgs = [Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details];
                        this.delegate.ViewController.showState.apply(this.delegate.ViewController, showStateArgs);
                    }.bind(this);
                } else {
                    action = this.component.sendPlayerCommandFromType.bind(this.component, item, null, details);
                }

                return this._getMediaBrowserFromType(type).getCellFromContentTypeItem(item, {
                    identifier: this._getIdentifierFromType(type),
                    username: this.username
                }, item.contentType, false, isBrowsable, action, buttonTapped, this.delegate.ViewController);
            }

            // ----------------
            // Private Section
            // ----------------
            _getIdentifierFromType(type) {
                if (type === MusicServerEnum.Search_Result.LOCAL || type === MusicServerEnum.Search_Result.TUNE_IN) {
                    return MusicServerEnum.Service.LOCAL;
                } else if (type === MusicServerEnum.Search_Result.SPOTIFY) {
                    return MusicServerEnum.Service.SPOTIFY;
                }
            }

            _getUsernameForType(type) {
                if (type === MusicServerEnum.Search_Result.SPOTIFY) {
                    return this.username;
                } else {
                    return MusicServerEnum.NOUSER;
                }
            }

            _getMediaBrowserFromType(type) {
                if (type === MusicServerEnum.Search_Result.LOCAL) {
                    return Controls.AudioZoneV2Control.MediaBrowserV2Library;
                } else if (type === MusicServerEnum.Search_Result.SPOTIFY || type === MusicServerEnum.Search_Result.TUNE_IN) {
                    return Controls.AudioZoneV2Control.MediaBrowserV2Service;
                } else {
                    return Controls.AudioZoneV2Control.MediaBrowserV2Base;
                }
            }

            _getAvailableSources() {
                var path = MusicServerEnum.Commands.GLOBAL_SEARCH.BASE_CMD,
                    arr = [],
                    username = "";
                return this.component.getCurrentSpotifyId(true).then(function (res) {
                    this.username = res;
                    Debug.Control.AudioZone.GlobalSearch && console.log(this.name + ": Saved Spotify Username: " + this.username);
                    return this.component.getCurrentServices().promise.then(function (servicesObject) {
                        if (servicesObject.length) {
                            username = servicesObject[0].user;

                            if (!this.username) {
                                this.username = username;
                            }
                        }

                        return this.component.sendMediaServerCommand(MusicServerEnum.Commands.GLOBAL_SEARCH.DESCRIBE).then(function (res) {
                            Object.keys(res.globalsearch_result).forEach(function (key) {
                                if (key === MusicServerEnum.Service.SPOTIFY && this.username) {
                                    arr.push(key + '@' + this.username + ':' + res.globalsearch_result[key].join('#' + MAX_SEARCH_RESULTS + ",") + '#' + MAX_SEARCH_RESULTS);
                                } else {
                                    arr.push(key + ':' + res.globalsearch_result[key].join('#' + MAX_SEARCH_RESULTS + ",") + '#' + MAX_SEARCH_RESULTS);
                                }
                            }.bind(this));
                            path += arr.join('|');
                            return path;
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            }

            _processResult(result) {
                var update;

                if (result.type) {
                    update = {
                        type: result.type
                    };
                    Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "_processResult: " + result.type + " = " + Object.keys(result.globalsearch_result).length + " results", result);

                    switch (result.type) {
                        case MusicServerEnum.Search_Result.LOCAL:
                        case MusicServerEnum.Search_Result.TUNE_IN:
                            update.tableContent = this._createSpecificTableContent(result.globalsearch_result, result.type, this.delegate);
                            break;

                        case MusicServerEnum.Search_Result.SPOTIFY:
                            update.tableContent = this._createSpecificTableContent(result.globalsearch_result.result, result.type, this.delegate);
                            break;
                    }
                }

                return update;
            }

            _createSpecificTableContent(result, type, delegate) {
                var content = [],
                    section;
                result && Object.keys(result).forEach(function (key) {
                    section = {
                        rows: [],
                        type: key
                    };

                    if (key === "playlists") {
                        result[key].caption = _("media.playlists");
                    }

                    section.headerTitle = result[key].caption;

                    if (result[key].link) {
                        section.sectionRightButtonTitle = _('media.global-search-button.show-more');
                        section.sectionRightButtonColor = window.Styles.colors.brand;
                        section.link = result[key].link;
                    }

                    if (key === 'topresults') {//this.topResultsSection = section;
                    } else if (key !== MusicServerEnum.Attr.SERVICE.USER) {
                        if (!result[key].items) {
                            console.warn('no items in result: ' + key);
                        } else {
                            section.rows = result[key].items.map(function (item) {
                                return this.createAudioResultCell(item, type, delegate);
                            }.bind(this));
                        }

                        if (section.rows.length) {
                            content.push(section);
                        }
                    }
                }.bind(this));

                switch (type) {
                    case MusicServerEnum.Search_Result.TUNE_IN:
                        content = content.sort(function (a, b) {
                            if (a.type === "station") {
                                return -1;
                            } else {
                                return 1;
                            }
                        });
                        break;

                    case MusicServerEnum.Search_Result.SPOTIFY:
                        content = content.sort(function (a, b) {
                            if (a.type === "playlists") {
                                return -1;
                            } else {
                                return 1;
                            }
                        });
                        break;
                }

                this.cachedTables[type] = content;
                return content;
            }

            /**
             * Ensures we always work with a correct search file with all the required entries
             * @param identifier
             * @param controlUuid
             * @return {*}
             * @private
             */
            _getCurrentSearchFile(identifier, controlUuid) {
                return PersistenceComponent.getShared(MusicServerEnum.CUSTOMIZATION_KEYS.SEARCH_HISTORY).then(function (res) {
                    if (!res) {
                        var emptySearch = {};
                        emptySearch[controlUuid] = {};
                        emptySearch[controlUuid][identifier] = [];
                        res = emptySearch;
                    }

                    return res;
                }, function () {
                    var emptySearch = {};
                    emptySearch[controlUuid] = {};
                    emptySearch[controlUuid][identifier] = [];
                    return emptySearch;
                });
            }

        }

        Components.Audioserver.extensions.GlobalSearchExt = GlobalSearchExt;
    }
    return Components;
}(window.Components || {});
