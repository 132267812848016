'use strict';

window.Components = function (Components) {
    class SearchExtBase extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Starts the search
         * returns {Promise}
         */
        searchFor() {
            //fast-class-es6-converter: auto generated
            throw "Implement the 'searchFor' function in your class";
        }

        createAudioResultCell(item, type, delegate) {
            Controls.AudioZoneV2Control.MediaBrowserV2Base.applyContentTypeToItem(item);

            var isBrowsable = this.component.isFileTypeBrowsable(item[MusicServerEnum.Event.FILE_TYPE]),
                identifier = this._getIdentifierFromType(type),
                username = this._getUsernameForType(type),
                buttonTapped,
                action,
                showStateArgs;

            if (delegate.ViewController instanceof GUI.AudioZoneV2ControlContentViewController || delegate.ViewController instanceof GUI.AudioZoneV2ControlDetailedViewController) {
                if (delegate._showContextMenuForItem) {
                    buttonTapped = delegate._showContextMenuForItem.bind(delegate, item);
                }
            } else if (delegate.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                buttonTapped = delegate.ViewController.addItem.bind(delegate.ViewController, item, {
                    service: {
                        cmd: identifier + "/" + username
                    }
                });
            }

            if (isBrowsable) {
                action = function () {
                    showStateArgs = [Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, {
                        username: username,
                        lastSelectedItem: item,
                        identifier: identifier,
                        contentTypes: [delegate.contentType],
                        mediaType: delegate.mediaType
                    }];
                    delegate.ViewController.showState.apply(delegate.ViewController, showStateArgs);
                }.bind(this);
            } else {
                action = this.component.playFileUrl.bind(this, item, delegate.getMediaInfo(item.contentType));
            }

            return this._getMediaBrowserFromType(type).getCellFromContentTypeItem(item, {
                identifier: this._getIdentifierFromType(type),
                username: username
            }, item.contentType, false, isBrowsable, action, buttonTapped, delegate.ViewController);
        }

        _createSpecificTableContent() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_createSpecificTableContent' function in your class";
        }

        _getIdentifierFromType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getIdentifierFromType' function in your class";
        }

        _getUsernameForType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getUsernameForType' function in your class";
        }

        _getMediaBrowserFromType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getMediaBrowserFromType' function in your class";
        }

    }

    Components.Audioserver.extensions.SearchExtBase = SearchExtBase;
    return Components;
}(window.Components || {});
