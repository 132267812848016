'use strict';

window.Components = function (Components) {
    class RoomFavoriteLoader extends Components.Audioserver.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        getAcquireCommand() {
            return MusicServerEnum.AudioCommands.ZONE_FAV.GET;
        }

        _processResult(data, cmd) {
            Debug.Media.Loader && console.log("Processing Result: " + JSON.stringify(data)); // Applying the contentType

            data.items.forEach(function (item) {
                item.contentType = MusicServerEnum.MediaContentType.ZONE_FAVORITES;item.listedInFavourites = true; // required for _isContentAccountDependent-Check in mediaBrowser_Service
            }.bind(this));

            super._processResult(...arguments);
        }

        _doesHandleEvent(event) {
            return event.hasOwnProperty(MusicServerEnum.EventIdentifier.ROOMFAV_CHANGED) && this.component && this.component.activeZoneControl && event.roomfavchanged_event[0].playerid === this.component.activeZoneControl.details.playerid;
        }

        _processEvent(event) {
            this.invalidateContentCachesOf(event.roomfavchanged_event[0].playerid, MusicServerEnum.ReloadCause.CONTENT_UPDATED);
        }

    }

    Components.Audioserver.extensions.RoomFavoriteLoader = RoomFavoriteLoader;
    return Components;
}(window.Components || {});
