'use strict';

window.Components = function (Components) {
    class ZoneGroupExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.name += "@" + component.getServerName();

            if (Feature.MULTI_MUSIC_SERVER) {
                this.registerExtensionEv(this.component.ECEvent.Start, this._handleStart.bind(this));
                this.registerExtensionEv(this.component.ECEvent.Stop, this._handleStop.bind(this)); // as the "grouping" server state always only contains the last sync-event.

                this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                    if (result.command === MusicServerEnum.Commands.SYNC.GET_SYNCED_PLAYERS) {
                        this._handleGetSyncedPlayersResponse(result.data);
                    }
                }.bind(this)); // register for master volume changes!

                this.registerExtensionEv(this.component.ECEvent.EventReceived, function (evId, event) {
                    if (event.hasOwnProperty(MusicServerEnum.EventIdentifier.MASTER_VOLUME)) {
                        this._handleGroupMasterVolumeChanged(event[MusicServerEnum.EventIdentifier.MASTER_VOLUME]);
                    }
                }.bind(this));
            } else {
                this.registerExtensionEv(this.component.ECEvent.ConnEstablished, this._handleConnectionEstablished.bind(this));
                this.registerExtensionEv(this.component.ECEvent.EventReceived, function (evId, event) {
                    this._handleEvent(event);
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                    this._handleResult(result);
                }.bind(this));
            }
        }

        getCurrentGroups(forceReload) {
            Debug.Media.ZoneGroupExt && console.log(this.name, "getCurrentGroups: forceReload: " + forceReload);
            var result = {};

            if (this.currentGroups && !forceReload) {
                result.data = cloneObjectDeep(this.currentGroups);
            } else {
                this.currentGroupsDeferred = Q.defer();
                result.promise = this.currentGroupsDeferred.promise;
            }

            return result;
        }

        // -------------------------------------------------------------------------
        // PRIVATE
        // -------------------------------------------------------------------------

        /**
         * Only called with multi music server feature. Registers for state changes so that the sync info can
         * be received from the miniserver.
         * @private
         */
        _handleStart() {
            Debug.Media.ZoneGroupExt && console.log(this.name, "_handleStart");
            this.svrUuid = this.component.getActiveMediaServer().uuidAction;
            SandboxComponent.registerForStateChangesForUUID(this.svrUuid, this, this._receivedServerStates);
        }

        /**
         * Only called with multi music server feature. Unregisters from the serverStates from the miniserver.
         * @private
         */
        _handleStop() {
            Debug.Media.ZoneGroupExt && console.log(this.name, "_handleStop");
            SandboxComponent.unregisterForStateChangesForUUID(this.svrUuid, this);
        }

        /**
         * Called whenever the server state container receives an update.
         * @param states
         * @private
         */
        _receivedServerStates(states) {
            Debug.Media.ZoneGroupExt && console.log(this.name, "_receivedServerStates");
            this.groupingColors = states.groupingColors;

            this._gotZoneGroups(states.grouping);
        }

        _handleResult(result) {
            if (result.oldCommand === MusicServerEnum.Commands.SYNC.GET_SYNCED_PLAYERS_ID) {
                Debug.Media.ZoneGroupExt && console.log(this.name + " received an result " + JSON.stringify(result)); // every group needs a color.

                this.component.assignZoneGroupColors(result.data, this.groupingColors);

                this._gotZoneGroups(result.data);
            }
        }

        /**
         * Handles incoming zones, either from the stateContainer or from the media server
         * @param newGroups array of zonegroups, already "colored"
         * @private
         */
        _gotZoneGroups(newGroups) {
            Debug.Media.ZoneGroupExt && console.log(this.name, "_gotZoneGroups: " + JSON.stringify(newGroups));
            var oldGroups = this.currentGroups ? this.currentGroups : []; // Update the current groups var

            this.currentGroups = cloneObjectDeep(newGroups); // check if someone is waiting for a new synclisting, if so dispatch.

            if (this.currentGroupsDeferred) {
                this.currentGroupsDeferred.resolve(newGroups);
                this.currentGroupsDeferred = null;
            }

            if (JSON.stringify(newGroups) !== JSON.stringify(oldGroups) && !Feature.MULTI_MUSIC_SERVER) {
                Debug.Media.ZoneGroupExt && console.log("         the syncing did change, inform all zones!"); // inform all zones that were previously part of a group or that are now part of it

                this._informAllZones(oldGroups, newGroups);
            }
        }

        _handleEvent(event) {
            var key = Object.keys(event)[0];

            if (key === MusicServerEnum.EventIdentifier.AUDIO.SYNC) {
                Debug.Media.ZoneGroupExt && console.log(this.name + " received an event " + JSON.stringify(event));

                this._requestGroups();
            }
        }

        _handleConnectionEstablished() {
            Debug.Media.ZoneGroupExt && console.log(this.name + " handles connection established");

            this._requestGroups();
        }

        _requestGroups() {
            if (Feature.MULTI_MUSIC_SERVER) {// the synced zones are received from the Miniserver via the mediaStateContainer!
            } else {
                Debug.Media.ZoneGroupExt && console.log(this.name + " requests new groups list");
                this.channel.emit(this.component.ECEvent.SendMessage, {
                    cmd: MusicServerEnum.Commands.SYNC.GET_SYNCED_PLAYERS,
                    auto: true
                });
            }
        }

        _getZonesToUpdate(oldGroups, newGroups) {
            Debug.Media.ZoneGroupExt && console.log(this.name + " _getZonesToUpdate: Old " + oldGroups.length + " - New " + newGroups.length);
            var zonesToUpdate = {};
            var totalGroups = oldGroups.concat(newGroups);

            for (var i = 0; i < totalGroups.length; i++) {
                var groupObj = totalGroups[i];
                var syncedZones = [];

                if (groupObj.hasOwnProperty(MusicServerEnum.Event.PLAYERS)) {
                    syncedZones = groupObj[MusicServerEnum.Event.PLAYERS];
                } // iterate over synced zones


                for (var j = 0; j < syncedZones.length; j++) {
                    var currZone = syncedZones[j];
                    zonesToUpdate[currZone[MusicServerEnum.Event.PLAYER_ID]] = currZone;
                }
            }

            Debug.Media.ZoneGroupExt && console.log("       zones to update: " + Object.keys(zonesToUpdate).length);
            return zonesToUpdate;
        }

        // inform all zones that were previously part of a group or that are now part of it
        _informAllZones(oldGroups, newGroups) {
            Debug.Media.ZoneGroupExt && console.log(this.name + " _informAllZones: Old " + oldGroups.length + " - New " + newGroups.length);

            var zonesToUpdate = this._getZonesToUpdate(oldGroups, newGroups);

            var i;
            var internalEventData = {};
            var internalEvent = {};

            for (i = 0; i < newGroups.length; i++) {
                var groupObj = newGroups[i];
                var syncedZones = [];

                if (groupObj.hasOwnProperty(MusicServerEnum.Event.PLAYERS)) {
                    syncedZones = groupObj[MusicServerEnum.Event.PLAYERS];
                }

                internalEventData[MusicServerEnum.Event.SYNCED_ZONES] = syncedZones;
                internalEventData[MusicServerEnum.Event.SYNCED_COLOR] = this.currentGroups[i].color; // iterate over synced zones and dispatch events

                for (var j = 0; j < syncedZones.length; j++) {
                    var currZone = syncedZones[j];
                    var currId = currZone[MusicServerEnum.Event.PLAYER_ID]; // remove zones that where already informed

                    delete zonesToUpdate[currZone[MusicServerEnum.Event.PLAYER_ID]];
                    internalEventData[MusicServerEnum.Event.PLAYER_ID] = currId;
                    internalEvent[MusicServerEnum.InternalEventIdentifier.ZONE.SYNC_CHANGED] = internalEventData;
                    Debug.Media.ZoneGroupExt && console.log("ZoneGroupExt->SyncedZone: " + JSON.stringify(internalEvent));
                    this.channel.emit(this.component.ECEvent.EventReceived, internalEvent); //request state update for this zone.

                    this.sendZoneCommand(currId, MusicServerEnum.AudioCommands.STATUS);
                }
            } // inform zones that are no longer part of a zoneGroup, but where before


            var remainingZones = Object.keys(zonesToUpdate);

            for (i = 0; i < remainingZones.length; i++) {
                internalEventData[MusicServerEnum.Event.SYNCED_ZONES] = [];
                internalEventData[MusicServerEnum.Event.PLAYER_ID] = remainingZones[i];
                internalEvent[MusicServerEnum.InternalEventIdentifier.ZONE.SYNC_CHANGED] = internalEventData;
                Debug.Media.ZoneGroupExt && console.log("ZoneGroupExt->Zone: " + JSON.stringify(internalEvent));
                this.channel.emit(this.component.ECEvent.EventReceived, internalEvent); // request state update for this zone.

                this.sendZoneCommand(remainingZones[i], MusicServerEnum.AudioCommands.STATUS);
            }
        }

        sendZoneCommand(id, cmd) {
            Debug.Media.ZoneGroupExt && console.log(this.name, "sendZoneCommand for " + id + " - " + cmd);
            var fullCmd = "audio/" + id + "/" + cmd;
            Debug.Media.ZoneGroupExt && console.log(this.name, "          fullCmd: " + fullCmd);
            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: fullCmd
            });
        }

        _handleGetSyncedPlayersResponse(groupsArray) {
            var stateContainer = SandboxComponent.getStateContainerForUUID(this.component.getActiveMediaServer().uuidAction);
            stateContainer && stateContainer.newGroupingInfoReceived(groupsArray);
        }

        _handleGroupMasterVolumeChanged(masterVolumeChange) {
            var stateContainer = SandboxComponent.getStateContainerForUUID(this.component.getActiveMediaServer().uuidAction);
            Debug.Control.AudioZone.MasterVolume && console.log(this.name, "_handleGroupMasterVolumeChanged: " + JSON.stringify(masterVolumeChange));
            stateContainer && stateContainer.onMasterVolumeChange(masterVolumeChange);
        }

    }

    Components.Audioserver.extensions.ZoneGroup = ZoneGroupExt;
    return Components;
}(window.Components || {});
