'use strict';

window.Components = function (Components) {
    class QueueLoader extends Components.Audioserver.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        // -------------------------------------------------------
        //            Handling Events
        // -------------------------------------------------------
        _doesHandleEvent(event) {
            return !!event[MusicServerEnum.EventIdentifier.AUDIO.QUEUE];
        }

        // -------------------------------------------------------
        //            Public Methods Events
        // -------------------------------------------------------
        getAcquireCommand() {
            if (!this.hasOwnProperty(MusicServerEnum.Event.PLAYER_ID)) {
                return false;
            } else {
                return 'audio/' + this[MusicServerEnum.Event.PLAYER_ID] + '/' + MusicServerEnum.AudioCommands.QUEUE.GET;
            }
        }

        prepareResult(result) {
            var adoptedResult = super.prepareResult(...arguments); // queue items usually have no file type, just an audioType. As we rely on fileTypes, assign one.

            adoptedResult.items.forEach(function (item) {
                if (!item.hasOwnProperty(MusicServerEnum.Event.FILE_TYPE)) {
                    switch (item[MusicServerEnum.Event.AUDIO_TYPE]) {
                        case MusicServerEnum.AudioType.STREAM:
                        case MusicServerEnum.AudioType.AIRPLAY:
                        case MusicServerEnum.AudioType.BLUETOOTH:
                        case MusicServerEnum.AudioType.SPOTIFY_CONNECT:
                        case MusicServerEnum.AudioType.FILE:
                            item[MusicServerEnum.Event.FILE_TYPE] = MusicServerEnum.FileType.LOCAL_FILE;
                            break;

                        case MusicServerEnum.AudioType.PLAYLIST:
                            item[MusicServerEnum.Event.FILE_TYPE] = MusicServerEnum.FileType.LOCAL_PLAYLIST;
                            break;

                        case MusicServerEnum.AudioType.LINEIN:
                            item[MusicServerEnum.Event.FILE_TYPE] = MusicServerEnum.FileType.HW_INPUT;
                            break;

                        default:
                            break;
                    }
                }
            });
            return adoptedResult;
        }

        _processEvent(event) {
            var playerId = event[MusicServerEnum.EventIdentifier.AUDIO.QUEUE][0][MusicServerEnum.Event.PLAYER_ID];
            Debug.Control.AudioZone.QueueLoader && console.log(this.name, "Queue changed for " + playerId);

            if (this[MusicServerEnum.Event.PLAYER_ID] && playerId !== this[MusicServerEnum.Event.PLAYER_ID]) {
                // Queue changed for a different player than we're dealing with right now.
                return;
            }

            Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         cancel all requests (" + this.requestQueue.length + (this.currentRequest ? " + 1" : "") + ") total: " + Object.keys(this.requests).length);
            this.currentRequest >= 0 && this._rejectAndDeleteRequest(this.currentRequest, MusicServerEnum.ReloadCause.CHANGED);
            this.currentRequest = -1;
            var nextRequest;

            while (nextRequest = this.requestQueue.shift()) {
                this._rejectAndDeleteRequest(nextRequest, MusicServerEnum.ReloadCause.CHANGED);
            }

            Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         after cancelling all requests (" + this.requestQueue.length + (this.currentRequest ? " + 1" : "") + ") total: " + Object.keys(this.requests).length); // Delay cache invalidations if we currently show the player View (QueueScreen) to not overload the Audioserver

            if (this.component && this.component.AudioViewController && this.component.AudioViewController.currentView && this.component.AudioViewController.currentView instanceof GUI.ModalViewController && this.component.AudioViewController.currentView.currentView instanceof Controls.AudioZoneV2Control.PlayerV2 && !this.component.Feature.DELAYED_QUEUE_EVENTS) // the audioserver now delays the events itself!
            {
                this._delayTimeout && clearTimeout(this._delayTimeout);
                Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         invalidate the cache --> delay!");
                this._delayTimeout = setTimeout(function () {
                    Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         invalidate the cache --> delayed");
                    this.invalidateContentCachesOf(playerId, MusicServerEnum.ReloadCause.CHANGED);
                    Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         invalidated");
                }.bind(this), 2000);
            } else {
                Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         invalidate the cache");
                this.invalidateContentCachesOf(playerId, MusicServerEnum.ReloadCause.CHANGED);
                Debug.Control.AudioZone.QueueLoader && console.log(this.name, "         invalidated");
            }
        }

        requestContentBatch(id, nItems, mediaTypeDetails) {
            this._updatePlayer(mediaTypeDetails);

            return super.requestContentBatch(...arguments);
        }

        requestContent(id, nItems, mediaTypeDetails) {
            this._updatePlayer(mediaTypeDetails);

            return super.requestContent(...arguments);
        }

        prefetchContent(identifier, packageSize, mediaTypeDetails) {
            this._updatePlayer(mediaTypeDetails);

            return super.prefetchContent(...arguments);
        }

        _updatePlayer(mediaTypeDetails) {
            if (mediaTypeDetails && mediaTypeDetails.hasOwnProperty(MusicServerEnum.Event.PLAYER_ID)) {
                var newPlayerid = mediaTypeDetails[MusicServerEnum.Event.PLAYER_ID];

                if (!this[MusicServerEnum.Event.PLAYER_ID]) {
                    Debug.Media.Loader && console.log(this.name, "No player so far, nothing to do");
                } else if (this[MusicServerEnum.Event.PLAYER_ID] !== newPlayerid) {
                    Debug.Media.Loader && console.log(this.name, "The player has changed from " + this[MusicServerEnum.Event.PLAYER_ID] ? this[MusicServerEnum.Event.PLAYER_ID] : "no player" + "" + " to " + newPlayerid + " - resetting the cache"); // the service did change, respond by invalidating all the cache

                    this.cache = {};
                }

                this[MusicServerEnum.Event.PLAYER_ID] = newPlayerid;
            }
        }

        /*  Overwrite baseclass, since getAcquireCommand is handled. Other loaders assume 'audio/cfg' is prepended
                     to their commands, in this subclass we return the full command in getAcquireCommand - except for the
                     arguments.
                     */
        _doesHandleResult(result) {
            var doHandle = false;

            if (!doHandle && result.hasOwnProperty("command")) {
                // test new mapping handling
                var lastRqObj = this.requests[this.currentRequest];

                if (lastRqObj && result.command === lastRqObj.command) {
                    doHandle = true;
                }
            }

            return doHandle;
        }

        /**
         * Concates the arguments to the command. Is handled different than in the other loaders since here
         * getAcquireCommand returns the full command (audio/ does not need to be concatenated).
         * @param command contains the full command (audio/{playerid}/getqueue)
         * @param args the arguments that need to be appended to the command (start/nitems)
         * @returns {string}
         * @private
         */
        _send(command, args) {
            var fullCmd = command + "/" + args;
            Debug.Communication && CommTracker.commStart(CommTracker.Transport.AUDIO_SOCKET, fullCmd);
            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: fullCmd,
                auto: true
            });
            return fullCmd;
        }

        /* Overwrite baseclass implementation, we don't need the request id in our command */
        getAcquireCommandArguments(request) {
            //return request.id + "/" + request.start + "/" + request.nItems;
            return request.start + "/" + request.nItems;
        }

    }

    Components.Audioserver.extensions.QueueLoader = QueueLoader;
    return Components;
}(window.Components || {});
