'use strict';

window.Components = function (Components) {
    class ServiceLoader extends Components.Audioserver.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.registerExtensionEv(this.component.ECEvent.ServiceChanged, this._handleServiceChanged.bind(this));
            this.noPrefetchMap = {};
        }

        getAcquireCommand(mediaTypeDetails, doesHandleCheck) {
            if (mediaTypeDetails) {
                this._updateServiceType(mediaTypeDetails);
            }

            if (!this.service) {
                !doesHandleCheck && console.error(this.name, "getAcquireCommand failed, no service specified yet!", getStackObj());
                return false;
            } else {
                return 'getservicefolder/' + this.service[MusicServerEnum.Attr.SERVICE.UID];
            }
        }

        requestContentBatch(id, nItems, mediaTypeDetails) {
            if (nItems > 50) {
                console.warn(this.name, "Loading portions greater than 50 is not a good idea. Loading content from " + "external services is quite cumbersome and takes time.");
            }

            this._updateServiceType(mediaTypeDetails);

            return super.requestContentBatch(...arguments);
        }

        requestContent(id, nItems, mediaTypeDetails) {
            if (nItems > 50) {
                console.warn(this.name, "Loading portions greater than 50 is not a good idea. Loading content from " + "external services is quite cumbersome and takes time.");
            }

            this._updateServiceType(mediaTypeDetails);
            /*if (this.service[MusicServerEnum.Attr.SERVICE.CMD] === MusicServerEnum.Service.SPOTIFY) {
                this._invalidateCacheFor(id);
            }*/


            return super.requestContent(...arguments);
        }

        prefetchContent(identifier, packageSize, mediaTypeDetails) {
            Debug.Media.Loader && console.log(this.name + ": prefetchContent first " + packageSize + " of " + identifier);

            if (this.avoidPrefetching(identifier)) {
                Debug.Media.Loader && console.log("    prefetching for " + identifier + " not allowed");
                return;
            }

            this._updateServiceType(mediaTypeDetails);

            return super.prefetchContent(...arguments);
        }

        /**
         * Used to intercept prefetching. E.g. the playlist folder inside the getservicefolder result mustn't
         * be prefetched as it will be loaded using a different command later.
         * @param id            the id of the container in question
         * @returns {boolean}   if is to be avoided (true) of if it is allowed (false).
         */
        avoidPrefetching(id) {
            return this.noPrefetchMap.hasOwnProperty(id);
        }

        serviceItemFollowedChanged(item, followed) {
            var identifier = item.id || item.audiopath,
                parentIds = this._getParentIdentifiersFromSpotifyAudioPath(identifier);

            Debug.Media.Loader && console.log(this.name, "serviceItemFollowedChanged: " + identifier + ", followed: " + !!followed);
            parentIds.forEach(parentId => {
                Debug.Media.Loader && console.log(this.name, "     invalidating cache for parent of " + identifier + ": " + parentId); // only invalidate the parent cache itself, don't invalidate e.g. the content of the playlists when a playlist is unfollowed

                this.invalidateContentCachesOf(parentId, MusicServerEnum.ReloadCause.CHANGED, false, false, true);
            });
        }

        /**
         * Retrieves the app spotify type of an item based on its audiopath
         * e.g.:
         * "spotify@o18d47dn85h98gnvymg6hywu7:track:166W55fFsA66OZsxIfzEK7" --> "4" = TRACKS
         * MusicServerEnum.Tag.TRACK --> MusicServerEnum.Spotify.TYPES.TRACKS
         * @param path
         * @returns {*[]}
         * @private
         */
        _getParentIdentifiersFromSpotifyAudioPath(path) {
            var parts = path.split(":"),
                tag = path.length >= 3 ? parts[1] : null,
                parentIds = [];

            switch (tag) {
                case MusicServerEnum.Tag.EPISODE:
                case MusicServerEnum.Tag.TRACK:
                    // spotify@o18d47dn85h98gnvymg6hywu7:track:166W55fFsA66OZsxIfzEK7
                    parentIds.push(MusicServerEnum.Spotify.TYPES.TRACKS);
                    break;

                case MusicServerEnum.Tag.PLAYLIST:
                    // spotify@o18d47dn85h98gnvymg6hywu7:playlist:37i9dQZF1DWUW2bvSkjcJ6
                    parentIds.push(MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS);
                    break;

                case MusicServerEnum.Tag.ALBUM:
                    // potify@o18d47dn85h98gnvymg6hywu7:album:1LoyJQVHPLHE3fCCS8Juek
                    parentIds.push(MusicServerEnum.Spotify.TYPES.ALBUMS);
                    break;

                case MusicServerEnum.Tag.ARTIST:
                    // potify@o18d47dn85h98gnvymg6hywu7:artist:1LoyJQVHPLHE3fCCS8Juek
                    parentIds.push(MusicServerEnum.Spotify.TYPES.ARTISTS);
                    break;

                case MusicServerEnum.Tag.SHOW:
                    parentIds.push(MusicServerEnum.Spotify.TYPES.PODCASTS);
                    break;

                case MusicServerEnum.Tag.STATION:
                case MusicServerEnum.Tag.GENRE:
                case MusicServerEnum.Tag.NONE:
                    // nothing to do here, no parent to empty on this.
                    break;

                default:
                    console.error(this.name, "_getParentIdentifierFromAudioPath: failed to parse path: " + path);
                    break;
            }

            return parentIds;
        }

        /**
         * Check if its a playlistChanged event and if it affects the current target (service + user)
         * No need to check if the event was triggered by this app and hence the loader would update the dataset too,
         * as the mediaMessageParser differs between result-events and events that come unexpectedly. In this situation
         * only unexpected events are processed!
         * @param event
         * @returns {boolean}
         * @private
         */
        _doesHandleEvent(event) {
            var doHandle = false,
                evData,
                source;

            if (event.hasOwnProperty(MusicServerEnum.EventIdentifier.PLAYLIST_CHANGED) && this.service) {
                evData = event[MusicServerEnum.EventIdentifier.PLAYLIST_CHANGED][0];
                source = evData[MusicServerEnum.Attr.SERVICE.CMD] + "/" + evData[MusicServerEnum.Attr.SERVICE.USER];
                doHandle = source.toLowerCase() === this.service[MusicServerEnum.Attr.SERVICE.UID]; // lowercase as lms/nouser and lms/noUser don't equal.

                Debug.Media.Loader && console.log(this.name, "_doesHandleEvent: " + !!doHandle + " --> " + JSON.stringify(event));

            } else if (event.hasOwnProperty(MusicServerEnum.EventIdentifier.RADIOS_CHANGED)) {
                //{ "customurl_changed_event": { "action": "add", "id": "custom:station:p8GSpzLF"} }
                doHandle = true;
            }

            return doHandle;
        }

        /**
         * It affects the current service and user, so detect what was changed and how - then respond.
         * @param event
         * @private
         */
        _processEvent(event) {
            var evData, playlistID, action;
            Debug.Media.Loader && console.log(this.name, "_processEvent: " + JSON.stringify(event));

            if (event.hasOwnProperty(MusicServerEnum.EventIdentifier.PLAYLIST_CHANGED)) {
                developerAttention(this.name + ": _processEvent detected something important!", window.Styles.colors.orange);
                evData = event[MusicServerEnum.EventIdentifier.PLAYLIST_CHANGED][0];
                playlistID = evData[MusicServerEnum.Attr.Playlist.ID];
                action = evData[MusicServerEnum.Attr.Container.ACTION];
                Debug.Media.Loader && console.log(this.name, "     playlist " + playlistID + " modified with action " + action);
                Debug.Media.Loader && console.log(this.name, "        eventData: " + JSON.stringify(evData));
                this.invalidateContentCachesOf(MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS, action);

            } else if (event.hasOwnProperty(MusicServerEnum.EventIdentifier.RADIOS_CHANGED)) {
                //{ "customurl_changed_event": { "action": "add", "id": "custom:station:p8GSpzLF"} }
                evData = event[MusicServerEnum.EventIdentifier.RADIOS_CHANGED];
                var radioId = evData.id;
                action = evData[MusicServerEnum.Attr.Container.ACTION];

                if (this.service && this.service.cmd === MusicServerEnum.Radios.CUSTOM) {
                    Debug.Media.Loader && console.log(this.name, "_processEvent -- tunein service active, radio changed! " + action + " radio=" + radioId, this.service, this.cache);
                    // since there is only one level (no sub-folders), reload the whole thing, by invalidating "start"
                    this.invalidateContentCachesOf("start", action, true, true, false);
                }
            }
        }

        _updateServiceType(mediaTypeDetails) {
            Debug.Media.Loader && console.log(this.name, "_updateServiceType called with mediaTypeDetails: " + JSON.stringify(mediaTypeDetails), getStackObj());

            if (mediaTypeDetails && mediaTypeDetails.hasOwnProperty(MusicServerEnum.Attr.SERVICE._)) {
                var newService = mediaTypeDetails[MusicServerEnum.Attr.SERVICE._];

                if (!this.service) {
                    Debug.Media.Loader && console.log(this.name, "_updateServiceType - No service so far, nothing to do");
                    this.service = newService;
                } else if (this.service[MusicServerEnum.Attr.SERVICE.UID] !== newService[MusicServerEnum.Attr.SERVICE.UID]) {
                    console.log(this.name, "_updateServiceType - the service has changed from " + (this.service ? this.service[MusicServerEnum.Attr.SERVICE.UID] : "no service") + "" + " to " + newService[MusicServerEnum.Attr.SERVICE.UID] + " - resetting the cache"); // the service did change, respond by invalidating all the cache

                    this.cache = {}; // reset the no prefetchmap

                    this.noPrefetchMap = {}; // store entire service object

                    this.service = newService;
                } else if (!this.service.hasOwnProperty(MediaEnum.Attr.SERVICE.USER) && newService.hasOwnProperty(MediaEnum.Attr.SERVICE.USER)) {
                    Debug.Media.Loader && console.log(this.name, "_updateServiceType - userfriendly username was " + "missing, update: " + newService[MediaEnum.Attr.SERVICE.USER]);
                    this.service[MediaEnum.Attr.SERVICE.USER] = newService[MediaEnum.Attr.SERVICE.USER];
                }
            } else {
                Debug.Media.Loader && console.warn(this.name, "     _updateServiceType ignored, service info missing!", getStackObj());
            }
        }

        /**
         * Overwrite if the result needs to be adopted before dispatching (used in serviceLoaderExt)
         * @param result the result to adopt
         * @param finished whether or not this is the final result
         * @returns {*} the adopted result
         */
        prepareResult(result, finished) {
            // to ensure the items names are decoded, pass on to the base class
            result = super.prepareResult(...arguments);

            if (result.service === MusicServerEnum.Service.SPOTIFY && result.id === MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS && result.start === 0) {
                // push "my playlists" into this.
                var userInfo = this._getServiceUserInfo();

                result.items.splice(0, 0, this.component.getSpotifyLikedSongsItem(userInfo.id, userInfo.user));
            }

            for (var i = 0; i < result.items.length; i++) {
                var item = result.items[i]; // no prefetching e.g. for playlists, as a different loader is in charge for them.

                if (item.hasOwnProperty("contentType")) {
                    this.noPrefetchMap[item[MusicServerEnum.Event.ID]] = true;
                }
            }

            // ensures that custom radio streams are alphabetically sorted.
            if (result.service === "custom") {
                result.items = result.items.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                })
            }

            return result;
        }

        _getServiceUserInfo() {
            var userId, userName;

            if (this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.ID)) {
                userId = this.service[MusicServerEnum.Attr.SERVICE.ID];
            } else if (this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.UID)) {
                userId = this.service[MusicServerEnum.Attr.SERVICE.UID].split("/")[1];
            } else {
                console.error(this.name, "failed to acquire spotify userId! " + JSON.stringify(this.service));
            }

            if (this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.USER)) {
                userName = this.service[MusicServerEnum.Attr.SERVICE.USER];
            } else {
                console.error(this.name, "_getServiceUserInfo: missing user name!");
            }

            return {
                id: userId,
                user: userName
            };
        }

        _handleServiceChanged(id, event) {
            if (this.service && this.service[MusicServerEnum.Attr.SERVICE.UID] === event[MusicServerEnum.Attr.SERVICE.UID]) {
                // whoops, the current service is affected.
                var reason = event.action; //user add, del, update

                this.rejectAndDeleteAllRequests(reason);
                this.invalidateContentCachesOf("start", reason, true);
            }
        }

    }

    Components.Audioserver.extensions.ServiceLoader = ServiceLoader;
    return Components;
}(window.Components || {});
