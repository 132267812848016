'use strict';
/**
 * This Extension is solely used to get data for the grouping-screen or to perform update-actions triggered by the
 * grouping screen. It does not notify any UI instances, as this is being done by other extensions/state-containers.
 * @type {{}}
 */

var Components = function (Components) {
    class DynamicGroupsExt extends Components.Extension {
        //region Getter
        get dynamicGroupMap() {
            if (!this._dynamicGroupMap) {
                this._dynamicGroupMap = {};
            }

            return this._dynamicGroupMap;
        }

        get staticGroupMap() {
            if (!this._staticGroupMap) {
                this._staticGroupMap = {};
            }

            return this._staticGroupMap;
        }

        get playerMap() {
            if (!this._playerMap) {
                this._playerMap = {};
            }

            return this._playerMap;
        }

        get totalNameMap() {
            if (!this._totalNameMap) {
                this._totalNameMap = {};
            }

            return this._totalNameMap;
        } //endregion Getter


        //region Setter
        set dynamicGroupMap(newVal) {
            this._dynamicGroupMap = newVal;
        }

        set staticGroupMap(newVal) {
            this._staticGroupMap = newVal;
        }

        set playerMap(newVal) {
            this._playerMap = newVal;
        }

        set totalNameMap(newVal) {
            this._totalNameMap = newVal;
        } //endregion Setter


        constructor(component, extensionChannel) {
            super(...arguments);
            this.name += "@" + component.getServerName();
            this._updListeners = {};
            this.registerExtensionEv(this.component.ECEvent.ConnEstablished, this._handleStart.bind(this));
            this.registerExtensionEv(this.component.ECEvent.Stop, this._handleStop.bind(this));
            this.registerExtensionEv(this.component.ECEvent.EventReceived, function (evId, event) {
                if (event.hasOwnProperty(MusicServerEnum.EventIdentifier.AUDIO.SYNC)) {
                    /**
                     * {
                     *     "audio_sync_event": [
                     *         {
                     *             "group": "168e085c-01f1-7dc5-ffff-6f4bfad385ea",
                     *             "players": [
                     *                 {
                     *                     "id": "15d76819-039e-dd59-ffff-6f4bfad385ea",
                     *                     "playerid": 1
                     *                 },
                     *                ...
                     *             ],
                     *             "type": "static"
                     *         },
                     *         {
                     *             "group": "e8929c71-10b1-3490-b2b9-9eacbc23d203",
                     *             "players": [
                     *                 {
                     *                     "id": "15d76819-039e-dd59-ffff-6f4bfad385ea",
                     *                     "playerid": 1
                     *                 },
                     *                 ...
                     *             ],
                     *             "type": "dynamic"
                     *         }
                     *     ]
                     * }
                     */
                    this._handleSyncEvent(event[MusicServerEnum.EventIdentifier.AUDIO.SYNC]);
                }
            }.bind(this));
        }

        // ------------------------------------------------------------------------------------------
        //            Public Methods
        // ------------------------------------------------------------------------------------------

        /**
         *
         * @param groupId existing groups id or null/undefined/false if a new one is to be created.
         * @param idArray array of all players that are part of this group
         * @note A single playerId of a static group inside the idArray means all players of the static group are part.
         * @returns {Q.Promise<unknown>}
         */
        updateGroup(groupId, idArray) {
            Debug.Media.DynamicGroups && console.log(this.name, "updateGroup: " + groupId + " with players: " + idArray.join(", "));
            /**
             * Single command for creating/joining/leaving/dissolving groups.
             * GroupID "new" => create
             * PlayerID no longer in list => leave
             * New PlayerID in list => join
             * No PlayerIDs => dissolve
             *
             * Request
             * audio/cfg/dgroup/update/<GroupID>/<PlayerID1>,<PlayerID2>[,<PlayerIDn>]
             *
             * Response
             * {
             *     "dgroup_update_result": {
             *         "id": "<Group UUID>",
             *         "players": [
             *                 <PlayerID1>
             *                 ,<PlayerID2>
             *                 [,<PlayerIDn>]
             *         ]
             *     },
             *     "command": "audio/cfg/dgroup/update/<GroupID>/<PlayerID1>,<PlayerID2>[,<PlayerIDn>]"
             * }
             */
                // with the update command, a single command can be used to create/dissolve/update/remove groups

            var cmdGroupId = groupId || "new",
                cmd = sprintf(MusicServerEnum.Commands.DynamicGroup.UPDATE, cmdGroupId, idArray.join(","));
            Debug.Media.DynamicGroups && console.log(this.name, "updateGroup - cmd = " + cmd);
            return this._sendCommand(cmd, false).then(function (res) {
                Debug.Media.DynamicGroups && console.log(this.name, "updateGroup >> responded: " + JSON.stringify(res));
            }.bind(this));
        }

        /**
         * Using this method one can register for potential updates to synced zones.
         * @param updateFn the function to call when the synced zones have changed
         * @returns {any} a function to use for unregistering.
         */
        registerForGroupingChanges(updateFn) {
            var rndId;

            do {
                rndId = getRandomIntInclusive(0, 10000);
            } while (this._updListeners.hasOwnProperty(rndId));

            this._updListeners[rndId] = updateFn;
            return function () {
                delete this._updListeners[rndId];
            }.bind(this);
        }

        /**
         * Returns the list of groups one can join. Those are either static or dynamic groups
         * Each item carries the following properties (regardless of the type)
         *  - name          well, the name of the group/player
         *  - group         the id of the group
         *  - playerids     an array containing all player ids (only one when a single player)
         *  - controls      an array containing the control-objects of the players (only one when a single player)
         * @returns {*[]}
         */
        getJoinGroupList(visiblePlayerId) {
            var gList = [],
                activeZonePlayerStaticGroup = this._getActiveZoneStaticGroupId(visiblePlayerId);

            Object.values(this.staticGroupMap).forEach(function (groupObj) {
                if (groupObj.group !== activeZonePlayerStaticGroup) {
                    // iterate over players, if one of them is also part of dynamic groups, hide it.
                    var alreadyDynamic = groupObj.players.some(function (playerObj) {
                        return !!this.playerMap[playerObj.playerid].dynamicGroup;
                    }.bind(this));

                    if (!alreadyDynamic && this._isPermittedGroup(groupObj)) {
                        gList.push(this._createGroupObjForUI(groupObj, MusicServerEnum.GroupType.FIXED));
                    }
                }
            }.bind(this));
            Object.values(this.dynamicGroupMap).forEach(function (groupObj) {
                if (this._isPermittedGroup(groupObj)) {
                    gList.push(this._createGroupObjForUI(groupObj, MusicServerEnum.GroupType.DYNAMIC));
                }
            }.bind(this));

            this._addRoomNamesToDuplicates(gList);

            gList.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            }.bind(this));
            Debug.Media.DynamicGroups && console.log(this.name, "getJoinGroupList: " + gList.length + " groups", gList);
            return gList;
        }

        /**
         * Returns the list of items one can join to. Those are either single players or static (fixed) groups.
         * Each item carries the following properties (regardless of the type).
         *  - name          well, the name of the group/player
         *  - [group]       group id, only available if not a single player, but a static group.
         *  - playerids     an array containing all player ids (only one when a single player)
         *  - controls      an array containing the control-objects of the players (only one when a single player)
         * @returns {*[]}
         */
        getCreateGroupList(visiblePlayerId) {
            Debug.Media.DynamicGroups && console.log(this.name, "getCreateGroupList");

            var createList = [],
                coveredStaticGroupMap = {},
                staticGroupId,
                activeZonePlayerId = visiblePlayerId || this._getActiveZonePlayerId(),
                activeZonePlayerStaticGroup = this._getActiveZoneStaticGroupId(activeZonePlayerId),
                activeZoneItem = null;

            Debug.Media.DynamicGroups && console.log(this.name, "getCreateGroupList - activePlayerId: " + activeZonePlayerId + " (has static group: " + activeZonePlayerStaticGroup + ")");
            this.playerMap && Object.keys(this.playerMap).forEach(function (playerId) {
                staticGroupId = this.playerMap[playerId].staticGroup;

                if (staticGroupId) {
                    if (!coveredStaticGroupMap.hasOwnProperty(staticGroupId)) {
                        if (activeZonePlayerStaticGroup === staticGroupId) {
                            // the current zone item - the static group in this case, should always be first in
                            // the list.
                            activeZoneItem = this._getCreateGroupItemWithStaticGroup(staticGroupId);
                        } else {
                            createList.pushObject(this._getCreateGroupItemWithStaticGroup(staticGroupId));
                        }

                        coveredStaticGroupMap[staticGroupId] = true;
                    }
                } else if ("" + activeZonePlayerId === "" + playerId) {
                    // the current zone item should always be first in the list.
                    activeZoneItem = this._getCreateGroupItemWithPlayer(playerId);
                } else {
                    createList.pushObject(this._getCreateGroupItemWithPlayer(playerId));
                }
            }.bind(this));

            this._addRoomNamesToDuplicates(createList); // ensure it's sorted.


            createList.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            }.bind(this)); // Put the current zone first.

            if (activeZoneItem) {
                activeZoneItem.selected = true;
                createList.splice(0, 0, activeZoneItem);

                this._addRoomNamesToDuplicates(createList);
            } else {
                Debug.Media.DynamicGroups && console.warn(this.name, "     NO active player?! ");
            }

            return createList;
        }

        /**
         * Returns the list of items that are part of the same group as the playerid provided
         * Each item carries the following properties (regardless of the type)
         *  - name          well, the name of the group/player
         *  - group         the id of the group
         *  - playerids     an array containing all player ids (only one when a single player)
         *  - controls      an array containing the control-objects of the players (only one when a single player)
         * @param visiblePlayerId
         * @returns {*[]}
         */
        getEditGroupList(visiblePlayerId) {
            var activeZonePlayerId = visiblePlayerId || this._getActiveZonePlayerId();

            if (this.playerMap[activeZonePlayerId].dynamicGroup === false) {
                //console.error(this.name, "getEditGroupList - cannot modify a static group. Players groups = ", this.playerMap[activeZonePlayerId]);
                //console.error(this.name, "    dynamic groups = ", this.dynamicGroupMap);
                //console.error(this.name, "     static groups = ", this.staticGroupMap);
                return [];
            } // group contains a players property, an array of playerObjs which in turn contain the playerid


            var activePlayerDynamicGroup = this.dynamicGroupMap[this.playerMap[activeZonePlayerId].dynamicGroup];
            var list = [];
            Debug.Media.DynamicGroups && console.log(this.name, "getEditGroupList - active player = " + activeZonePlayerId + ", part of dynamicGroup: " + JSON.stringify(activePlayerDynamicGroup));
            Object.values(this.staticGroupMap).forEach(function (groupObj) {
                var isAnyPlayerPart = groupObj.players.some(function (playerObj) {
                    return this._getIsGroupMember(playerObj.playerid, activePlayerDynamicGroup);
                }.bind(this));
                if (this._isPermittedGroup(groupObj)) {
                    list.push(this._createGroupObjForUI(groupObj, MusicServerEnum.GroupType.FIXED, isAnyPlayerPart));
                }
            }.bind(this));
            Object.keys(this.playerMap).forEach(function (playerId) {
                if (!this.playerMap[playerId].staticGroup) {
                    // only static groups are hidden.
                    list.pushObject(this._getCreateGroupItemWithPlayer(playerId, this._getIsGroupMember(playerId, activePlayerDynamicGroup)));
                }
            }.bind(this));

            this._addRoomNamesToDuplicates(list); // ensure it's sorted.


            list.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            }.bind(this));
            Debug.Media.DynamicGroups && console.log(this.name, "   >> items = " + list.length);
            return list;
        }

        getGroupOfPlayer(playerId) {
            var playerObj = this.playerMap[playerId],
                group;

            if (playerObj.dynamicGroup) {
                group = this.dynamicGroupMap[playerObj.dynamicGroup];
            } else if (playerObj.staticGroup) {
                group = this.staticGroupMap[playerObj.dynamicGroup];
            } else {
                group = null;
            }

            return group;
        }

        isPartOfDynamicGroup(playerId) {
            var playerObj = this.playerMap[playerId];
            return !!playerObj && !!playerObj.dynamicGroup;
        }

        isDynamicGroup(groupId) {
            return this.dynamicGroupMap.hasOwnProperty(groupId) || this.dynamicGroupMap.hasOwnProperty("" + groupId);
        }

        // ------------------------------------------------------------------------------------------
        //            Private Methods
        // ------------------------------------------------------------------------------------------
        _getIsGroupMember(playerId, group) {
            return this.playerMap[playerId].dynamicGroup === group.group || this.playerMap[playerId].staticGroup === group.goup;
        }

        _handleStart() {
            Debug.Media.DynamicGroups && console.log(this.name, "_handleStart");

            this._requestGroups();
        }

        _handleStop() {
            Debug.Media.DynamicGroups && console.log(this.name, "_handleStop");
        }

        _requestGroups() {
            Debug.Media.DynamicGroups && console.log(this.name, "_requestGroups");
            return this._sendCommand(MusicServerEnum.Commands.SYNC.GET_SYNCED_PLAYERS, true).then(function (res) {
                Debug.Media.DynamicGroups && console.log(this.name, "_requestGroups >> responded: " + JSON.stringify(res));

                this._prepareGroupData(res.data);
            }.bind(this));
        }

        _handleSyncEvent(syncEvent) {
            Debug.Media.DynamicGroups && console.log(this.name, "_handleSyncEvent: " + JSON.stringify(syncEvent));
            /**
             *  [
             *         {
             *             "group": "uuid",
             *             "mastervolume": 25,
             *             "type": "{dynamic|static}" ,
             *             "players": [
             *                 {
             *                     "playerid": 1,
             *                     "static": true
             *                 },
             *                 {
             *                     "playerid": 6,
             *                     "static": true
             *                 },
             *                 {
             *                     "playerid": 13,
             *                     "static": false
             *                 }
             *             ]
             *         }
             *     ]
             */

            this._prepareGroupData(syncEvent);
        }

        _prepareGroupData(groupsArray) {
            Debug.Media.DynamicGroups && console.log(this.name, "_prepareGroupData: " + groupsArray.length + " groups");
            this.currentGroups = groupsArray;
            this.staticGroupMap = {};
            this.dynamicGroupMap = {};
            this.playerMap = {};
            var groupId,
                playerId,
                isDynGroup,
                ungroupedCnt = 0;
            groupsArray.forEach(function (group) {
                if (group.hasOwnProperty("players") || group.players.length > 0) {
                    //precaution, as "empty groups" may be received at some point
                    groupId = group.group;
                    isDynGroup = group.type === "dynamic";

                    if (isDynGroup) {
                        this.dynamicGroupMap[groupId] = group;
                    } else {
                        this.staticGroupMap[groupId] = group;
                    }

                    group.players.forEach(function (groupPlayer) {
                        playerId = groupPlayer.playerid;
                        this.playerMap[playerId] = this.playerMap[playerId] || {
                            staticGroup: false,
                            dynamicGroup: false
                        };

                        if (isDynGroup) {
                            this.playerMap[playerId].dynamicGroup = groupId;
                        } else {
                            this.playerMap[playerId].staticGroup = groupId;
                        }
                    }.bind(this)); // ensure the player-control-objects are provided instead of API-players with id and static-attribute only

                    group.controls = [];
                    group.playerids = [];
                    group.players.forEach(function (playerObj) {
                        group.playerids.push(playerObj.playerid);
                        group.controls.pushObject(this.component.getControlByPlayerId(playerObj.playerid));
                    }.bind(this)); // ensure a group name is provided

                    if (isDynGroup) {
                        group.name = this._createDynamicGroupName(group.controls, group.playerids);
                    } else {
                        group.name = this._getStaticGroupName(group);
                    }
                }
            }.bind(this)); // ensure that players that are not part of groups are also listed.

            this.component.getAvailableZones(false).forEach(function (zoneControl) {
                playerId = zoneControl.details.playerid;

                if (!this.playerMap.hasOwnProperty(playerId)) {
                    this.playerMap[playerId] = {
                        staticGroup: false,
                        dynamicGroup: false
                    };
                    ungroupedCnt++;
                }
            }.bind(this));

            this._establishGlobalNameMap();

            Debug.Media.DynamicGroups && this.__printGroups();

            this._notifyUpdateListeners();
        }

        _establishGlobalNameMap() {
            // establish a name map to allow checking for duplicates:
            var grpName;
            this.totalNameMap = {}; // reset first

            Object.keys(this.staticGroupMap).forEach(grpKey => {
                grpName = this._safeGetGroupNameOf(this.staticGroupMap[grpKey]);
                this.totalNameMap[grpName] = this.totalNameMap[grpName] || 0;
                this.totalNameMap[grpName] = this.totalNameMap[grpName] + 1;
            });
            Object.keys(this.dynamicGroupMap).forEach(grpKey => {
                grpName = this._safeGetGroupNameOf(this.dynamicGroupMap[grpKey]);
                this.totalNameMap[grpName] = this.totalNameMap[grpName] || 0;
                this.totalNameMap[grpName] = this.totalNameMap[grpName] + 1;
            });
            Object.keys(this.playerMap).forEach(playerKey => {
                grpName = this._safeGetGroupNameOf(this._getCreateGroupItemWithPlayer(playerKey));
                this.totalNameMap[grpName] = this.totalNameMap[grpName] || 0;
                this.totalNameMap[grpName] = this.totalNameMap[grpName] + 1;
            });
        }

        /**
         * A player id may not be visible to this user.
         * @param grp
         * @returns {null|*}
         * @private
         */
        _safeGetGroupNameOf(grp) {
            return grp ? grp.name : null;
        }

        /** Developing Tool **/
        __printGroups() {
            var uiGrp;
            console.log(this.name, "Current Groups: ");
            console.log(this.name, "   Static = " + Object.keys(this.staticGroupMap).length + " groups");
            Object.values(this.staticGroupMap).forEach(staticGroup => {
                uiGrp = this._createGroupObjForUI(staticGroup, MusicServerEnum.GroupType.FIXED);
                console.log(this.name, "       - " + uiGrp.name + " (ID=" + uiGrp.group + "), " + uiGrp.controls.length + " players");
                uiGrp.controls.forEach(memberControl => {
                    console.log(this.name, "             - " + memberControl.name);
                });
            });
            console.log(this.name, "   Dynamic = " + Object.keys(this.dynamicGroupMap).length + " groups");
            Object.values(this.dynamicGroupMap).forEach(staticGroup => {
                uiGrp = this._createGroupObjForUI(staticGroup, MusicServerEnum.GroupType.DYNAMIC);
                console.log(this.name, "       - " + uiGrp.name + " (ID=" + uiGrp.group + "), " + uiGrp.controls.length + " players");
                uiGrp.controls.forEach(memberControl => {
                    console.log(this.name, "             - " + memberControl.name);
                });
            });
        }

        /**
         * If a group only contains players that the current user isn't permitted to, don't show it on the ui.
         * @param inputGroup
         * @returns {boolean}
         * @private
         */
        _isPermittedGroup(inputGroup) {
            return inputGroup.controls.length > 0;
        }

        _createGroupObjForUI(inputGroup, groupType, selected) {
            var uiGroup = cloneObject(inputGroup); // ensure the player-control-objects are provided instead of API-players with id and static-attribute only

            uiGroup.controls = [];
            uiGroup.playerids = [];
            inputGroup.players.forEach(function (playerObj) {
                uiGroup.playerids.push(playerObj.playerid);
                uiGroup.controls.pushObject(this.component.getControlByPlayerId(playerObj.playerid));
            }.bind(this)); // ensure a group name is provided

            if (groupType === MusicServerEnum.GroupType.DYNAMIC) {
                uiGroup.name = this._createDynamicGroupName(uiGroup.controls, uiGroup.playerids);
            } else {
                uiGroup.name = this._getStaticGroupName(uiGroup);
            }

            uiGroup.selected = !!selected;
            uiGroup._appId = uiGroup.group || uiGroup.playerids.join("+"); // internal id to identify groups

            return uiGroup;
        }

        _createDynamicGroupName(zoneControls, playerIds) {
            var names = [],
                grpName;
            zoneControls.forEach(function (ctrl) {
                names.push(ctrl.getName(false));
            }.bind(this));

            const numGroups = playerIds.length;
            const invisiblePlayers = names.length !== playerIds.length;

            if (numGroups > 2 && names.length > 0) {
                grpName = names[0] + " + " + (numGroups - 1);
            } else if (numGroups > 0) {
                grpName = names.join(" + ");
            } else {
                grpName = "-NO-PLAYERS-VISIBLE-";
            }

            if (invisiblePlayers) {
                grpName = grpName + "*";
            }

            return grpName;
        }

        _getStaticGroupName(group) {
            return group.controls.length > 0 ? group.controls[0].getName(true) : "-NO-PLAYERS-VISIBLE-";
        }

        _getRoomName(group) {
            var control,
                room,
                name = null;

            if (group.type === MusicServerEnum.GroupType.FIXED) {
                control = ActiveMSComponent.getStructureManager().getControlByUUID(group.group);
            } else {
                control = group.controls.length > 0 ? group.controls[0] : null;
            }

            if (control && control.getRoom()) {
                room = control.getRoom();
                name = room ? room.name : null;
            }

            return name;
        }

        _addRoomNamesToDuplicates(list) {
            Debug.Media.DynamicGroups && console.log(this.name, "_addRoomNamesToDuplicates", list);
            list.forEach(groupItem => {
                if (this.totalNameMap[groupItem.name] > 1) {
                    Debug.Media.DynamicGroups && console.warn(this.name, "   " + groupItem.name + " --> more than 1 with that name!"); // add room to name.

                    groupItem.hasNameSiblings = true;

                    var room = this._getRoomName(groupItem);

                    if (room && room !== groupItem.name) {
                        //don't add the room name if the name equals the room!
                        groupItem.name += SEPARATOR_SYMBOL + room;
                    } else {
                        Debug.Media.DynamicGroups && console.warn(this.name, "        !! failed to acquire room name of group " + groupItem.name);
                    }
                } else {
                    groupItem.hasNameSiblings = false;
                    Debug.Media.DynamicGroups && console.log(this.name, "   " + groupItem.name + " --> only 1 with that name!");
                }
            });
        }

        _getCreateGroupItemWithStaticGroup(staticGroupId) {
            return this._createGroupObjForUI(this.staticGroupMap[staticGroupId], MusicServerEnum.GroupType.FIXED);
        }

        _getCreateGroupItemWithPlayer(playerId, selected) {
            var item = {},
                ctrl = this.component.getControlByPlayerId(playerId);

            if (ctrl) {
                item.name = ctrl.getName(false);
                item.playerids = [playerId];
                item.controls = [ctrl];
                item.selected = !!selected;
                item._appId = item.playerids.join("+"); // internal id to identify groups
            } else {
                console.error(this.name, "_getCreateGroupItemWithPlayer failed to get control with pID: " + playerId);
                item = null;
            }

            return item;
        }

        _sendCommand(cmd, automatic) {
            Debug.Media.DynamicGroups && console.log(this.name, "sendCommand: " + JSON.stringify(cmd));
            return this.component.sendMediaServerCommand(cmd).then(null, function (err) {
                console.error(this.name, "sendCommand failed: " + JSON.stringify(err));
                return Q.reject(err);
            }.bind(this));
        }

        _getActiveZonePlayerId() {
            var activeZoneCtrl = this.component.getActiveZoneControl(),
                activeZonePlayerId = activeZoneCtrl ? activeZoneCtrl.details.playerid : -1;
            return activeZonePlayerId;
        }

        _getActiveZoneStaticGroupId(visiblePlayerId) {
            var activeZonePlayerId = visiblePlayerId || this._getActiveZonePlayerId();

            if (activeZonePlayerId !== -1) {
                return this.playerMap[activeZonePlayerId].staticGroup;
            } else {
                return false;
            }
        }

        _notifyUpdateListeners() {
            Object.values(this._updListeners).forEach(function (listenerFn) {
                listenerFn();
            });
        }

    }

    Components.Audioserver.extensions.DynamicGroupsExt = DynamicGroupsExt;
    return Components;
}(Components || {});
