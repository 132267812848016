'use strict';

window.Components = function (Components) {
    class ZoneFavoriteExt extends Components.Extension {
        //region Getter
        get control() {
            return this.component.activeZoneControl;
        }

        get currentZoneId() {
            return this.control.details.playerid;
        } //endregion Getter


        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Called when an item is asked to be deleted. Returns whether or not the item
         * has to be removed from the data set right away or not.
         * @param item  the item to remove
         * @returns {*} whether or not to remove it from the views data set.
         */
        deleteZoneFavorite(item) {
            var cmd = MusicServerEnum.AudioCommands.ZONE_FAV.REMOVE + item[MusicServerEnum.Event.ID];
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": deleteZoneFavorite via " + cmd);
            return this.component.sendMediaServerCommand('audio/cfg/roomfavs/' + this.currentZoneId + "/" + cmd);
        }

        /**
         * Will present the addItemScreen and also take care of handling when an item was selected for being
         * saved as favorite.
         * @param details   the details of the calling screen.
         * @param viewCtrl  the viewController to present the screen state in.
         * @private
         */
        selectNewZoneFavorite(details, viewCtrl) {
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": selectNewZoneFavorite"); // create a deferred that will resolve once an item was added. It will inform all UIs between this
            // view and the one where the item was selected on it.

            var addModeDeferred = Q.defer(); // like the one that the editableMediaContentScreen provides

            var newDetails = cloneObject(details),
                mediaScreen = Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_FAVS,
                addFn;
            newDetails.control = details.control;
            newDetails.targetMediaScreenState = Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA; // prepare the save-favorite chain

            addFn = function (cbArgument) {
                // an item was selected, handle it (will open up a name popup and then send the save cmd)
                var prms = this._handleFavoriteSelected(cbArgument); // now handle the result of the save command. It's important to return a promise, since e.g.
                // the cell that triggered it needs to know about it.


                return prms.then(function (item) {
                    // inform the UI that the item was successfully added
                    addModeDeferred.notify(item);
                    addModeDeferred.resolve(item);
                }.bind(this), function (err) {
                    console.error(this.name + ": something went wrong, either while no name was agreed upon or saving the favorite failed"); // if there was no name agreed upon, don't stop everything.

                    if (err !== "cancel") {
                        addModeDeferred.reject();
                    }

                    throw err;
                }.bind(this));
            }.bind(this); // Prepare the details for the addItemScreen


            newDetails.addTargetName = _("audio-server.favorites.edit.new");
            newDetails.addFn = addFn; // fn to call when an item is to be added

            newDetails.addPromise = addModeDeferred.promise;
            viewCtrl.showState(mediaScreen, null, newDetails);
            return addModeDeferred.promise;
        }

        /**
         * Called as soon as an item has been selected to be added as zoneFavorite
         * @param cbArgument    the arg containing all info on the selected item
         * @private
         */
        _handleFavoriteSelected(cbArgument) {
            return this._handleAddItem(cbArgument.item, cbArgument.contentType, this.component.getNameForItem(cbArgument.item), cbArgument.mediaTypeDetails ? cbArgument.mediaTypeDetails : null);
        }

        /**
         * Launches an save zoneFavorite cmd for the details provided. It'll either resolve with the item that
         * was added or it'll reject. If it resolves it will also take care of updating the views data.
         * @param item          what item to save as new favorite
         * @param contentType   the contentType where the item was taken from (service, playlist, ..)
         * @param name          the name for the newly created favorite
         * @param mediaTypeDetails      the mediatypedetails, e.g. when adding a service item or a playlist.
         * @returns {*}         the promise that'll resolve or reject.
         * @private
         */
        _handleAddItem(item, contentType, name, mediaTypeDetails) {
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": _handleAddItem - " + JSON.stringify(item));
            var def = Q.defer(),
                playerid = this.control.details.playerid,
                cmd = this.component.getZoneFavoriteSaveCommand(playerid, item, name, contentType, mediaTypeDetails); // check if a command was found for this item

            if (cmd !== null) {
                this.component.sendAddZoneFavoriteCmd(cmd, item, name, this.control).done(function () {
                    def.resolve(item);
                }.bind(this), function () {
                    def.reject();
                });
            } else {
                console.error(this.name + ": Could not find a save zoneFavorite command! won#t save it");
                setTimeout(function () {
                    def.reject();
                }, 0);
            }

            return def.promise;
        }

    }

    Components.Audioserver.extensions.ZoneFavoriteExt = ZoneFavoriteExt;
    return Components;
}(window.Components || {});
