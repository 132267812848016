'use strict';

window.Components = function (Components) {
    var SCAN_EVENT = "ScanEvent";

    class LibraryLoader extends Components.Audioserver.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.isScanning = false;
            this.registerExtensionEv(component.ECEvent.LibraryErrorReceived, this._handleLibraryError.bind(this));
            this.registerExtensionEv(component.ECEvent.ConnEstablished, function () {
                this._requestScanState();
            }.bind(this));
        }

        // -------------------------------------------------------
        //            Handling Events
        // -------------------------------------------------------

        /**
         * Superclass MediaLoaderExtension is already registered for events. Override but still call superclasses
         * handleEvent at first.
         */
        _handleEventReceived(evId, event) {
            super._handleEventReceived(...arguments);

            var key = Object.keys(event)[0];

            if (key === MusicServerEnum.EventIdentifier.LIBRARY.RESCAN) {
                if (this.component.Feature.BG_LIB_SCANING) {
                    // only respond to scan ends, not scan starts - since scanning is processed in the background.
                    if (event[key][0].status === 0) {
                        var reloadedId = event[key][0].id;
                        Debug.Media.LibraryLoader && console.log(this.name, "Received a 'rescanning finished' event - invalidate all content of ID: " + reloadedId);
                        this.rejectAndDeleteAllRequests(MusicServerEnum.ReloadCause.CONTENT_UPDATED); // When invalidating the root the "cache-tree" is being iterated and everything stored
                        // "below" will be invalidated - inclusing the item id provided in the event.
                        this.invalidateContentCachesOf(0, MusicServerEnum.ReloadCause.CONTENT_UPDATED);// but if a second reload arrives, there may not be any data for root or the parents of the
                        // item reloaded (the data of visible folders only is reloaded) - hence the root reload will
                        // not "reach" the item reloaded. Hence launch a separate reload for the item itself.
                    reloadedId && this.invalidateContentCachesOf(reloadedId, MusicServerEnum.ReloadCause.CONTENT_UPDATED);
}
                }

                if (event[key][0].status === 0) {
                    // we're not rescanning.
                    Debug.Media.LibraryLoader && console.log(this.name, "Received a 'rescanning finished'/'not rescanning' event.");
                    this.component.Feature.LIB_SCAN_HANDLING && this._scanEnded();
                } else {
                    Debug.Media.LibraryLoader && console.log(this.name, "Received a 'scanning active' event.");
                    this.component.Feature.LIB_SCAN_HANDLING && this._scanStarted(event[key][0].files, event[key][0].folders); // only reject & invalidate when a start was received from a music server that isn't capable of
                    // background scanning.

                    if (!this.component.Feature.BG_LIB_SCANING) {
                        this.rejectAndDeleteAllRequests(MusicServerEnum.PopupMessageId.RESCANNING);
                        this.invalidateContentCachesOf(0, MusicServerEnum.PopupMessageId.RESCANNING);
                    }
                }
            }
        }

        _handleLibraryError(evId, event) {
            // something is wrong with the library. invalidate all caches.
            console.error("Library error received, invalidating the cache! " + JSON.stringify(event));
            this.invalidateContentCachesOf(0, MusicServerEnum.PopupMessageId.BADFILE);
        }

        // -------------------------------------------------------
        //            Public Methods Events
        // -------------------------------------------------------
        getAcquireCommand() {
            return 'getmediafolder';
        }

        /**
         * Registers a new listener for scanning events.
         * @param listener      the function to call when a scan event is received
         * @returns {number}    a listenerId using which the listener can unregister again.
         */
        registerScanListener(listener) {
            Debug.Media.LibraryLoader && console.log(this.name, "registerScanListener");
            var listenerId = this.on(SCAN_EVENT, listener);
            setTimeout(function () {
                // dispatch first state async right away
                listener(null, {
                    isScanning: this.isScanning
                });
            }.bind(this), 200);
            return listenerId;
        }

        /**
         * Removes a scan listener from receiving scan state updates.
         * @param listenerId    the ID to identify the listener, returned by the registerScanListener method.
         */
        removeScanListener(listenerId) {
            Debug.Media.LibraryLoader && console.log(this.name, "removeScanListener: " + JSON.stringify(listenerId));
            this.off(listenerId);
        }

        /**
         * This method is used to deal with errors that occurred during the current media request. After this method
         * the current request has already been rejected. after this method the next request from the queue will be
         * processed.
         * @param error         the error that has occured
         * @returns {boolean}   wether or not the error has already been processed
         * @private
         */
        _processRequestError(error) {
            Debug.Media.LibraryLoader && console.log("Processing Error: " + JSON.stringify(error));
            var handled = false;

            if (error.reason === MusicServerEnum.PopupMessageId.RESCANNING) {
                var nextRequest;

                while (nextRequest = this.requestQueue.shift()) {
                    if (nextRequest.deferred) {
                        nextRequest.deferred.reject(error.reason);
                    }
                }

                handled = true;
            }

            return handled;
        }

        _requestScanState() {
            Debug.Media.LibraryLoader && console.log(this.name, "_requestScanState");
            this.component.sendMediaServerCommand(MusicServerEnum.Commands.LIBRARY.SCAN_STATUS, true).done(function (res) {
                Debug.Media.LibraryLoader && console.log(this.name, "_requestScanState: " + JSON.stringify(res));
                var isScanning = res.data[0].scanning !== 0;

                if (this.isScanning !== isScanning) {
                    if (isScanning) {
                        this._scanStarted(res.data[0].files, res.data[0].folders);
                    } else {
                        this._scanEnded();
                    }
                }
            }.bind(this), function () {
                Debug.Media.LibraryLoader && console.error(this.name + ":_requestScanState: failed!");
            }.bind(this));
        }

        _scanStarted(files, folders) {
            Debug.Media.LibraryLoader && console.log(this.name, "_scanStarted");

            this._updateAndDispatchScanState({
                isScanning: true,
                files: files,
                folders: folders
            });
        }

        _scanEnded() {
            Debug.Media.LibraryLoader && console.log(this.name, "_scanEnded");

            this._updateAndDispatchScanState({
                isScanning: false
            });
        }

        _updateAndDispatchScanState(scanRes) {
            Debug.Media.LibraryLoader && console.log(this.name, "_updateAndDispatchScanState: " + JSON.stringify(scanRes));
            this.isScanning = scanRes.isScanning;
            this.emit(SCAN_EVENT, scanRes);
        }

    }

    Components.Audioserver.extensions.LibraryLoader = LibraryLoader;
    return Components;
}(window.Components || {});
