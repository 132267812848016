'use strict';

window.Components = function (Components) {
    class InputExt extends Components.Extension {
        //region Getter
        get inputs() {
            return this._inputs;
        } //endregion Getter

        //region Setter
        set inputs(newVal) {
            this._inputs = newVal;
        } //endregion Setter


        constructor(component, extensionChannel) {
            super(...arguments);
            this.name = "InputExt@" + component.getServerSerialNumber();
            this.inputs = null; // static input for music-Servers with API 1.0

            this.staticInputs = [{
                id: "1",
                name: _("media.section.line-in") + " " + 1,
                description: _("media.section.line-in") + " " + 1,
                enabled: true,
                cmd: 'linein',
                type: 0,
                isStatic: true,
                contentType: MusicServerEnum.MediaContentType.LINEIN
            }];
            this.promises = {};
            this.registerExtensionEv(this.component.ECEvent.FeatureCheckingReady, this._handleFeatureCheckingReady.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ConnClosed, this._handleConnectionClosed.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                this._handleResult(result);
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.EventReceived, function (evId, result) {
                if (result.hasOwnProperty(MusicServerEnum.EventIdentifier.INPUT_CHANGED)) {
                    Debug.Media.InputExt && console.log(this.name, "inputs changed event received!");
                    this.inputs = null;
                    this.getInputs(true);
                }
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultErrorReceived, function (evId, error) {
                this._handleResultError(error);
            }.bind(this));
        }

        getInputs(force) {
            Debug.Media.InputExt && console.log(this.name, "getInputs(force=" + !!force + ")");
            var result = {};

            if (this.currentInputsDeferred != null && this.currentInputsDeferred.promise.isPending()) {
                Debug.Media.InputExt && console.log(this.name,"     return deferred");
                return {
                    promise: this.currentInputsDeferred.promise,
                    data: this.inputs
                };
            } else if (this.inputs && this.currentInputsDeferred && !this.currentInputsDeferred.promise.isPending()) {
                Debug.Media.InputExt && console.log(this.name,"     return cached inputs", this.inputs);
                return {
                    promise: Q.resolve(this.inputs),
                    data: this.inputs
                };
            }

            Debug.Media.InputExt && console.log(this.name,"     request inputs");
            this.currentInputsDeferred = Q.defer();

            if (this.inputs && this.featureCheckingReady) {
                Debug.Media.InputExt && console.log(this.name,"     lineIn are cached && feature-checking is ready");
                result.data = this.inputs;
            } // everytime someone requests the lineIn, load them!

            if (result.data && !force) {
                Debug.Media.InputExt && console.warn(this.name, "Unneccesary Reload, inputs known: " + JSON.stringify(result.data));
                this.currentInputsDeferred.resolve(result.data);
            } else {
                Debug.Media.InputExt && console.warn(this.name, "   request inputs!");

                this._requestInputs();
            }

            result.promise = this.currentInputsDeferred.promise;
            return result;
        }

        updateInputEnabled(input) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.enabled === input.enabled) {
                    // nothing changed
                    return null;
                } else {
                    storedInput.enabled = input.enabled;
                }

                var cmd = MusicServerEnum.Commands.INPUT.SET.CMD + input.id + MusicServerEnum.Commands.INPUT.SET.ENABLED + input.enabled;
                return this._sendCmdForInput(cmd, input);
            }.bind(this));
        }

        /**
         * Checks and updates the input volume level. Only returns a promise if final
         * @param input     the updated input object
         * @param final     whether or not this setting is final
         * @returns {*}     either a promise (final) or nothing
         */
        updateInputVolume(input, final) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.inputvolume === input.inputvolume) {
                    // nothing changed
                    return null;
                } else if (final) {
                    storedInput.inputvolume = input.inputvolume;
                }

                var volCmd = final ? MusicServerEnum.Commands.INPUT.SET.INPUT_VOLUME_FINAL : MusicServerEnum.Commands.INPUT.SET.INPUT_VOLUME;
                var cmd = MusicServerEnum.Commands.INPUT.SET.CMD + input.id + volCmd + input.inputvolume;

                var promise = this._sendCmdForInput(cmd, input);

                if (!final) {
                    promise = null;
                }

                return promise;
            }.bind(this));
        }

        updateInputName(input) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.name === input.name) {
                    // nothing changed
                    return null;
                } else {
                    storedInput.name = input.name;
                }

                var cmd = MusicServerEnum.Commands.INPUT.SET.CMD + input.id + MusicServerEnum.Commands.INPUT.SET.NAME + input.name;
                return this._sendCmdForInput(cmd, input);
            }.bind(this));
        }

        updateInputType(input) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.icontype === input.icontype) {
                    // nothing changed
                    return null;
                } else {
                    storedInput.icontype = input.icontype;
                }

                var cmd = MusicServerEnum.Commands.INPUT.SET.CMD + input.id + MusicServerEnum.Commands.INPUT.SET.TYPE + input.icontype;
                return this._sendCmdForInput(cmd, input);
            }.bind(this));
        }

        /**
         * an input located on another audioserver can only be modified by sending the commands to the "parent" AS
         * @param cmd
         * @param input
         * @returns {*}
         * @private
         */
        _sendCmdForInput(cmd, input) {
            Debug.Media.InputExt && console.log(this.name, "_sendCmdForInput: " + cmd + ", input=" + JSON.stringify(input));
            var comp, svr;

            try {
                svr = this.component.getMediaServerBySerial(input.serverSerial);
                comp = window.audioserverComps && svr ? window.audioserverComps[svr.uuidAction] : this.component;

                if (!comp) {
                    console.error(this.name, "_sendCmdForInput: failed to look up component for input, use own!");
                    comp = this.component;
                }

                return comp.sendMediaServerCommand(cmd, true);
            } catch (ex) {
                console.error(this.name, "_sendCmdForInput: exception while sending via other comp, use own! " + JSON.stringify(ex));
                return this._sendCmd(cmd);
            }
        }

        _sendCmd(cmd) {
            Debug.Media.InputExt && console.log(this.name, "_sendCmd: " + cmd);

            if (this.promises.hasOwnProperty(cmd)) {
                throw "Previous operation not finished yet!";
            }

            this.promises[cmd] = Q.defer();
            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: cmd
            });
            return this.promises[cmd].promise;
        }

        // -------------------------------------------------------------------------
        // PRIVATE
        // -------------------------------------------------------------------------
        _requestInputs() {
            Debug.Media.InputExt && console.log(this.name, "_requestInputs");

            if (!this.featureCheckingReady) {
                Debug.Media.InputExt && console.log(this.name, "          feature-checking not ready yet. don't rq lineIn");
            } else if (this.component.Feature.DYNAMIC_INPUTS) {
                this.channel.emit(this.component.ECEvent.SendMessage, {
                    cmd: MusicServerEnum.Commands.INPUT.GET,
                    auto: true
                });
            } else {
                Debug.Media.InputExt && console.log(this.name, "          no dynamic lineIn, return static one");
                setTimeout(function () {
                    if (this.currentInputsDeferred) {
                        Debug.Media.InputExt && console.log(this.name, "         return static input - " + JSON.stringify(this.staticInputs));
                        this.currentInputsDeferred.resolve(this.staticInputs);
                        this.currentInputsDeferred = null;
                    } else {
                        Debug.Media.InputExt && console.log(this.name, "          no deferred, don't set static input");
                    }
                }.bind(this), 200);
            }
        }

        // -------------------------------------------------------------------------
        // Handle Extension Channel Events
        _handleResult(result) {
            var currSvrSerial;

            if (!result.hasOwnProperty("command")) {// nothing to do
            } else if (result.command === MusicServerEnum.Commands.INPUT.GET) {
                // get services result received
                Debug.Media.InputExt && console.log(this.name, "result received: " + JSON.stringify(result));
                currSvrSerial = this.component.getServerSerialNumber();
                var sameNameMap = {}; // Check if the icontype is correct and add the default type if it is missing or invalid

                result.data.forEach(function (input) {
                    if (!MusicServerEnum.LineInMap[input.icontype]) {
                        input.icontype = 0; // Default Type
                    }

                    input.contentType = MusicServerEnum.MediaContentType.LINEIN;

                    if (!input.audiopath) {
                        // the cmd property of a line in looks like this: "linein/504F94F00946#1000001"
                        // but when stored as favorite, the audiopath looks like this: "linein:504F94F00946#1000001"
                        // so ensure the audiopath looks like the one in the favorites to avoid having duplicate linein-favs.
                        input.audiopath = input.cmd.replace("/", ":");
                    } // extract server serial number


                    try {
                        input.serverSerial = input.id.split("#")[0];
                        var server = this.component.getMediaServerBySerial(input.serverSerial);

                        if (server) {
                            input.serverName = this.component.getServerName(null, server);
                        } // set flag to easily identify the right input.


                    input.isFromCurrentAudioserver = input.serverSerial === currSvrSerial;
                    } catch (ex) {
                        console.error(this.name, "_handleResult - failed to retrieve serverSerial from input id! " + JSON.stringify(input));
                    }

                    if (input.description === "") {
                        delete input.description;
                    }

                    sameNameMap[input.name] = sameNameMap.hasOwnProperty(input.name);
                }.bind(this)); // iterate again, check sameNameMap, add audioserver to identify inputs with the same name.

                result.data.forEach(function (input) {
                    if (sameNameMap[input.name]) {input.uniqueSubtitle = input.description || input.serverName || input.audiopath;
                        Debug.Media.InputExt && console.log(this.name, "   input " + input.name + " (" + input.audiopath + ") is NOT unique, add add info: " +
                        input.uniqueSubtitle );
                    } else {
                        Debug.Media.InputExt && console.log(this.name, "   input " + input.name + " is unique!");
                        input.uniqueSubtitle = null; // no need.
                    }
                }.bind(this));
                this.inputs = result.data;

                if (this.currentInputsDeferred) {
                    this.currentInputsDeferred.resolve(this.inputs);
                    this.currentInputsDeferred = null;
                }
            } else if (this.promises.hasOwnProperty(result.command)) {
                if (this.promises[result.command]) {
                    this.promises[result.command].resolve();
                    delete this.promises[result.command];
                }
            }
        }

        _handleResultError(error) {
            if (!error.hasOwnProperty("command")) {// Nothing to do.
            } else if (error.command === MusicServerEnum.Commands.INPUT.GET) {
                // get services result received
                Debug.Media.InputExt && console.log(this.name, "error received: " + JSON.stringify(result));
                this.services = null;

                if (this.currentInputsDeferred) {
                    this.currentInputsDeferred.reject();
                    this.currentInputsDeferred = null;
                }
            } else if (this.promises.hasOwnProperty(error.command)) {
                if (this.promises[error.command]) {
                    this.promises[error.command].reject();
                    delete this.promises[error.command];
                }
            }
        }

        _handleFeatureCheckingReady() {
            Debug.Media.InputExt && console.log(this.name, "_handleFeatureCheckingReady");
            this.featureCheckingReady = true;

            if (this.currentInputsDeferred) {
                this._requestInputs();
            }
        }

        _handleConnectionClosed() {
            Debug.Media.InputExt && console.log(this.name, "_handleConnectionClosed");
            this.inputs = null;
            this.featureCheckingReady = false;
        }

        /**
         * Gets a specific input according to the provided ID
         * @note This function will also load the inputs if missing
         * @param id
         * @returns {*}
         * @private
         */
        _getInput(id) {
            var result;

            if (this.inputs === null) {
                result = this.getInputs().promise.then(function () {
                    return this._getInput(id);
                }.bind(this));
            } else {
                result = Q(this.inputs.find(function (obj) {
                    return obj.id === id;
                }));
            }

            return result;
        }

    }

    Components.Audioserver.extensions.InputExt = InputExt;
    return Components;
}(window.Components || {});
