'use strict';

window.Components = function (Components) {
    class InputLoader extends Components.Audioserver.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        requestContentBatch(id, nItems, mediaTypeDetails) {
            return this.requestContent.apply(this, arguments); // not required to batch, different handling
        }

        /**
         * Requests the favorites for the active zone.
         * @result Returns a promise that dispatches the results
         */
        requestContent(id, nItems, mediaTypeDetails) {
            var result = {};
            this.deferred = this.deferred || Q.defer();

            if (this.inputs) {
                setTimeout(function () {
                    this._dispatchResult(this._getResultToDispatch(this.inputs));
                }.bind(this), 5);
            } else {
                this.channel.extensions.inputExt.getInputs().promise.done(function (inputs) {
                    this.inputs = inputs;
                    this.inputs.forEach(function (input) {
                        input.contentType = MusicServerEnum.MediaContentType.LINEIN;
                    });

                    this._dispatchResult(this._getResultToDispatch(this.inputs));
                }.bind(this));
            }

            result.promise = this.deferred.promise;
            return result;
        }

        /**
         * Called whenever the cache needs to be invalidated (e.g. when the favorites where edited). Will inform
         * all registered listeners to reload their data.
         * @param id            the id of the element who's cache is to be invalidated.
         * @param reason  why where the contens invalidated? used for the reloadEvent
         * @param remove  should the view reload the content or should it remove itself from the screen.
         */
        invalidateContentCachesOf(id, reason, remove) {
            Debug.Media.MiniserverLoader && console.log(this.name, "invalidateContentCachesOf: " + id + " - " + reason);
            this.inputs = null;

            this._dispatchReloadEvent(reason, remove);
        }

        /**
         * Stump - not necessary in this implementation
         */
        prefetchContent() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        stopRequestFor() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        rejectAndDeleteAllRequests() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        getAcquireCommand() {
        }

        getAcquireCommandArguments(request) {
            return null;
        }

        _doesHandleEvent(event) {
            return event.hasOwnProperty(MusicServerEnum.EventIdentifier.INPUT_CHANGED);
        }

        _processEvent(event) {
            this.invalidateContentCachesOf(0, MusicServerEnum.ReloadCause.CONTENT_UPDATED);
        }

        _getResultToDispatch(inputs) {
            return {
                items: inputs,
                totalitems: inputs.length,
                isFinished: true
            };
        }

        _dispatchResult(result) {
            result = cloneObject(result);
            Debug.Media.MiniserverLoader && console.log(this.name, "_dispatchResult: " + JSON.stringify(result));
            Debug.Media.InputExt &&console.info(this.name, "Dispatching new inputs");
            this.deferred && this.deferred.resolve(this.prepareResult(result, true));
            this.deferred = null;
        }

    }

    Components.Audioserver.extensions.InputLoader = InputLoader;
    return Components;
}(window.Components || {});
