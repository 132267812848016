'use strict';

window.Components = function (Components) {
    class AudioZoneSettings extends Components.Extension {
        /**
         * Creates a component that enables to get/set settings of various zones. Does not handle any events,
         * settings need to be polled instead.
         * @param component
         * @param extensionChannel
         */
        constructor(component, extensionChannel) {
            super(...arguments);
            this.registerExtensionEv(this.component.ECEvent.FeatureCheckingReady, this._handleFeatureCheckingReady.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                this._handleResult(result);
            }.bind(this));
            this.sentCommands = {}; // will hold all the cmds that have been sent from this ext.
        }

        /**
         * Registers for setting changes in an audioZone. Initially all available settings are automatically
         * requested when a new registration is made.
         * @param playerID              the playerId of the zone
         * @param onSettingsReceived    the eventHandler callback
         * @returns {*}                 the unregister function returned by the eventHandler
         */
        registerForAudioZoneSettings(playerID, onSettingsReceived) {
            Debug.Media.AudioZoneSettings && console.log(this.name + " registerForAudioZoneSettings: " + playerID);

            if (!playerID) {
                console.error("PlayerID is mandatory for registering!");
                return;
            }

            if (!onSettingsReceived || onSettingsReceived == null) {
                console.error("callback function is mandatory for registering with an AudioZoneSettings!");
                return;
            }

            var listenerID = this.on(this._getEventIdForPlayer(playerID), onSettingsReceived); // no caching at the moment, re-request each time someone registers.

            this._requestSettingsForPlayer(playerID);

            return listenerID;
        }

        /**
         * When done editing/monitoring the settings, this is used to unregister
         * @param listenerID    the result of registerForAudioZoneSettings.
         */
        unregisterFromAudioZoneSettings(listenerID) {
            if (typeof listenerID === 'undefined') {
                console.error("ListenerID is mandatory for unregistering!");
                return;
            }

            this.off(listenerID);
        }

        /**
         * Used to set or get settings. "audio/cfg/" is prepended to command and "/" plus playerId is appended.
         * If a value is passed in it is appended at the end - then it's a setter.
         * @param playerId      the playerId to set
         * @param command       the command identifying the setting to set/get
         * @param [value]       an optional value, if missing the current setting is requested
         */
        sendSetting(playerId, command, value) {
            var fullCommand = "audio/cfg/" + command + "/" + playerId;
            var stateRequest = true;

            if (typeof value !== 'undefined' && typeof value !== 'object') {
                fullCommand += "/" + value;
                stateRequest = false;
            }

            Debug.Media.AudioZoneSettings && console.log(this.name + " sendSetting: " + "" + fullCommand);
            this.sentCommands[fullCommand] = true;
            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: fullCommand,
                auto: stateRequest
            });
        }

        // -------------------------------------------------------------------------
        // PRIVATE
        // -------------------------------------------------------------------------

        /**
         * Create an event-id for settings of a certain zone
         * @param playerID      the playerId of the zone
         * @returns {string}    the event-id for settings of a certain zone
         * @private
         */
        _getEventIdForPlayer(playerID) {
            return 'settingsOfZone' + playerID;
        }

        /**
         * Requests all settings of a certain player
         * @param playerId  the player the settings are to be requested from
         * @private
         */
        _requestSettingsForPlayer(playerId) {
            Debug.Media.AudioZoneSettings && console.log(this.name + " _requestSettingsForPlayer " + "" + playerId);

            for (var i = 0; i < this.statusCommands.length; i++) {
                var cmd = this.statusCommands[i];
                this.sendSetting(playerId, cmd);
            }
        }

        /**
         * Checks if the result is of any interest for the settings of a zone. if so, it's processed and
         * dispatched to the listeners. Otherwise it does not care about it.
         * @param result    the result object
         * @private
         */
        _handleResult(result) {
            Debug.Media.AudioZoneSettings && console.log(this.name + " _handleResult " + "" + result.command);

            try {
                if (!this._doHandleResultOf(result.command) && !this._oldDoHandleResultOf(result.oldCommand)) {
                    Debug.Media.AudioZoneSettings && console.log(this.name + " doesn't handle " + "" + result.command);
                    return;
                }

                var resultObjects = result.data; // result.data is an array of resultObj!

                for (var i = 0; i < resultObjects.length; i++) {
                    // check what player/zone this resultObj belongs to. Dispatch accordingly.
                    var resultObj = resultObjects[i];

                    if (resultObj.hasOwnProperty(MusicServerEnum.Event.PLAYER_ID)) {
                        var playerId = resultObj[MusicServerEnum.Event.PLAYER_ID]; // inform event listeners

                        Debug.Media.AudioZoneSettings && console.log(this.name + "  emit to listeners");
                        this.emit(this._getEventIdForPlayer(playerId), resultObj);
                    } else {
                        console.error("Settings event without player ID not handled.");
                    }
                }
            } catch (exc) {
                console.error("AudioZoneSettingsExt could not connect '" + result.oldCommand + "' to a result-receiver!");
                console.error(exc.stack);
            }
        }

        /**
         * checks if the result is of one of the settings-commands
         * @param command       the command id
         * @returns {boolean}   whether or not it's a settings command.
         * @private
         */
        _doHandleResultOf(command) {
            var handled = this.sentCommands.hasOwnProperty(command);

            if (handled) {
                delete this.sentCommands[command];
            }

            return handled;
        }

        /**
         * checks if the result is of one of the settings-commands
         * @param command       the command id
         * @returns {boolean}   whether or not it's a settings command.
         * @private
         */
        _oldDoHandleResultOf(command) {
            var contained = false;

            for (var i = 0; i < this.statusCommands.length; i++) {
                contained = contained || this.statusCommands[i] === command;
            }

            return contained;
        }

        _handleFeatureCheckingReady() {
            this.statusCommands = this._getStatusCommands();
        }

        _getStatusCommands() {
            var cmds = [MusicServerEnum.AudioCommands.CFG.AUDIO_DELAY, MusicServerEnum.AudioCommands.CFG.MAX_VOLUME, MusicServerEnum.AudioCommands.CFG.DEFAULT_VOLUME];

            if (this.component.Feature.EQUALIZER) {
                cmds.push(MusicServerEnum.AudioCommands.CFG.EQUALIZER);
            }

            return cmds;
        }
    }

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    if (!("extensions" in Components.Audioserver)) {
        Components.Audioserver.extensions = {};
    }

    Components.Audioserver.extensions.AudioZoneSettings = AudioZoneSettings;
    return Components;
}(window.Components || {});
