/**
 * Contains all additional information used by the autopilot generator (e.g enumeration, ...)
 */
window.AutomaticDesignerEnums = {
    AUTOMATIC_DESIGNER_STATE_UUID: 'autopilotGenerator',
    SECTIONS_VERSION_FILE_NAME: 'ap_sections_version',
    SECTIONS_FILE_NAME: 'ap_sections',
    CAPABILITIES_VERSION_FILE_NAME: 'ap_controls_version',
    CAPABILITIES_FILE_NAME: 'ap_controls',
    RULES_FILE_NAME: "ap_rules",
    SCENES_FILE_NAME: "ap_scenes",
    REGISTRATION: "registration",
    KNOWN_TYPES: {
        ACTIONS: {
            OPERATING_MODES: {
                STRING: "operatingModes",
                INT: [1]
            },
            ROOMS: {
                STRING: "rooms",
                INT: [0]
            },
            CATS: {
                STRING: "cats",
                INT: [0]
            },
            FUNCTIONS: {
                STRING: "functions",
                INT: [0]
            },
            NOTIFICATIONS: {
                STRING: "notifications",
                INT: [2, 3, 4, 6, 7]
            },
            SCENES: {
                STRING: "scenes",
                INT: [8]
            },
            SCRIPTS: {
                STRING: "scripts",
                INT: [5]
            }
        },
        EVENTS: {
            TIMES: {
                STRING: "times",
                INT: [3, 4, 5]
            },
            ROOMS: {
                STRING: "rooms",
                INT: [0]
            },
            CATS: {
                STRING: "cats",
                INT: [0]
            },
            FUNCTIONS: {
                STRING: "functions",
                INT: [0]
            },
            WEATHER: {
                STRING: "weather",
                INT: [2]
            },
            OPERATING_MODES: {
                STRING: "operatingModes",
                INT: [1]
            }
        }
    },
    KNOWN_INT_TYPES_CATEGORIES: {
        NOTIFICATIONS: {
            CALLER: 93,
            MAILER: 92,
            PUSH: 96,
            SYSTEM_STATE: 98,
            TTS: 223
        },
        OPERATING_MODE: 21
    },
    KNOWN_DESC_IDS: {
        NOTIFICATION: 96001,
        MAIL: 92001,
        CALLER: 93001,
        TTS: 223101,
        SYSTEM_STATE: 98101,
        AUDIO_ZONE_V2_FAV: 507105,
        WAITING: 341801,
        SCENES: 511101
    },
    KEYWORDS: {
        OPERATOR: 'operator',
        NAME_PLACEHOLDER: 'name',
        LINE_BREAK: '<br>'
    },
    SCREEN_TYPES: {
        EVENTS: 'events',
        ACTIONS: 'actions'
    },
    ENTRY_TYPES: {
        ANALOG: 'analog',
        DIGITAL: 'digital',
        TEXT: "text",
        NOTIFICATION: 'notification',
        MAIL: 'mail',
        CALLER: 'caller',
        SYSTEM_STATE: "sysmsg",
        TTS: "tts",
        // Theoretically should be analog subtypes!?
        DATE: 'date',
        TIME: 'time',
        DURATION: 'duration',
        ANALOG_SINGLE_SELECT: "analogSingleSelect",
        ANALOG_MULTI_SELECT: "analogMultiSelect"
    },
    CAPABILITY_TYPES: {
        INPUT: 'input',
        MULTILINE: 'multiline',
        DATE: 'date',
        TIME: 'time',
        DURATION: 'duration',
        ANALOG_SINGLE_SELECT: "analogSingleSelect",
        ANALOG_MULTI_SELECT: "analogMultiSelect",
        NOTIFICATION: 'notification',
        MAIL: 'mail',
        CALLER: 'caller'
    },
    EVENT_TYPES: {
        CONTROL: 0,
        OPERATING_MODE: 1,
        WEATHER: 2,
        TIME: 3,
        DATE: 4,
        CUSTOM_TIME: 5
    },
    ACTION_TYPES: {
        CONTROL: 0,
        OPERATING_MODE: 1,
        CLOUD_MAILER: 2,
        CALLER: 3,
        NOTIFICATION: 4,
        PAUSE: 5
    },
    LOX_APP_TYPES: {
        OPERATING_MODES: 'operatingModes',
        CONTROLS: 'controls',
        WEATHER: 'weatherFieldTypes'
    },
    WEATHER_TYPE: 'weather',
    WEATHER_DATA: 'weatherData',
    RULE_TYPE: {
        RULE: 1,
        SCENE: 2
    },
    RUlE_TEMPLATE: {
        name: "",
        description: "",
        active: true,
        type: 1,
        id: "",
        operator: 3,
        // or
        eventGroups: [],
        actions: [],
        ruleFlags: 0,
        // bitmap of additional flags
        visu: {}
    },
    SCENE_TEMPLATE: {
        type: 2,
        id: "",
        actions: [],
        permissions: {
            users: [],
            groups: []
        },
        visu: {
            useVisu: false,
            rating: 0,
            desc: "",
            useVisuPwd: false
        }
    },
    EVENT_GROUP_TEMPLATE: {
        "operator": 1,
        // and
        "events": []
    },
    VALUE_OPERATORS: {
        BOOL: 0,
        EQUAL: 1,
        NOT_EQUAL: 2,
        GREATER: 3,
        LESS: 4,
        GREATER_OR_EQUAL: 5,
        LESS_OR_EQUAL: 6,
        // Text operators
        TEXT_EQUALS: 7,
        TEXT_NOT_EQUAL: 8,
        TEXT_CONTAINS: 9,
        TEXT_NOT_CONTAINS: 10,
        TEXT_STARTS_WITH: 11,
        TEXT_NOT_STARTS_WITH: 12
    },
    PAGE_VIEW_INDEX: 2,
    VAL_KEYS: {
        VALUE: 'value',
        ARR_VALUES: 'arrValues'
    },
    DURATION_WHEEL_ORDER: 'hhiiss',
    UNKNOWN_CAPABILITY_DESC_ID: 0,
    GENERIC_FUNCTION_CMD_FALLBACK_DESC_ID: 1,
    RULE_EXTENSION: {
        NONE: 0,
        TASK_RECORDER: 1,
        SCENE: 2
    },
    RULE_FLAGS: {
        NOTIFY_ON_EXEC: 1
    }
};
