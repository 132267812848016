'use strict';

window.Components = function (Components) {
    class AudioZone extends Components.Extension {
        //region Static
        static ListenerType = {
            AUDIO: "audioListener",
            CONNECTION: "connectionListener",
            QUEUE: "queueListener"
        }; //endregion Static

        constructor(component, extensionChannel, listenerObj) {
            super(...arguments);
            this.name += "@" + component.getServerName();
            this.listenerObj = listenerObj || {};
            this.listenerObj[this.constructor.ListenerType.AUDIO] = this.listenerObj[this.constructor.ListenerType.AUDIO] || {};
            this.listenerObj[this.constructor.ListenerType.CONNECTION] = this.listenerObj[this.constructor.ListenerType.CONNECTION] || {};
            this.listenerObj[this.constructor.ListenerType.QUEUE] = this.listenerObj[this.constructor.ListenerType.QUEUE] || {};
            this.listernerIDCounter = 0; // Get the listernerIDCounter from the passed listenerObj to correctly increment the listenerId again

            Object.values(this.constructor.ListenerType).forEach(function (listenerType) {
                Object.keys(this.listenerObj[listenerType]).forEach(function (key) {
                    this.listernerIDCounter = Math.max(this.listernerIDCounter, key);
                }.bind(this));
            }.bind(this));
            this.socketIsOpen = false;
            this.sentCommands = {}; // when the connection is established, re-request the player states!

            this.registerExtensionEv(this.component.ECEvent.ConnEstablished, function () {
                this._handleConnectionEstablished();
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ConnClosed, function () {
                this._handleConnectionClosed();
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.EventReceived, function (evId, event) {
                this._handleEvent(event);
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                this._handleResult(result);
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ServerStateReceived, function (evId, result) {
                this.serverState = result;
                Debug.Media.AudioZoneExt && console.info(this.name, "ServerStateReceived: " + JSON.stringify(result));

                this._dispatchConnectionState(this.socketIsOpen, this.serverState);
            }.bind(this)); // dispatch the connection state each time a task recorder starts or stops. this way the UI is adopted

            this.registerExtensionEv(this.component.ECEvent.TaskRecorderStart, function () {
                this._dispatchConnectionState(this.socketIsOpen, this.serverState);
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.TaskRecorderEnd, function () {
                this._dispatchConnectionState(this.socketIsOpen, this.serverState);
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.FeatureCheckingReady, this._handleFeatureCheckingReady.bind(this));
        }

        registerForAudioZone(playerID, onServerEventFn, onAudioEventFn, onQueueEventFn) {
            var listenerID = this.listernerIDCounter++;

            if (!playerID) {
                console.error("PlayerID is mandatory for registering with an audioZone!");
                return;
            }

            this._registerGen(playerID, listenerID, this.constructor.ListenerType.AUDIO, onAudioEventFn);

            this._registerGen(playerID, listenerID, this.constructor.ListenerType.CONNECTION, onServerEventFn);

            this._registerGen(playerID, listenerID, this.constructor.ListenerType.QUEUE, onQueueEventFn);

            if (onServerEventFn) {
                onServerEventFn(this._createConnectionStateEvent(this.socketIsOpen, this.serverState, this.prevFeatureCheckingReady));
            }

            if (this.socketIsOpen && onAudioEventFn) {
                if (this._hasAudioEventCache(playerID)) {
                    onAudioEventFn(this._getAudioEventCache(playerID));
                } else {
                    // nothing cached, re-request it!
                    this._requestDataForPlayer(playerID);
                }
            }

            if (onQueueEventFn) {
                Debug.Media.QueueOld && console.log(this.name, "registerForAudioZone - with queue: " + playerID);

                if (this._hasQueueCache(playerID)) {
                    onQueueEventFn(this._getQueueCache(playerID));
                } else {
                    // no queue stored yet, aquire it
                    this.send(playerID, {
                        cmd: MusicServerEnum.AudioCommands.QUEUE.GET
                    });
                }
            }

            return listenerID;
        }

        unregisterFromAudioZone(listenerID, playerID) {
            if (!playerID) {
                console.error("PlayerID is mandatory for unregistering with an audioZone!");
                return;
            }

            this._unregisterGen(playerID, listenerID, this.constructor.ListenerType.AUDIO);

            this._unregisterGen(playerID, listenerID, this.constructor.ListenerType.CONNECTION);

            var didUnregister = this._unregisterGen(playerID, listenerID, this.constructor.ListenerType.QUEUE);

            Debug.Media.QueueOld && didUnregister && console.log(this.name, "unregisterFromAudioZone - with queue: " + playerID);
        }

        /**
         * Sends a command object to the player identified by the id
         * @param playerId  the id of the targeted player
         * @param cmdObj    the command object containing the cmd itself and optional argumentTexts
         * @returns {null}
         */
        send(playerId, cmdObj) {
            if (cmdObj instanceof String) {
                console.error(this.name + ":Sending commands now requires a cmd object!");
                cmdObj = {
                    cmd: cmdObj
                };
            }

            var fullCommand = "audio/" + playerId + "/" + cmdObj.cmd;
            var deferred = null;

            if (this._isCommandWithConfirmation(cmdObj.cmd)) {
                deferred = Q.defer();

                if (!this.resultDeferreds) {
                    this.resultDeferreds = {};
                }

                this.resultDeferreds[fullCommand] = deferred;
            }

            cmdObj.cmd = fullCommand;
            this.channel.emit(this.component.ECEvent.SendMessage, cmdObj);
            return deferred ? deferred.promise : Q(true);
        }

        getStatesOfZone(playerid) {
            return this._getAudioEventCache(playerid);
        }

        // -------------------------------------------------------------------------
        // PRIVATE
        // -------------------------------------------------------------------------
        _hasQueueListeners(playerid) {
            var hasListener = this.listenerObj[this.constructor.ListenerType.QUEUE].hasOwnProperty(playerid);
            var listeners = this.listenerObj[this.constructor.ListenerType.QUEUE][playerid];
            hasListener = hasListener && Object.keys(listeners).length > 0;
            return hasListener;
        }

        // -------------------------------------------------------------------------
        // Acquire Data
        _requestDataForPlayer(playerId) {
            Debug.Media.AudioZoneExt && console.info("AudioZoneExt will request all data for " + playerId);
            this.send(playerId, {
                cmd: MusicServerEnum.AudioCommands.STATUS
            });
        }

        // -------------------------------------------------------------------------
        // Handle Extension Channel Events
        _handleResult(result) {
            try {
                // check if a deferred is waiting for a response to this
                if (result.hasOwnProperty('command') && this.resultDeferreds && this.resultDeferreds.hasOwnProperty(result.command)) {
                    var deferred = this.resultDeferreds[result.command];
                    deferred.resolve(result);
                    delete this.resultDeferreds[result.command];
                }

                if (result.hasOwnProperty('command') && this._doHandleResultOf(result.command)) {
                } else if (!this._doHandleResultOfOldCommand(result.oldCommand)) {
                    Debug.Media.AudioZoneExt && console.info("AudioZoneExt does not handle the command '" + result.oldCommand + "'");
                    return;
                }

                Debug.Media.AudioZoneExt && console.info("AudioZoneExt will handle the result of " + result.oldCommand); // iterate over these resultObjs - each one affects a single player/zone.

                var resultObjects = result.data; // result.data is an array or resultObj!

                for (var i = 0; i < resultObjects.length; i++) {
                    // check what player/zone this resultObj belongs to. Dispatch accordingly.
                    var resultObj = resultObjects[i];

                    if (resultObj.hasOwnProperty(MusicServerEnum.Event.PLAYER_ID)) {
                        var playerId = resultObj[MusicServerEnum.Event.PLAYER_ID];

                        if (result.oldCommand === MusicServerEnum.AudioCommands.QUEUE.GET && resultObj.hasOwnProperty('queue') // this is important, since new versions have paginated getqueue.
                        ) {
                            Debug.Media.QueueOld && console.log(this.name, "_handleResult: " + result.oldCommand);

                            this._handleGetQueueResult(playerId, resultObj.queue); // forward the queue to synced zones.


                            this._checkAndForwardQueueToSyncGroup(playerId);
                        }

                        if (result.oldCommand === MusicServerEnum.AudioCommands.STATUS || result.hasOwnProperty("command") && result.command === MusicServerEnum.Commands.AUDIO_STATUS_ALL) {
                            if (result.hasOwnProperty("command") && result.command === MusicServerEnum.Commands.AUDIO_STATUS_ALL) {
                                // the playersdetails result contains a property name for the name of the zone,
                                // in the app this will cause confusion. So delete it.
                                delete resultObj[MusicServerEnum.Event.NAME];
                            }

                            this._handleStatusResult(playerId, resultObj);
                        } // if some client of the AudioZoneExt did send this command, dispatch it


                        if (this.sentCommands[playerId] && this.sentCommands[playerId][result.oldCommand]) {
                            this.sentCommands[playerId][result.oldCommand](result.oldCommand, resultObj);
                            delete this.sentCommands[playerId][result.oldCommand];
                        }
                    } else {
                        // no audioZone cmd
                        Debug.Media.AudioZoneExt && console.info("AudioZoneExt does not handle the command '" + result.oldCommand + "'");
                    }
                }
            } catch (exc) {
                console.error("AudioZoneExt could not connect '" + result.oldCommand + "' to a result-receiver!");
                console.error(exc.stack);
            }
        }

        _handleStatusResult(playerId, data) {
            Debug.Media.AudioZoneExt && console.log("AudioZoneExt handles an Status-Result for " + playerId + ": " + JSON.stringify(data)); // cache the audio status result and dispatch it to the listeners

            this._cacheAndInformAudioListeners(playerId, data);
        }

        _handleGetQueueResult(playerId, data) {
            Debug.Media.QueueOld && console.log(this.name, "_handleGetQueueResult: player " + playerId);

            this._cacheQueue(playerId, data);

            if (this.zoneHandlers && this.zoneHandlers.hasOwnProperty(playerId)) {
                this.zoneHandlers[playerId].cacheQueue(data);
            }

            var queueListeners = this.listenerObj[this.constructor.ListenerType.QUEUE][playerId];

            for (var listenerId in queueListeners) {
                if (queueListeners.hasOwnProperty(listenerId)) {
                    queueListeners[listenerId](data);
                }
            }
        }

        _doHandleResultOfOldCommand(command) {
            var handleIt = false;

            switch (command) {
                case MusicServerEnum.AudioCommands.QUEUE.GET:
                case MusicServerEnum.AudioCommands.STATUS:
                case MusicServerEnum.Commands.AUDIO_STATUS_ALL:
                    handleIt = true;
                    break;

                default:
                    break;
            }

            return handleIt;
        }

        _doHandleResultOf(command) {
            var handleIt = false;

            switch (command) {
                case MusicServerEnum.Commands.AUDIO_STATUS_ALL:
                    handleIt = true;
                    break;

                default:
                    break;
            }

            return handleIt;
        }

        _handleEvent(event) {
            var key = Object.keys(event)[0];
            var eventDataArr = event[key];
            var playerId;
            var eventData;

            if (key === MusicServerEnum.InternalEventIdentifier.ZONE.SYNC_CHANGED) {
                Debug.Media.AudioZoneExt && console.log("AudioZoneExt received an internal sync event " + JSON.stringify(eventDataArr));
                playerId = eventDataArr[MusicServerEnum.Event.PLAYER_ID];

                this._cacheAndInformAudioListeners(playerId, eventDataArr);
            } else if (key === MusicServerEnum.EventIdentifier.AUDIO.GENERAL) {
                Debug.Media.AudioZoneExt && console.log("AudioZoneExt handles an audio event " + JSON.stringify(eventDataArr));

                for (var i = 0; i < eventDataArr.length; i++) {
                    eventData = eventDataArr[i];
                    playerId = eventData[MusicServerEnum.Event.PLAYER_ID];

                    var prevData = this._getAudioEventCache(playerId, true); // cache the audio event and dispatch it to the listeners


                    this._cacheAndInformAudioListeners(playerId, eventData); // Forward audioEvents on the sync-master to it's clients


                    this._checkAndForwardToSyncGroup(playerId, eventData, prevData);
                }
            } else if (key === MusicServerEnum.EventIdentifier.AUDIO.QUEUE) {
                Debug.Media.AudioZoneExt && console.log("AudioZoneExt handles an queue event " + JSON.stringify(eventDataArr));
                Debug.Media.QueueOld && console.log(this.name, "_handleEvent - Queue " + JSON.stringify(eventDataArr));

                for (var j = 0; j < eventDataArr.length; j++) {
                    eventData = eventDataArr[j];
                    playerId = eventData[MusicServerEnum.Event.PLAYER_ID]; // the queue event informs on restrictions that affect the queue (e.g. only when lib tracks
                    // are played, a queue-move or insert is allowed.

                    if (eventData.hasOwnProperty(MusicServerEnum.Event.QUEUE_RESTRICTIONS)) {
                        // forward to listeners (e.g. stateContainer)
                        var passedObj = {};
                        passedObj[MusicServerEnum.Event.QUEUE_RESTRICTIONS] = eventData[MusicServerEnum.Event.QUEUE_RESTRICTIONS];

                        this._cacheAndInformAudioListeners(playerId, passedObj);
                    }

                    var hasQueueListener = this._hasQueueListeners(playerId);

                    var doReload = false;

                    if (this._hasQueueCache(playerId) || this._hasSyncedQueueInCache(playerId)) {
                        // clear queue cache!
                        this._invalidateCacheQueue(playerId);

                        doReload = hasQueueListener;
                    } // Editing queues still uses this audioZoneExt as queue-Source. so don't rely solely on the Feature


                    if (!this.component.Feature.PAGED_QUEUE || doReload) {
                        // if the zone is synced, only the master receives a sync event, the slaves don't
                        this.send(playerId, {
                            cmd: MusicServerEnum.AudioCommands.QUEUE.GET
                        });
                    } else {
                        Debug.Media.QueueOld && console.log(" - unhandled event, hasQueueListener: " + hasQueueListener); // When paged queues are supported, there's no need to handle them here.
                    }
                }
            } else {
                Debug.Media.AudioZoneExt && console.log("AudioZoneExt does not handle " + " " + JSON.stringify(event));
            }
        }

        _handleConnectionEstablished() {
            this.socketIsOpen = true;
            Debug.Media.AudioZoneExt && console.info(this.name, "_handleConnectionEstablished"); // get the zones from the structure file - create zoneHandlers for all available

            var zonesInStructure = this.component.getAvailableZones();
            this.zoneHandlers = {};
            zonesInStructure.forEach(function (zone) {
                this.zoneHandlers[zone.details.playerid] = new Components.Audioserver.extensions.AudioZone.AudioZoneHandler(zone, this._zoneHandlerStateChanged.bind(this));
            }.bind(this));

            this._dispatchConnectionState(this.socketIsOpen, this.serverState);

            if (!Feature.MULTI_MUSIC_SERVER) {
                // request for all players, not only for those that have already been on the UI
                this.component.sendMediaServerCommand(MusicServerEnum.Commands.AUDIO_STATUS_ALL, true);
            }
        }

        _handleConnectionClosed() {
            this.socketIsOpen = false;
            Debug.Media.AudioZoneExt && console.info(this.name, "_handleConnectionClosed"); // destroy the zoneHandlers

            if (this.zoneHandlers) {
                var playerid,
                    keys = Object.keys(this.zoneHandlers);

                for (var i = 0; i < keys.length; i++) {
                    playerid = keys[i];
                    this.zoneHandlers[playerid].destroy();
                }

                this.zoneHandlers = {};
            }

            this._rejectDeferreds();

            this._dispatchConnectionState(this.socketIsOpen, this.serverState);

            this._invalidateCaches();
        }

        // ------------------------------------------------------------------------

        /**
         * This method i called whenever a zoneHandler receives a new state that is to be dispatched
         * to the listeners registered to this very zone
         * @private
         */
        _zoneHandlerStateChanged(playerid) {
            if (!this.zoneHandlers || !this.zoneHandlers.hasOwnProperty(playerid)) {
                return;
            }

            this._dispatchConnectionStateForPlayer(playerid, this.socketIsOpen, this.serverState, this.prevFeatureCheckingReady);
        }

        // -------------------------------------------------------------------------
        // Helper Methods
        _registerGen(playerID, listenerID, target, onEventFn) {
            if (!onEventFn || onEventFn == null) {
                return;
            }

            if (!this.listenerObj[target][playerID]) {
                this.listenerObj[target][playerID] = {};
            }

            this.listenerObj[target][playerID][listenerID] = onEventFn;
        }

        _unregisterGen(playerID, listenerID, target) {
            var didUnregister = false;

            if (this.listenerObj.hasOwnProperty(target) && this.listenerObj[target].hasOwnProperty(playerID) && this.listenerObj[target][playerID].hasOwnProperty(listenerID)) {
                delete this.listenerObj[target][playerID][listenerID];
                didUnregister = true;
            }

            return didUnregister;
        }

        _handleFeatureCheckingReady() {
            Debug.Media.AudioZoneExt && console.log(this.name, "_handleFeatureCheckingReady");

            this._dispatchConnectionState(this.prevServerConnected, this.prevServerState, true);
        }

        _dispatchConnectionState(connected, state, featureCheckingReady) {
            if (connected === this.prevServerConnected && state === this.prevServerState && !!featureCheckingReady === this.prevFeatureCheckingReady) {
                return;
            }

            for (var playerid in this.listenerObj[this.constructor.ListenerType.CONNECTION]) {
                this._dispatchConnectionStateForPlayer(playerid, connected, state, featureCheckingReady);
            }

            this.prevServerConnected = connected;
            this.prevServerState = state;
            this.prevFeatureCheckingReady = !!featureCheckingReady;
        }

        _dispatchConnectionStateForPlayer(playerid, serverConnected, serverState, featureCheckingReady) {
            var serverListeners = this.listenerObj[this.constructor.ListenerType.CONNECTION][playerid];

            for (var listenerId in serverListeners) {
                if (serverListeners.hasOwnProperty(listenerId)) {
                    serverListeners[listenerId](this._createConnectionStateEvent(serverConnected, serverState, featureCheckingReady));
                }
            }
        }

        _createConnectionStateEvent(connected, state, featureCheckingReady) {
            return {
                reachable: connected,
                state: state,
                taskRecording: this.component.taskRecorderActive,
                featureCheckingReady: !!featureCheckingReady
            };
        }

        // -------------------------------------------------------------------------
        // Caching
        // Player Events
        _cacheAudioEvent(playerid, data) {
            /* no need to cache anything here*/
        }

        _getAudioEventCache(playerid, clone) {
            var result = null;

            if (this.zoneHandlers && this.zoneHandlers.hasOwnProperty(playerid)) {
                result = this.zoneHandlers[playerid].getAudioState();

                if (!!clone) {
                    result = cloneObjectDeep(result);
                }
            }

            return result;
        }

        _hasAudioEventCache(playerid) {
            return this.zoneHandlers && this.zoneHandlers.hasOwnProperty(playerid) && this.zoneHandlers[playerid].getAudioState(); //return this._hasInCacheGen(this.eventCache, playerid);
        }

        // Queue
        _cacheQueue(playerid, data) {
            Debug.Media.QueueOld && console.log(this.name, "_cacheQueue: player " + playerid); // no need to merge, always overwrite

            if (!this.queueCache) {
                this.queueCache = {};
            }

            this._cacheGen(this.queueCache, playerid, data);
        }

        _getQueueCache(playerid) {
            Debug.Media.QueueOld && console.log(this.name, "_getQueueCache: player " + playerid);
            return this._getFromCacheGen(this.queueCache, playerid);
        }

        _hasQueueCache(playerid) {
            Debug.Media.QueueOld && console.log(this.name, "_hasQueueCache: player " + playerid);
            return this._hasInCacheGen(this.queueCache, playerid);
        }

        _invalidateCacheQueue(playerid) {
            Debug.Media.QueueOld && console.log(this.name, "_invalidateCacheQueue: player " + playerid);
            return this._invalidateSpecificCacheGen(this.queueCache, playerid);
        }

        // General
        _invalidateCaches() {
            delete this.queueCache;
            delete this.eventCache;
        }

        _cacheGen(cache, identifier, data) {
            if (!cache) {
                throw "Cannot save data in an undefined cache!";
            }

            if (!identifier) {
                throw "Cannot save data without an identifier!";
            }

            cache[identifier] = data;
        }

        _getFromCacheGen(cache, identifier) {
            var result = null;

            if (this._hasInCacheGen(cache, identifier)) {
                result = cache[identifier];
            }

            return result;
        }

        _hasInCacheGen(cache, identifier) {
            return cache && cache.hasOwnProperty(identifier);
        }

        /**
         * Invalidates the cache of a specific identifier.
         * @param cache         what cache to invalidate
         * @param identifier    which identifier's cache needs to be invalidated
         * @private
         */
        _invalidateSpecificCacheGen(cache, identifier) {
            if (!cache) {
                throw "Cannot save data in an undefined cache!";
            }

            if (!identifier) {
                throw "Cannot save data without an identifier!";
            }

            delete cache[identifier];
        }

        _cacheAndInformAudioListeners(playerId, data) {
            //Workaround until BG-I15293 is fixed. invalid type-attributes are arriving.
            if (data.audiopath && data.audiopath.indexOf("bluetooth") >= 0) {
                delete data.type;
            }

            if (data.audiopath && data.audiopath.indexOf("airplay") >= 0) {
                delete data.type;
            }

            this._cacheAudioEvent(playerId, data);

            if (this.zoneHandlers && this.zoneHandlers.hasOwnProperty(playerId)) {
                this.zoneHandlers[playerId].cacheAudioState(data);
            }

            var audioListeners = this.listenerObj[this.constructor.ListenerType.AUDIO][playerId];

            for (var listenerId in audioListeners) {
                audioListeners[listenerId](data);
            }
        }

        // if a member of a sync group receives and audio event, update the state of all other group members
        _checkAndForwardToSyncGroup(playerId, newData, prevData) {
            Debug.Media.AudioZoneExt && console.log(this.name + ": _checkAndForwardToSyncGroup");

            var currPlayerData = this._getAudioEventCache(playerId); // it might be null if other zones aren't visualized.


            if (currPlayerData === null || currPlayerData !== null && !currPlayerData.hasOwnProperty(MusicServerEnum.Event.SYNCED_ZONES)) {
                return;
            }

            var syncedZones = currPlayerData[MusicServerEnum.Event.SYNCED_ZONES];

            if (syncedZones.length > 1) {
                // is the current zone in a group?
                if (!this._hasSyncRelevantChanges(cloneObjectDeep(newData), prevData)) {
                    Debug.Media.AudioZoneExt && console.log(this.name + ": the zone " + playerId + " received an " + "audioEvent, but it doesn't affect other zones!");
                } else {
                    Debug.Media.AudioZoneExt && console.log(this.name + ": the zone " + playerId + " received an " + "audioEvent, update other group members too");

                    for (var i = 0; i < syncedZones.length; i++) {
                        var currId = syncedZones[i][MusicServerEnum.Event.PLAYER_ID]; // no need to re-request the state for the current zone.

                        if (currId !== playerId) {
                            Debug.Media.AudioZoneExt && console.log("     request state for " + currId);
                            this.send(currId, {
                                cmd: MusicServerEnum.AudioCommands.STATUS
                            });
                        }
                    }
                }
            } else {
                Debug.Media.AudioZoneExt && console.log(this.name + ": the zone " + playerId + " is not in a zone group");
            }
        }

        /**
         * Makes sure that no unneccessary state-requests are sent for other zones of this group, unless they are really needed.
         * Volume changes for example don't need to trigger an update for other zones in the group.
         * @param newData       the newly received update for a single zone in the group
         * @param prevData      the states that the app currently has for this zone.
         * @returns {boolean}   true if the other zones need to update their states too.
         * @private
         */
        _hasSyncRelevantChanges(newData, prevData) {
            Debug.Media.AudioZoneExt && console.log(this.name + ": _hasSyncRelevantChanges");
            var deleteAttributes = [MusicServerEnum.Event.VOLUME, // delete volume, it's individually per zone
                MusicServerEnum.Event.MODE, // mode (play/pause) is also individually per zone
                MusicServerEnum.Event.ZONE_STATE, // state (on/off) is individual too
                MusicServerEnum.Event.TIME // time isn't individual, but it will change constantly, triggering unneccessary updates
            ];

            for (var j = 0; j < deleteAttributes.length; j++) {
                delete prevData[deleteAttributes[j]];
                delete newData[deleteAttributes[j]];
            }

            delete prevData[MusicServerEnum.Event.VOLUME];
            delete newData[MusicServerEnum.Event.VOLUME];
            var keys = Object.keys(newData);
            var key,
                didChange = false;

            for (var i = 0; i < keys.length; i++) {
                key = keys[i];
                var change = newData[key] !== prevData[key];
                change && Debug.Media.AudioZoneExt && console.log("   - " + key + " has changed: " + prevData[key] + " to " + newData[key]);
                didChange |= change;
            }

            return didChange;
        }

        // if a member of a sync group receives and audio event, update the state of all other group members
        _checkAndForwardQueueToSyncGroup(playerId) {
            var currPlayerData = this._getAudioEventCache(playerId);

            if (!currPlayerData || !currPlayerData.hasOwnProperty(MusicServerEnum.Event.SYNCED_ZONES)) {
                return;
            }

            Debug.Media.QueueOld && console.log(this.name, "_checkAndForwardQueueToSyncGroup: player " + playerId);
            var syncedZones = currPlayerData[MusicServerEnum.Event.SYNCED_ZONES];

            var dataToForward = this._getQueueCache(playerId);

            if (syncedZones.length > 1) {
                // is the current zone in a group?
                Debug.Media.AudioZoneExt && console.log(this.name + ": the zone " + playerId + " received an " + "audioEvent, update other group members too");
                Debug.Media.QueueOld && console.log(this.name + ": the zone " + playerId + " received an " + "audioEvent, update other group members too");

                for (var i = 0; i < syncedZones.length; i++) {
                    var currId = syncedZones[i][MusicServerEnum.Event.PLAYER_ID]; // no need to re-request the state for the current zone.

                    if (currId !== playerId) {
                        // emit getqueueresult event
                        Debug.Media.AudioZoneExt && console.log("Forward queue to " + currId);
                        var channelEventData = {
                            playerid: currId,
                            queue: dataToForward,
                            command: "audio/" + currId + MusicServerEnum.AudioCommands.QUEUE.GET
                        };
                        this.channel.emit(this.component.ECEvent.ResultReceived, channelEventData);
                    }
                }
            }
        }

        _hasSyncedQueueInCache(playerId) {
            var currPlayerData = this._getAudioEventCache(playerId);

            if (!currPlayerData || !currPlayerData.hasOwnProperty(MusicServerEnum.Event.SYNCED_ZONES)) {
                return false;
            }

            Debug.Media.QueueOld && console.log(this.name, "_hasSyncedQueueInCache: player " + playerId);
            var hasInCache = false;
            var syncedZones = currPlayerData[MusicServerEnum.Event.SYNCED_ZONES];

            if (syncedZones.length > 1 && syncedZones[0][MusicServerEnum.Event.PLAYER_ID] === playerId) {
                // this zone is the master of a zone group, forward the events.
                for (var i = 1; i < syncedZones.length; i++) {
                    hasInCache = hasInCache || this._hasQueueCache(syncedZones[i][MusicServerEnum.Event.PLAYER_ID]);
                }
            }

            return hasInCache;
        }

        _isCommandWithConfirmation(command) {
            var hasConfirmation;
            hasConfirmation = command.indexOf(MusicServerEnum.AudioCommands.QUEUE.ADD) > -1 || command.indexOf(MusicServerEnum.AudioCommands.QUEUE.INSERT) > -1 || command.indexOf(MusicServerEnum.AudioCommands.IDENTIFY_SOURCE) > -1 || command.indexOf(MusicServerEnum.AudioCommands.MASTER_VOLUME.REQUEST) > -1;
            return hasConfirmation;
        }

        _rejectDeferreds() {
            if (!this.resultDeferreds) {
                return;
            }

            var keys = Object.keys(this.resultDeferreds);
            var i;

            for (i = 0; i < keys.length; i++) {
                this.resultDeferreds[keys[i]].reject();
            }

            this.resultDeferreds = {};
        }

    }

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    if (!("extensions" in Components.Audioserver)) {
        Components.Audioserver.extensions = {};
    }

    Components.Audioserver.extensions.AudioZone = AudioZone;
    return Components;
}(window.Components || {});
