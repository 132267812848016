'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var REBOOT_POPUP_ID = 1337;

        class Popup extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.popupStack = [];
                this.registerExtensionEv(this.component.ECEvent.ShowPopup, this._handleShowPopup.bind(this));
                this.registerExtensionEv(this.component.ECEvent.EventReceived, this._handleEventReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.InitialAudioScreenLoaded, this._handleInitialAudioScreenLoaded.bind(this));
            }

            destroy() {
                this._dismissAllPopups();

                super.destroy(...arguments);
            }

            showContent(content) {
                this.popupStack.push(content);

                this._showNextContent();
            }

            // ---------------------------------------------------------------
            //            Handling events
            // ---------------------------------------------------------------
            _handleShowPopup(evId, evData) {
                Debug.Media.PopupExt && console.log(this.name, evData.title + ": " + evData.message);
                this.popupStack.push(evData);

                this._showNextContent();
            }

            _handleEventReceived(evId, event) {
                var key = Object.keys(event)[0];
                var eventData = event[key];

                if (key === MusicServerEnum.EventIdentifier.POPUP) {
                    Debug.Media.PopupExt && console.log("PopupExt", "Popup event received: " + JSON.stringify(event));
                    var i;

                    for (i = 0; i < eventData.length; i++) {
                        this._respondToPopupEvent(eventData[i]);
                    }

                    this._showNextContent();
                }
            }

            _handleInitialAudioScreenLoaded() {
                // when we navigate from outside into an audioZone, potential current context needs to be adopted
                // e.g. sticky notifications need to become sticky popups
                if (this.currentContent && this.notification) {
                    this.popupStack.push(this.currentContent);
                    this.currentContent = null;

                    this._removeNotification();
                }

                this._showNextContent();
            }

            // ---------------------------------------------------------------
            _showNextContent() {
                Debug.Media.PopupExt && console.log("PopupExt", "_showNextContent");

                if (!this.currentPromise && !this.currentContent && this.popupStack.length > 0) {
                    this.currentContent = this.popupStack.splice(0, 1)[0];

                    if (this.component.getAudioViewsVisible()) {
                        this._presentAsPopup(this.currentContent);
                    } else {
                        this._presentAsNotification(this.currentContent);
                    }
                }

                Debug.Media.PopupExt && console.log("        ", "_showNextContent finished");
            }

            _presentAsPopup(content) {
                Debug.Media.PopupExt && console.log(this.name, "present as popup :" + JSON.stringify(content));
                this.currentPromise = NavigationComp.showPopup(content);
                var sticky = content.hasOwnProperty('sticky') && content.sticky;
                this.currentPromise.then(function (btnIdx) {
                    // nothing to do with the btnIdx for now
                    if (this.currentContent.hasOwnProperty('deferred')) {
                        this.currentContent.deferred.resolve(btnIdx);
                    }

                    if (sticky && this.component.AudioViewController !== null) {
                        // navigate out of the music-server views && show it as a notification
                        this.popupStack.push(this.currentContent); // will re-show the sticky content as notif, on _showNextContent below

                        this.component.AudioViewController.navigateBack(); // now on the controlContent, call navigate navBack once more, so we've left the musicServer UI fully

                        NavigationComp.navigateBack(target); // _showNextContent will take care of the rest (showing it as a notification)
                        // since the contentOld's destroy, who usually resets the views visible argument
                        // will be called too late (animations), reset it right away.

                        this.component.setAudioViewsVisible(false);
                    }

                    this.currentContent = null;
                    this.currentPromise = null;

                    this._showNextContent();
                }.bind(this), function () {
                    // respond to cancel button
                    if (this.currentContent.hasOwnProperty('deferred')) {
                        this.currentContent.deferred.reject();
                    }

                    this.currentContent = null;
                    this.currentPromise = null;

                    this._showNextContent();
                }.bind(this));
            }

            _respondToPopupEvent(event) {
                Debug.Media.PopupExt && console.log("PopupExt", "_respondToPopupEvent");

                if (!this._doesHandlePopupEvent(event)) {
                    return;
                }

                if (event.hasOwnProperty('stickyid') && event.hasOwnProperty('sticky')) {
                    // handle sticky event!
                    if (event.sticky) {
                        this._dismissAllPopups();

                        this.popupStack.push(this._createPopupFromEvent(event));
                    } else if (this.currentContent) {
                        if (this.currentContent.hasOwnProperty('stickyid') && this.currentContent.stickyid === event.stickyid && (this.currentPromise !== null || this.notification) // notifications don't have a promise, so sticky notif's wouldn't be dismissed
                        ) {
                            // dismiss the current stickyid-popup
                            if (this.currentContent.hasOwnProperty('deferred')) {
                                this.currentContent.deferred.resolve();
                            }

                            if (this.notification) {
                                this._removeNotification();
                            } else {
                                NavigationComp.removePopup(this.currentPromise);
                            }
                        } else {
                            var msg = "Could not dismiss sticky popup!";
                            console.error(msg);
                            throw msg;
                        }
                    } else {
                        // look for the sticky popup in the stack and remove it.
                        var i,
                            iContent,
                            removed = false;

                        for (i = 0; i < this.popupStack.length; i++) {
                            iContent = this.popupStack[i];

                            if (iContent.hasOwnProperty('stickyid') && iContent.stickyid === event.stickyid) {
                                // found the one were looking for, remove it
                                this.popupStack.splice(i, 1);
                                removed = true;
                                break;
                            }
                        }

                        if (!removed) {
                            console.error("Received a request to dismiss a sticky popup, that wasn't found in the extension!");
                            console.warn(JSON.stringify(event));
                        }
                    }
                } else {
                    this.popupStack.push(this._createPopupFromEvent(event));
                }
            }

            _doesHandlePopupEvent(event) {
                if (this.component.Feature.FULL_DIALOG_SUPPORT) {
                    return false; // all communication with the user is being handled via Dialogs instead of Popups
                }

                var result = true;

                if (!event.hasOwnProperty('messageid')) {
                    Debug.Media.PopupExt && console.log("       no message id, return");
                    return result;
                }

                switch (event.messageid) {
                    case MusicServerEnum.PopupMessageId.RESCANNING:
                        if (this.component.Feature.GETMEDIAFOLDER_ERROR) {
                            result = false; // don't handle this popup here. the getmediafolder_error will take care
                        }

                        break;

                    case MusicServerEnum.PopupMessageId.INITSPOTIFY:
                    case MusicServerEnum.PopupMessageId.DELETESPOTIFY:
                    case MusicServerEnum.PopupMessageId.INITGOOGLE:
                    case MusicServerEnum.PopupMessageId.DELETEGOOGLE:
                        result = false; // new login handling does not depend on pop

                        break;

                    default:
                        break;
                }

                return result;
            }

            _createPopupFromEvent(event) {
                Debug.Media.PopupExt && console.log("PopupExt", "_createPopupFromEvent");

                switch (event.level) {
                    case 1:
                        event.icon = Icon.INFO;
                        event.color = window.Styles.colors.green;
                        break;

                    case 2:
                        event.icon = Icon.WARNING;
                        event.color = window.Styles.colors.green; // no more yellow popups! bad contrast

                        break;

                    case 3:
                        event.icon = Icon.ERROR;
                        event.color = window.Styles.colors.red;
                        break;

                    default:
                        event.icon = Icon.INFO;
                        event.color = Color.STATE_INACTIVE;
                        console.error("Popup-Event received with unknown level: " + event.level);
                        break;
                } // the popups received by the music-server are all in EN. But they have an id, so we can translate them.


                this._translateViaMessageId(event);

                if (!event.hasOwnProperty('stickyid')) {
                    event.buttonOk = _("okay");
                } else {
                    // you cannot dismiss a sticky event, you can only close all views.
                    event.buttonOk = _("media.minimize");
                }

                return event;
            }

            _dismissAllPopups() {
                Debug.Media.PopupExt && console.log("PopupExt", "_dismissAllPopups");

                if (this.currentPromise || this.notification) {
                    // empty the popup-stack
                    if (this.popupStack.length > 0) {
                        do {
                            var content = this.popupStack.pop();

                            if (content.hasOwnProperty('deferred')) {
                                content.deferred.reject();
                            }

                            if (content.hasOwnProperty('sticky')) {
                                var msg = "Cannot simply dismiss a sticky popup!";
                                console.error(msg);
                                throw msg;
                            }
                        } while (this.popupStack.length > 0);
                    } // remove the current promise!


                    if (this.currentContent.hasOwnProperty('deferred')) {
                        this.currentContent.deferred.reject();
                    }

                    if (this.currentContent.hasOwnProperty('sticky')) {
                        var exc = "Cannot simply dismiss the current sticky popup!";
                        console.error(exc);
                        throw exc;
                    }

                    if (this.notification) {
                        this._removeNotification();
                    } else {
                        NavigationComp.removePopup(this.currentPromise);
                    }
                }
            }

            // ----------------------------------------------------------
            //       Notifications
            // ----------------------------------------------------------
            _presentAsNotification(content) {
                var notifType;
                var icon = "resources/Images/Controls/AudioZone/icon-services.svg";
                var closeable = !(content.hasOwnProperty('sticky') && content.sticky);

                switch (content.level) {
                    case 1:
                        notifType = NotificationType.SUCCESS;
                        break;

                    case 2:
                        notifType = NotificationType.WARNING;
                        break;

                    case 3:
                        notifType = NotificationType.ERROR;
                        break;

                    default:
                        notifType = NotificationType.INFO;
                        break;
                }

                if (!this.notification) {
                    // remove html, for now only the br is used by the mediaServer
                    var sanitizedMessage = content.message.replace("<br>", "   ");
                    this.notification = GUI.Notification.createGeneralNotification({
                        title: content.title,
                        //AudioserverComp.getServerName(),
                        subtitle: sanitizedMessage,
                        iconSrc: icon,
                        closeable: closeable,
                        clickable: true
                    }, notifType);
                    this.notification.on(GUI.Notification.CLICK_EVENT, function () {
                        this._presentAsPopup(this.currentContent);

                        this.notification.remove();
                        this.notification = null;
                    }.bind(this));
                    this.notification.on("destroy", function () {
                        if (this.currentPromise) {
                            // don't handle destroy of notification, popup took over after click handler
                            return;
                        }

                        if (this.currentContent && this.currentContent.hasOwnProperty('deferred')) {
                            this.currentContent.deferred.reject();
                        }

                        this.currentContent = null;
                        this.notification = null;

                        this._showNextContent();
                    }.bind(this));
                }
            }

            _removeNotification() {
                if (this.notification) {
                    this.notification.remove();
                    this.notification.destroy();
                    this.notification = null;
                }
            }

            _translateViaMessageId(event) {
                Debug.Media.PopupExt && console.log("PopupExt", "_translateViaMessageId: " + JSON.stringify(event));

                if (!event.hasOwnProperty('messageid')) {
                    Debug.Media.PopupExt && console.log("       no message id, return");
                    return;
                }

                var title = null;
                var message = null;

                switch (event.messageid) {
                    case MusicServerEnum.PopupMessageId.UPNPSEARCH:
                        // the config is searching for upnp devices
                        title = _("audio-server.popup.title");
                        message = _("media.popup.upnp-search.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.INITSPOTIFY:
                        // a new spotify account is being added
                        title = _("audio-server.popup.title");
                        message = _("media.popup.spotify.init.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.INITGOOGLE:
                        // a google account is being added
                        title = _("audio-server.popup.title");
                        message = _("media.popup.google.init.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.DELETESPOTIFY:
                        // a spotify account is being removed
                        title = _("audio-server.popup.title");
                        message = _("media.popup.spotify.delete.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.DELETEGOOGLE:
                        // a google account is being removed
                        title = _("audio-server.popup.title");
                        message = _("media.popup.google.delete.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.SPOTIFYFAVORITE:
                        // tracks from spotify where added as favorites
                        title = _("audio-server.popup.title");
                        message = _("media.popup.spotify.add-favorite.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.RECONFIGURED:
                        // Loxone config has reconfigured, the music server is restarting
                        title = _("audio-server.popup.title");
                        message = _("audio-server.popup.reconfigure.message");
                        message += "<br>" + _("please-wait");
                        event.stickyid = REBOOT_POPUP_ID;
                        event.sticky = true;
                        setTimeout(this._emitReloadMusicAppEvent.bind(this), 1000 * 20);
                        break;

                    case MusicServerEnum.PopupMessageId.BIGFILELIST:
                        // shown while loads of files are being processed
                        title = _("audio-server.popup.title");
                        message = _("audio-server.popup.bigfilelist.message");
                        message += "<br>" + _("please-wait");
                        break;

                    case MusicServerEnum.PopupMessageId.ADDLIMIT:
                        // at most 500 items can be put in a queue
                        title = _("audio-server.popup.title");
                        message = _("media.popup.addlimit.message");
                        break;

                    case MusicServerEnum.PopupMessageId.NOINTERNET:
                        // the music server has no internet connection
                        title = _("media.popup.nointernet.title");
                        message = _("media.popup.nointernet.message");
                        break;

                    case MusicServerEnum.PopupMessageId.BADCONN:
                        // a radiostation is not reachable;
                        title = _("media.popup.radio.badconn.title");
                        message = _("media.popup.radio.badconn.message");
                        break;

                    case MusicServerEnum.PopupMessageId.TTSERROR:
                        if (event.hasOwnProperty("arguments") && event.arguments.hasOwnProperty("ttstext")) {
                            // while scanning the library a corrupt file was discovered that needs to be removed
                            title = _("media.popup.ttserror.title");
                            message = _("media.popup.ttserror.message", {
                                ttstext: event.arguments.ttstext
                            });
                        }

                        break;

                    case MusicServerEnum.PopupMessageId.EVENTFILERROR:
                        if (event.hasOwnProperty("arguments") && event.arguments.hasOwnProperty("badfile")) {
                            // while scanning the library a corrupt file was discovered that needs to be removed
                            title = _("media.popup.eventfileerror.title");
                            message = _("media.popup.eventfileerror.message", {
                                file: event.arguments.badfile
                            });
                        }

                        break;

                    case MusicServerEnum.PopupMessageId.RESCANNING:
                        title = _("media.popup.library.rescanning.title");
                        message = _("media.popup.library.rescanning.message");

                        this._dispatchLibraryError(event);

                        break;

                    case MusicServerEnum.PopupMessageId.BADFILE:
                        if (event.hasOwnProperty("arguments") && event.arguments.hasOwnProperty("badfile")) {
                            // while scanning the library a corrupt file was discovered that needs to be removed
                            title = _("media.popup.library.badfile.title");
                            message = _("media.popup.library.badfile.message", {
                                badfile: event.arguments.badfile
                            });
                        }

                        this._dispatchLibraryError(event);

                        break;

                    case MusicServerEnum.PopupMessageId.SLOWINTERNET:
                        title = _("media.popup.slowinternet.title");
                        message = _("media.popup.slowinternet.message");
                        break;

                    case MusicServerEnum.PopupMessageId.SPOTIFYINUSE:
                        title = _("media.popup.spotify.title");
                        message = _("media.popup.spotify.alreadyinuse.message");
                        break;

                    default:
                        break;
                } // only update the texts if the id was recognized


                if (title !== null && message !== null) {
                    event.title = title;
                    event.message = message;
                }

                Debug.Media.PopupExt && console.log("       translated: " + JSON.stringify(event));
            }

            /**
             * Emits a reloadMusicApp-Event on the extensionChannel just like if it would have been received
             * from the Music Server itself. The centralCommunciationNode will take care of it.
             * @private
             */
            _emitReloadMusicAppEvent() {
                Debug.Media.PopupExt && console.log("PopupExt", "_emitReloadMusicAppEvent"); // first of all, dismiss the sticky popup.

                var event = {};
                event.stickyid = REBOOT_POPUP_ID;
                event.sticky = false;

                this._respondToPopupEvent(event);

                this._showNextContent(); // dispatch the reloadMusicAppEvent


                var ev = {};
                ev[MusicServerEnum.EventIdentifier.RELOAD_APP] = {};
                this.component.emit(this.component.ECEvent.EventReceived, ev);
            }

            /**
             * Called when a popupEvent is received that indicates a sth is wrong with the library. The loader needs
             * to be aware of this, so we dispatch it on the extension-channel.
             * @param popupEvent the popupEvent the app has received, we might need some info from it.
             * @private
             */
            _dispatchLibraryError(popupEvent) {
                // for now, it is simply dispatched.
                this.component.emit(this.component.ECEvent.LibraryErrorReceived, popupEvent);
            }

        }

        Components.Audioserver.extensions.PopupExt = Popup;
    }
    return Components;
}(window.Components || {});
