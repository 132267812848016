'use strict';

window.Components = function (Components) {
    class SpotifyPlaylistEditExtension extends Components.Audioserver.extensions.PlaylistEditExtension {
        constructor() {
            super(...arguments);
        }

        /**
         * Returns the remove argument for this item. Needs to be subclassable since e.g. spotify requires the
         * items ID instead of the index.
         * @param idx       what position was the item to be removed
         * @param item      the item object that is to be removed
         * @returns {string}    the argument needed for the update command that removes an item.
         */
        getRemoveItemArgument(idx, item) {
            return item[MusicServerEnum.Event.ID];
        }

        // -------------------------------------------------------
        //            Methods used by subclasses
        // -------------------------------------------------------

        /**
         * Returns the MediaContentType of these containers. e.g. MusicServerEnum.MediaContentType.SERVICE
         * @returns {string}
         */
        getContentType() {
            return MusicServerEnum.MediaContentType.SERVICE;
        }

        getRootContainerId() {
            return MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS;
        }

        /**
         * Used to add additional infos to created containers.
         * @param newCntr   the new container that has just been created and will be returned on createContainer
         * @param response  the response from the AS on the createContainer command.
         */
        addInfosToNewContainerItem(newCntr, response) {
            // the response from the audioserver (older versions) is missing the owner and ownerId property, but
            // returning the ownerId as attribute "user".
            if (!newCntr.hasOwnProperty("ownerId") && newCntr.hasOwnProperty("user")) {
                console.warn(this.name, "addInfosToNewContainerItem: response missing ownerId, but has user set - fixing! resp: " + JSON.stringify(response));
                newCntr.ownerId = newCntr.user;
            }
        }

    }

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    if (!("extensions" in Components.Audioserver)) {
        Components.Audioserver.extensions = {};
    }

    Components.Audioserver.extensions.SpotifyPlaylistEditExtension = SpotifyPlaylistEditExtension;
    return Components;
}(window.Components || {});
