'use strict';

window.Components = function (Components) {
    class PlaylistEditExtension extends Components.Audioserver.extensions.MediaEditBase {
        constructor() {
            super(...arguments);
        }

        // -------------------------------------------------------
        //            Methods used by subclasses
        // -------------------------------------------------------

        /**
         * Returns the MediaContentType of these containers. e.g. MusicServerEnum.MediaContentType.PLAYLISTS
         * @returns {string}
         */
        getContentType() {
            return MusicServerEnum.MediaContentType.PLAYLISTS;
        }

        /**
         * Returns the update command used for containers (e.g. audio/cfg/playlist/update)
         * @returns {string}
         */
        getUpdateCommand() {
            return MusicServerEnum.Commands.PLAYLIST.UPDATE;
        }

        /**
         * Returns the command used to create a new container (e.g. audio/cfg/playlist/create)
         * @returns {string}
         */
        getCreateContainerCommand() {
            return MusicServerEnum.Commands.PLAYLIST.CREATE;
        }

        /**
         * Returns the command used to delete a new container (e.g. audio/cfg/playlist/delete)
         * @returns {string}
         */
        getDeleteContainerCommand() {
            return MusicServerEnum.Commands.PLAYLIST.DELETE;
        }

        /**
         * Returns the command used to rename an existing container (e.g. audio/cfg/playlist/rename)
         * @returns {string}
         */
        getRenameContainerCommand() {
            return MusicServerEnum.Commands.PLAYLIST.RENAME;
        }

        /**
         * Returns the name of the attribute identifying the container. e.g. "playlistid"
         * @returns {string}
         */
        getContainerIdAttribute() {
            return MusicServerEnum.Attr.Playlist.ID;
        }

        /**
         * Returns the name of id of the event that is sent out when a container changes e.g. "playlistchanged_event"
         * @returns {string}
         */
        getChangedEventId() {
            return MusicServerEnum.EventIdentifier.PLAYLIST_CHANGED;
        }

    }

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    if (!("extensions" in Components.Audioserver)) {
        Components.Audioserver.extensions = {};
    }

    Components.Audioserver.extensions.PlaylistEditExtension = PlaylistEditExtension;
    return Components;
}(window.Components || {});
