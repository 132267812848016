'use strict';
/**
 * The MediaMessageParser registers on messageReceived EC_Events, it parses them and dispatches Results or Events
 */

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var RESPONSE_TIMEOUT = 15000; // Increased to 15 seconds, spotify may exceed our previous limitation of 10 seconds

        class MediaMessageParser extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.name += "@" + component.getServerName();
                this.apiMessageReceived = false;
                this.registerExtensionEv(this.component.ECEvent.MessageReceived, function (evType, message) {
                    this._handleMessage(message);
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ConnEstablished, function () {
                    this.apiMessageReceived = false;
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ConnClosed, function () {
                    this.apiMessageReceived = false;
                }.bind(this)); // Some commands might be confirmed by a message instead of a response - interfere here and avoid dispatching
                // an event_received when a command was sent that triggered this very event. Instead dispatch a response-received.

                this.registerExtensionEv(this.component.ECEvent.MessageSent, function (evType, cmdObj) {
                    var command = cmdObj.cmd;

                    this._messageSent(command);
                }.bind(this)); //  array containing all outgoing commands.

                this.sentMessages = []; // some commands might never respond. they need to be removed from sentMessages.

                this.msgTimeouts = [];
            }

            /**
             * Will put the cmd that will be sent into the sentMessages queue. It will be removed and processed accordingly
             * as soon as an event or response is received for this cmd.
             * @param msg
             * @private
             */
            _messageSent(msg) {
                Debug.Media.Parser && console.log(this.name, "_messageSent '" + msg + "'");
                this.sentMessages.push(msg);
                this.msgTimeouts.push(setTimeout(function () {
                    console.error("Command '" + msg + "' did not respond within " + RESPONSE_TIMEOUT / 1000 + " seconds");

                    this._removeMsg(msg);

                    this.channel.emit(this.component.ECEvent.ResultErrorReceived, {
                        reason: "timeout",
                        command: msg
                    });
                }.bind(this), RESPONSE_TIMEOUT));
            }

            /**
             * Will check the sentMessages object for this cmd. If found, it will stop the cmdTimeout.
             * @param msg           the msg that should be removed
             * @returns {boolean}   whether or not the cmd was found in sentMessages.
             * @private
             */
            _removeMsg(msg) {
                Debug.Media.Parser && console.log(this.name, "_removeMsg '" + msg + "'");
                var idx = this.sentMessages.indexOf(msg),
                    timeout,
                    found = idx >= 0;

                if (found) {
                    this.sentMessages.splice(idx, 1);
                    timeout = this.msgTimeouts.splice(idx, 1)[0];
                    clearTimeout(timeout);
                }

                return found;
            }

            /**
             * Checks if the event received is a response to an command.
             * @param msgObj
             * @returns {boolean}
             * @private
             */
            _isResponseEvent(msgObj) {
                var msg = null,
                    isResponse = false;

                if (msgObj.hasOwnProperty(MusicServerEnum.Comm.CMD)) {
                    msg = msgObj[MusicServerEnum.Comm.CMD];
                    isResponse = this.sentMessages.indexOf(msg) >= 0;
                }

                Debug.Media.Parser && console.log(this.name, "_isResponseEvent '" + JSON.stringify(msgObj) + "' - " + isResponse);
                return isResponse;
            }

            // state callbacks
            _handleMessage(message) {
                Debug.Media.Parser && console.log(this.name, "_handleMessage '" + message + "'");

                if (message.length < 2) {
                    return; // nothing to do
                }

                if (!this.apiMessageReceived && message.indexOf(MusicServerEnum.Comm.ROOT_MSG) === 0) {
                    // initialMessage
                    this._handleInitialMessage(message);

                    return;
                }

                try {
                    var msgObj = JSON.parse(message),
                        identifier = Object.keys(msgObj).find(function (key) {
                            return !!Object.values(MusicServerEnum.Comm.IDENTIFIER).find(function (idSuffix) {
                                return key.hasSuffix(idSuffix);
                            });
                        }),
                        evData = msgObj[identifier],
                        resultId;

                    if (identifier.indexOf(MusicServerEnum.Comm.EVENT_ID) > -1) {
                        if (this._isResponseEvent(msgObj)) {
                            resultId = identifier.split(MusicServerEnum.Comm.EVENT_ID)[0];

                            this._processResult(evData, msgObj, resultId);
                        } else {
                            this._dispatchEvent(identifier, evData);
                        }
                    } else if (identifier.indexOf(MusicServerEnum.Comm.RESULT_ID) > -1) {
                        resultId = identifier.split(MusicServerEnum.Comm.RESULT_ID)[0];

                        this._processResult(evData, msgObj, resultId);
                    } else if (identifier.indexOf(MusicServerEnum.Comm.ERROR_ID) > -1 || identifier.indexOf(MusicServerEnum.Comm.IDENTIFIER.ERROR_PLAIN) > -1) {
                        var errorInfo = {
                            reason: evData
                        };

                        if (msgObj.hasOwnProperty(MusicServerEnum.Comm.CMD)) {
                            errorInfo.command = msgObj.command; // errors are also responses, remove!

                            this._removeMsg(msgObj.command);
                        }

                        this.channel.emit(this.component.ECEvent.ResultErrorReceived, errorInfo);
                    }
                } catch (exc) {
                    if (this.apiMessageReceived && message.indexOf(MusicServerEnum.Comm.ROOT_MSG) === 0) {
                        console.error(this.name, "_handleMessage: duplicate API message received, ignored.");
                    } else {
                        console.error(exc.stack);

                        this._handleParseError(message, exc.message);
                    }
                }
            }

            _processResult(evData, msgObj, resultId) {
                Debug.Media.Parser && console.log(this.name, "_processResult '" + resultId + "' - " + JSON.stringify(evData));
                var messageResult = {
                    oldCommand: resultId,
                    data: evData // dispatch the whole result array!

                };

                if (msgObj.hasOwnProperty(MusicServerEnum.Comm.CMD)) {
                    messageResult.command = msgObj.command; // errors are also responses, remove!

                    this._removeMsg(msgObj.command);
                }

                if (resultId === MusicServerEnum.GlobalSearch) {
                    messageResult = msgObj;
                    messageResult.oldCommand = resultId;
                }

                this.channel.emit(this.component.ECEvent.ResultReceived, messageResult);
            }

            _dispatchEvent(eventIdentifier, payloadArr) {
                // for each entry in the eventData-Array, dispatch an event
                var obj = {};
                obj[eventIdentifier] = payloadArr;
                this.channel.emit(this.component.ECEvent.EventReceived, obj);
            }

            _handleParseError(message, errorMsg) {
                console.error("Message could not be parsed! '" + message + "'", errorMsg); // check if the errornous result can be linked to a command

                var cmdId = '"command":"';
                var cmdIdPos = message.indexOf(cmdId);

                if (cmdIdPos > -1) {
                    // handle a command result error;
                    var cmdStart = cmdIdPos + cmdId.length;
                    var cmdPart = message.slice(cmdStart, message.length);
                    var cmdEnd = cmdPart.indexOf('"');
                    cmdPart = cmdPart.slice(0, cmdEnd);
                    Debug.Media.Parser && console.log("MediaMessageParser: a command failed! '" + cmdPart + "'");
                    this.channel.emit(this.component.ECEvent.ResultErrorReceived, {
                        command: cmdPart,
                        reason: errorMsg
                    });
                }
            }

            _handleInitialMessage(msg) {
                Debug.Media.Parser && console.log("MediaMessageParser: Initial-Message '" + msg + "'");

                try {
                    this.channel.emit(this.component.ECEvent.ServerInfoReceived, {
                        apiVersion: this._parseApiVersion(msg),
                        firmwareVersion: this._parseFirmwareVersion(msg),
                        sessionToken: this._parseSessionToken(msg)
                    });
                    this.apiMessageReceived = true;
                } catch (exc) {
                    console.error("MediaMessageParser: Issue Parsing the Initial-Message! " + JSON.stringify(exc));
                    console.error(exc.stack);
                }
            }

            _parseApiVersion(msg) {
                // aquire api
                var apiIdentifier = "~API:";
                var apiStartIndex = msg.indexOf(apiIdentifier);

                if (apiStartIndex < 0) {
                    throw new Error(this.name + ": _parseApiVersion - Initial message not an API-Message! Msg: '" + msg + "'");
                }

                apiStartIndex = apiStartIndex + apiIdentifier.length;
                var apiEndIndex = msg.indexOf("~", apiStartIndex);
                var apiVersionStr = msg.substring(apiStartIndex, apiEndIndex);
                Debug.Media.Parser && console.log("MediaMessageParser: API: '" + apiVersionStr + "'");
                var apiVersionParts = apiVersionStr.split(".");

                for (var i = 0; i < apiVersionParts.length; i++) {
                    apiVersionParts[i] = parseInt(apiVersionParts[i]);
                }

                return apiVersionParts;
            }

            _parseFirmwareVersion(msg) {
                // 'LWSS V 0.0.4.22b | ~API:1.0~'
                var versionIdentifier = MusicServerEnum.Comm.ROOT_MSG;
                var versionStartIndex = msg.indexOf(versionIdentifier);

                if (versionStartIndex < 0) {
                    throw new Error(this.name + ": _parseFirmwareVersion - Initial message not an API-Message! Msg: '" + msg + "'");
                }

                versionStartIndex = versionStartIndex + versionIdentifier.length;
                var versionEndIndex = msg.indexOf(" | ", versionStartIndex);
                var versionStr = msg.substring(versionStartIndex, versionEndIndex);
                Debug.Media.Parser && console.log("MediaMessageParser: Version: '" + versionStr + "'");
                return versionStr;
            }

            _parseSessionToken(msg) {
                return msg.replace(/^.*Session-Token: /g, "");
            }
        }

        if (!("Audioserver" in Components)) {
            Components.Audioserver = {};
        }
        if (!("extensions" in Components.Audioserver)) {
            Components.Audioserver.extensions = {};
        }
        Components.Audioserver.extensions.MediaMessageParser = MediaMessageParser;
    }
    return Components;
}(window.Components || {});
