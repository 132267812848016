'use strict';

window.Components = function (Components) {
    class SoundsuitLoader extends Components.Audioserver.extensions.ServiceLoader {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Verifies that the resultObject received is of any relevance for the loader right now. There may be
         * results of earlier requests that have been cancelled. There may even be results incoming that are for
         * the same ID as the current request, but the start indices may not match.
         * @param data
         * @returns {boolean|*}
         * @private
         */
        _getIsCurrentRequest(data) {
            return super._getIsCurrentRequest(...arguments);
        }
    }

    Components.Audioserver.extensions.SoundsuitLoader = SoundsuitLoader;
    return Components;
}(window.Components || {});
