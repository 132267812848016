'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        // Name of internal event used to dispatch to clients that registered for it.
        var SERVICE_CHANGED = "ServiceChangedEvent";
        var RADIOS_CHANGED = "RadiosChangedEvent";
        /**
         * Attributes of a reloadService-Event
         * @type {{ADD: string, CHANGE: string, REMOVE: string}}
         */

        var ReloadServiceEvent = {
            ACTION: 'action',
            USER: 'user',
            SERVICE: 'cmd'
        };

        class ServiceHandlerExt extends Components.Extension {
            //region Getter
            get services() {
                return this._services || [];
            } //endregion Getter


            //region Setter
            set services(value) {
                this._services = value;
            } //endregion Setter


            constructor(component, extensionChannel) {
                super(...arguments);
                this.radios = null;
                this.availableServices = null;
                this.socketIsOpen = false;
                this.registerExtensionEv(this.component.ECEvent.ConnEstablished, this._handleConnectionEstablished.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ConnClosed, this._handleConnectionClosed.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                    this._handleResult(result);
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultErrorReceived, function (evId, error) {
                    this._handleResultError(error);
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.EventReceived, function (ev, args) {
                    var key = Object.keys(args)[0];
                    if (key === MusicServerEnum.EventIdentifier.SERVICE_CFG_ERROR) {
                        this._handleServiceCfgError(args[key]);
                    } else if (key === MusicServerEnum.EventIdentifier.RADIOS_CHANGED) {
                        //{ "customurl_changed_event": { "action": "add", "id": "custom:station:p8GSpzLF"} }
                        // by nulling the radios, they will have to be reloaded.
                        this.radios = null;
                        this.radiosDeferred = null;
                        setTimeout(() => {
                            this.emit(RADIOS_CHANGED);
                        }, 500);
                    } else if (key === MusicServerEnum.EventIdentifier.SERVICES_CHANGED) {
                        // received whenever a user is configured (set) to a specific zone.
                        this._handleServiceChanged(null, args[key]);
                    }
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ServiceChanged, this._handleServiceChanged.bind(this));
            }

            /**
             * Once registered, the changedFn is called once new services are loaded.
             * @param changedFn     the fn to call when changed, it has the following arguments
             *                              id          "ServiceChangedEvent"
             *                              services    up to date array of service-objects
             *                              [changed]   what service has changed - if known
             *                              [user]      what user in specific was changed - if known
             *                              [action]    was he added removed or udpated - if known
             * @returns {*} an unregister method. Call it and you no longer hear of service changes.
             */
            registerForServiceChanges(changedFn) {
                return this.on(SERVICE_CHANGED, changedFn);
            }

            registerForRadioChanges(changedFn) {
                return this.on(RADIOS_CHANGED, changedFn);
            }

            assignSpotifyAccountToZone(userId, playerId) {
                // audio/cfg/servicecfg/set/spotify/<user>/asdefault/player/<PlayerID1>[,<PlayerID2>]
                let cmd = Commands.format(MusicServerEnum.Commands.SERVICE_ASSIGN_ZONE_SPOTIFY, userId, playerId);
                Debug.Media.ServiceHandler && console.log(this.name, "assignSpotifyAccountToZone: userId=" + userId + ", zoneId=" + playerId + " => cmd=" + cmd);
                return this._sendCmd(cmd).then((resp) => {
                    Debug.Media.ServiceHandler && console.log(this.name, "assignSpotifyAccountToZone: " + cmd + " --> success!", resp);
                }, (err) => {
                    Debug.Media.ServiceHandler && console.error(this.name, "assignSpotifyAccountToZone: " + cmd + " --> failed!", err);
                });
            }

            assignSoundsuitAccountToZone(userId, playerId) {
                // audio/cfg/servicecfg/set/soundsuit/<user>/asdefault/player/<PlayerID1>[,<PlayerID2>]
                let cmd = Commands.format(MusicServerEnum.Commands.SERVICE_ASSIGN_ZONE_SOUNDSUIT, userId, playerId);
                Debug.Media.ServiceHandler && console.log(this.name, "assignSoundsuitAccountToZone: userId=" + userId + ", zoneId=" + playerId + " => cmd=" + cmd);
                return this._sendCmd(cmd).then((resp) => {
                    Debug.Media.ServiceHandler && console.log(this.name, "assignSoundsuitAccountToZone: " + cmd + " --> success!", resp);
                }, (err) => {
                    Debug.Media.ServiceHandler && console.error(this.name, "assignSoundsuitAccountToZone: " + cmd + " --> failed!", err);
                });
            }

            /**
             * Returns a listing of the currently configured music service accounts
             * @returns {*}
             */
            getCurrentServices() {
                var result = {};

                if (this.currentServicesDeferred != null) {
                    return this.currentServicesDeferred;
                }

                this.currentServicesDeferred = Q.defer();

                if (this.services && this.services.length) {
                    result.data = this.services; // resolve async - some screens will require a promise in order to work (processAsync in Menu)

                    setTimeout(function () {
                        this.currentServicesDeferred.resolve(this.services);
                        this.currentServicesDeferred = null;
                    }.bind(this), 0);
                } else {
                    // only request if the services have changed or are not known yet.
                    this._requestServices();
                }

                result.promise = this.currentServicesDeferred.promise;
                return result;
            }

            /**
             * Returns a list of services that are available, which means that new service accounts may be added.
             * @returns {*}
             */
            getAvailableServices() {
                var result = {};

                if (this.availableServicesDeferred != null) {
                    return this.availableServicesDeferred;
                }

                this.availableServicesDeferred = Q.defer();

                if (this.availableServices) {
                    result.data = this.availableServices; // resolve async - some screens will require a promise in order to work (processAsync in Menu)

                    setTimeout(function () {
                        this.availableServicesDeferred.resolve(this.availableServices);
                        this.availableServicesDeferred = null;
                    }.bind(this), 0);
                } else {
                    // only request if the availableServices have changed or are not known yet.
                    this._requestAvailableServices();
                }

                result.promise = this.availableServicesDeferred.promise;
                return result;
            }

            getRadios() {
                var result = {};

                if (this.radiosDeferred instanceof Q.defer) {
                    return this.radiosDeferred;
                }

                this.radiosDeferred = Q.defer();

                if (this.radios) {
                    result.data = this.radios; // notifiy does not work here, no listener yet
                } // everytime someone requests current services, load them!


                this._requestRadios();

                result.promise = this.radiosDeferred.promise;
                return result;
            }

            getServiceName(cmd) {
                var service = this._searchArrayForService(this.services, cmd);

                if (!service) {
                    service = this._searchArrayForService(this.availableServices, cmd);
                }

                if (service) {
                    return service.name;
                } else if (cmd === MusicServerEnum.Target.LIBRARY) {
                    return _("media.library");
                } else {
                    console.error("Service '" + cmd + "' was neither found in current nor available services!");
                    return cmd;
                }
            }

            getServiceIcon(cmd, user) {
                var service = this._searchArrayForService(this.services, cmd, user),
                    result;

                if (service) {
                    result = service[MusicServerEnum.Attr.SERVICE.ICON];
                } else if (cmd === MusicServerEnum.Target.LIBRARY) {
                    result = Icon.AudioZone.LIBRARY_ICON;
                } else if (cmd === MusicServerEnum.Target.RADIO) {
                    result = Icon.AudioZone.TUNEIN_ICON;
                } else if (cmd === MusicServerEnum.Target.LMS) {
                    result = null;
                } else {
                    console.error("Service '" + cmd + "' was neither found in current nor available services!");
                    result = this.component.getDefaultIconForUnknown();
                }

                return result;
            }

            isSearchableService(service) {
                var result = false;

                if (service) {
                    // look in the services array for this service
                    result = this._searchArrayForService(this.services, service[MusicServerEnum.Attr.SERVICE.CMD]) !== null;
                    result = result && service[MusicServerEnum.Attr.SERVICE.CMD] === MusicServerEnum.Service.SPOTIFY; // atm only spotify is searchable

                    if (!result && this.component.Feature.SEARCH_RADIO) {
                        // maybe it's a radio? all radios are searchable
                        result = this._searchArrayForService(this.radios, service[MusicServerEnum.Attr.SERVICE.CMD]) !== null;
                    }
                }

                return result;
            }

            /**
             * Checks whether or not the "cmd" attribute given is one of the radios or not. Needed in the search.
             * @param cmd the cmd attribute returned by the getradios-cmd (or the getservices cmd)
             * @returns {boolean} wether or not it's a radio
             */
            isRadio(cmd) {
                return cmd === MusicServerEnum.SearchLoc.RADIO || this._searchArrayForService(this.radios, cmd) !== null;
            }

            /**
             * Checks whether or not the service provided is a proper music service like spotify, google music or
             * if it's a regular service like the radios. This check is necessary as here new services can be checked
             * too, since the list of current and available services is available here.
             * @param service
             * @returns {boolean}
             */
            isMusicService(service) {
                var isService,
                    cmd = service[MusicServerEnum.Attr.SERVICE.CMD];

                try {
                    // if that is a music service, it has to be either in the list of currentl services (accounts)
                    isService = this._searchArrayForService(this.services, cmd) !== null; // or in the available services list

                    isService = isService || this._searchArrayForService(this.availableServices, cmd) !== null;
                } catch (ex) {
                    console.error("isMusicService check did fail. Assuming that it is not a music service!");
                    isService = false;
                }

                return isService;
            }

            // region custom radio stream handling

            addCustomStream(name, url, icon = null) {
                Debug.Media.ServiceHandler && console.log(this.name, "addCustomStream: name=" + name + ", url=" + url + ", icon=" + !!icon);
                var cmd = Commands.format(MusicServerEnum.Commands.CUSTOM_STREAM.ADD, encodeURIComponent(name), encodeURIComponent(url));
                return this._sendRadioCmd(cmd).then((result) => {
                    var id = result.id;

                   if (id && icon) {
                        Debug.Media.ServiceHandler && console.log(this.name, "addCustomStream: name=" + name + " --> created, now upload icon!");
                        return this.setCustomStreamIcon(id, icon).then((result) => {
                            return id;
                        }, () => {
                           console.error(this.name, "addCustomStream: name=" + name + " Icon Upload failed, but don't bother - will work without an icon too!");
                           return id;
                        });

                    } else if (id) {
                        Debug.Media.ServiceHandler && console.log(this.name, "addCustomStream: name=" + name + " --> created, no icon used -> done");
                        return id;
                    } else {
                        return Q.reject("Failed to add radio, no ID returned!");
                    }
                });
            }

            updateCustomStream(id, name, url, icon = null) {
                Debug.Media.ServiceHandler && console.log(this.name, "updateCustomStream: id=" + id + ", name=" + name + ", url=" + url + ", icon=" + !!icon);
                var cmd = Commands.format(MusicServerEnum.Commands.CUSTOM_STREAM.UPDATE,
                    encodeURIComponent(id), encodeURIComponent(name), encodeURIComponent(url));
                return this._sendRadioCmd(cmd).then((result) => {
                    if (icon) {
                       Debug.Media.ServiceHandler && console.log(this.name, "updateCustomStream: id=" + id + ", name=" + name + " --> updated, now update icon!");
                       return this.setCustomStreamIcon(id, icon).then((result) => {
                            return id;
                        }, () => {
                           console.error(this.name, "updateCustomStream: id=" + id + ", name=" + name + " Icon Upload failed, but don't bother - will work without an icon too!");
                           return id;
                        });

                    } else {
                       Debug.Media.ServiceHandler && console.log(this.name, "updateCustomStream: id=" + id + ", name=" + name + " --> created, no icon used -> done");
                       return id;
                    }
                });
            }

            removeCustomStream(id) {
                var cmd = Commands.format(MusicServerEnum.Commands.CUSTOM_STREAM.DELETE, encodeURIComponent(id));
                return this._sendRadioCmd(cmd);
            }

            setCustomStreamIcon(id, iconB64) {
                Debug.Media.ServiceHandler && console.log(this.name, "setCustomStreamIcon: " + id);
                var uploadPayload = this._extractPayloadFromB64(iconB64),
                    def = Q.defer(),
                    url = this.component.connectionUrl + Commands.format(MusicServerEnum.Commands.CUSTOM_STREAM.SET_COVER, encodeURIComponent(id));

                // Due to a bug on the Audioserver we can't upload the file locally in a direct peer to peer connection
                // with HTTPS, there seems to be an issue with the HTTP2 implementation
                if (this.component.isP2PConnection) {
                    url = url.replace("https://", "http://");
                }

                Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.AUDIO_HTTP, url, uploadPayload.buffer);
                $.ajax({
                    xhr: () => {
                        var xhr = new window.XMLHttpRequest(); //Upload progress

                        xhr.upload.addEventListener("progress", (evt) => {
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total * 100;
                            }
                        }, false);
                        return xhr;
                    },
                    url: url,
                    type: 'PUT',
                    data: uploadPayload.buffer,
                    processData: false,
                    contentType: uploadPayload.contentType,
                    dataType: "json"
                }).done((response) => {
                    Debug.Media.ServiceHandler && console.log(this.name, "setCustomStreamIcon: " + id + " - success! " + JSON.stringify(response));
                    def.resolve(response.radios_result);
                }).fail((xhr, errorText, error) => {
                    console.error(this.name, "setCustomStreamIcon: " + id + " - failed", error);
                    console.error(error);
                    def.reject(errorText);
                });

                return def.promise;
            }

            _sendRadioCmd(cmd) {
                return this._sendCmd(cmd).then((result) => {
                    var rawPayload = result ? (result.radios_result || result.data) : null;
                    if (rawPayload && (!rawPayload.hasOwnProperty("error") || rawPayload.error === 0)) {
                        return rawPayload;
                    } else {
                        return Q.reject(rawPayload || result);
                    }
                })
            }

            _sendCmd(cmd) {
                this._cmdDeferreds = this._cmdDeferreds || {};
                if (this._cmdDeferreds.hasOwnProperty(cmd)) {
                    return this._cmdDeferreds[cmd].promise;
                }

                this._cmdDeferreds[cmd] = Q.defer();
                Debug.Communication && CommTracker.track(this._cmdDeferreds[cmd].promise, CommTracker.Transport.AUDIO_SOCKET, cmd);
                this.channel.emit(this.component.ECEvent.SendMessage, {
                    cmd: cmd,
                    auto: true
                });

                return this._cmdDeferreds[cmd].promise;
            }

            _extractPayloadFromB64(iconB64) {
                var rootParts = iconB64.split(";base64,"),
                    b64hdr = rootParts[0],
                    buffer = base64StringToArrayBuffer(rootParts[1]),
                    contentType = b64hdr.split(":")[1];
                return {
                    contentType,
                    buffer
                };
            }

            // endregion


            // -------------------------------------------------------------------------
            // PRIVATE
            // -------------------------------------------------------------------------
            _handleServiceCfgError(eventData) {
                var popup = eventData[0];
                popup.icon = Icon.INFO;
                popup.color = window.Styles.colors.green; // no more yellow popups! bad contrast

                popup.message = _("media.popup.service.unauthorized.message", {
                    user: popup.user
                });
                popup.buttonOk = _("media.service.auth-error.show");

                if (popup.hasOwnProperty('cmd')) {
                    switch (popup.cmd) {
                        case MusicServerEnum.Service.SPOTIFY:
                            popup.message += "<br><br>";
                            popup.message += _("media.popup.service.unauthorized.hint.spotify");
                            break;

                        case MusicServerEnum.Service.GOOGLE:
                            popup.message += "<br><br>";
                            popup.message += _("media.popup.service.unauthorized.hint.google");
                            break;

                        default:
                            break;
                    }
                }

                popup.buttonCancel = _("okay");

                if (this.serviceErrorPopup) {
                    // a new serviceconfigerror was received while the old one wasn't handled.
                    // dismiss the old one first
                    NavigationComp.removePopup(this.serviceErrorPopup);
                    this.serviceErrorPopup = null;
                }

                this.serviceErrorPopup = NavigationComp.showPopup(popup);
                this.serviceErrorPopup.done(function (buttonId) {
                    Debug.Media.ServiceHandler && console.log(this.name, "popup responded with " + JSON.stringify(buttonId));

                    this._showServiceEditor(popup);
                }.bind(this), function () {// do nothing;
                });
            }

            /**
             * Deals with a serviceChanged event. ServiceChanged is broadcasted via centralCommNode receiving a
             * reloadmusicapp_event with a service as cause. It'll reset the stored services & inform everyone who
             * needs to know about it.
             * @param id        unused - will always be "ServiceChanged"
             * @param event     the object containing the service, user and action that caused the change.
             * @private
             */
            _handleServiceChanged(id, event) {
                var action = event[ReloadServiceEvent.ACTION],
                    service = event[ReloadServiceEvent.SERVICE],
                    user = event[ReloadServiceEvent.USER]; // reset the stored services

                if (action === "serviceschanged") {
                    // when changing accounts the event is always "serviceschanged", but the app only reloads on
                    // "userchanged".
                    action = MusicServerEnum.ReloadCause.USER_CHANGED;
                }

                this.services = null;
                this.availableServices = null;

                this._requestServices();

                this._requestAvailableServices(); // emit so registered listeners know they need to reload their data.


                this.emit(SERVICE_CHANGED, this.services, service, user, action);
            }

            /**
             * Searches for a service in a given array (availableServices or activeServices)
             * @param serviceArr        where to search for the service
             * @param serviceCmd        the cmd for the service (e.g. local/spotify/googlemusic)
             * @param [serviceUser]     optionally the user can be specified too, only for availableServices
             * @returns {*}             the service object if found, null otherwise
             * @private
             */
            _searchArrayForService(serviceArr, serviceCmd, serviceUser) {
                if (!serviceArr) {
                    return null;
                }

                var result = null;
                serviceArr.some(function (service) {
                    if (service[MusicServerEnum.Attr.SERVICE.CMD] === serviceCmd) {
                        if (!serviceUser || service[MusicServerEnum.Attr.SERVICE.USER] === serviceUser) {
                            result = service;
                            return true;
                        }

                        return false;
                    }
                });
                return result;
            }

            // -------------------------------------------------------------------------
            // Acquire Data

            /**
             * Requests the services currently configured on this music server
             * Either it requests them right away or it stores a deferred to request them when the connection
             * is ready.
             * @private
             */
            _requestServices() {
                if (this.socketIsOpen) {
                    this.channel.emit(this.component.ECEvent.SendMessage, {
                        cmd: MusicServerEnum.Commands.SERVICES.GET,
                        auto: true
                    });
                } else {
                    // create a deferred that resolves with this very method once the connection is ready.
                    this._pendingServicesRq = Q.defer();

                    this._pendingServicesRq.promise.done(this._requestServices.bind(this));
                }
            }

            /**
             * Requests the services that can still be added to the Music Server.
             * Either right away or it stores a deferred to request them when the connection is ready.
             * @private
             */
            _requestAvailableServices() {
                if (this.socketIsOpen) {
                    this.channel.emit(this.component.ECEvent.SendMessage, {
                        cmd: MusicServerEnum.Commands.SERVICES.GET_AVAILABLE,
                        auto: true
                    });
                } else {
                    // create a deferred that resolves with this very method once the connection is ready.
                    this._pendingAvServicesRq = Q.defer();

                    this._pendingAvServicesRq.promise.done(this._requestAvailableServices.bind(this));
                }
            }

            /**
             * Either requests the radios right away or it stores a deferred to request them when the connection
             * is ready.
             * @private
             */
            _requestRadios() {
                if (this.socketIsOpen) {
                    this.channel.emit(this.component.ECEvent.SendMessage, {
                        cmd: MusicServerEnum.Commands.RADIOS.GET,
                        auto: true
                    });
                } else {
                    // create a deferred that resolves with this very method once the connection is ready.
                    this._pendingRadiosRq = Q.defer();

                    this._pendingRadiosRq.promise.done(this._requestRadios.bind(this));
                }
            }

            // -------------------------------------------------------------------------
            // Handle Extension Channel Events
            _handleResult(result) {
                if (!result.hasOwnProperty("command")) {// nothing to do
                } else if (result.command === MusicServerEnum.Commands.SERVICES.GET) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "getservices - result received: " + JSON.stringify(result));
                    this.services = result.data;
                    this.services = this._sanitizeServices(this.services);

                    if (this.currentServicesDeferred) {
                        this.currentServicesDeferred.resolve(this.services);
                        this.currentServicesDeferred = null;
                    }
                } else if (result.command === MusicServerEnum.Commands.SERVICES.GET_AVAILABLE) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "getavailableservices - result received: " + JSON.stringify(result));
                    this.availableServices = result.data; // just like regular services, also sanitize availableServices (result is in same format, so the
                    // method can be reused)

                    this.availableServices = this._sanitizeServices(this.availableServices);

                    if (this.availableServicesDeferred) {
                        this.availableServicesDeferred.resolve(this.availableServices);
                        this.availableServicesDeferred = null;
                    }
                } else if (result.command === MusicServerEnum.Commands.RADIOS.GET) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "result received: " + JSON.stringify(result));
                    this.radios = this._sanitizeRadios(result.data);

                    if (this.radiosDeferred) {
                        this.radiosDeferred.resolve(this.radios);
                        this.radiosDeferred = null;
                    }
                } else if (this._cmdDeferreds && this._cmdDeferreds.hasOwnProperty(result.command)) {
                    Debug.Media.ServiceHandler && console.log(this.name, "result received: " + JSON.stringify(result), this._cmdDeferreds);
                    try {
                        this._cmdDeferreds[result.command].resolve(result);
                    } catch (ex) {
                        console.error(this.name, "_handleResult >> failed to resolve for " + JSON.stringify(result));
                    }
                    delete this._cmdDeferreds[result.command];
                }
            }

            _handleResultError(error) {
                if (!error.hasOwnProperty("command")) {// nothing to do
                } else if (error.command === MusicServerEnum.Commands.SERVICES.GET) {
                    // get services result received
                     console.log(this.name, "error received: " + JSON.stringify(error));
                    this.services = null;

                    if (this.currentServicesDeferred) {
                        this.currentServicesDeferred.reject();
                        this.currentServicesDeferred = null;
                    }
                } else if (error.command === MusicServerEnum.Commands.SERVICES.GET_AVAILABLE) {
                    // get services result received
                    console.log(this.name, "error received: " + JSON.stringify(error));
                    this.availableServices = null;

                    if (this.availableServicesDeferred) {
                        this.availableServicesDeferred.reject();
                        this.availableServicesDeferred = null;
                    }
                } else if (error.command === MusicServerEnum.Commands.RADIOS.GET) {
                    // get services result received
                    console.log(this.name, "error received: " + JSON.stringify(error));
                    this.radios = null;

                    if (this.radiosDeferred) {
                        this.radiosDeferred.reject();
                        this.radiosDeferred = null;
                    }
                } else if (this._cmdDeferreds && this._cmdDeferreds.hasOwnProperty(error.command)) {
                    console.log(this.name, "error received: " + JSON.stringify(error));
                    this._cmdDeferreds[error.command].reject(error);
                    delete this._cmdDeferreds[error.command];
                }
            }

            _handleConnectionEstablished() {
                this.socketIsOpen = true; // Now that the socket is open, are there pending requests we need to process now?

                this._pendingServicesRq && this._pendingServicesRq.resolve();
                this._pendingRadiosRq && this._pendingRadiosRq.resolve();
                this._pendingAvServicesRq && this._pendingAvServicesRq.resolve();
                this._pendingServicesRq = null;
                this._pendingRadiosRq = null;
                this._pendingAvServicesRq = null;
            }

            _handleConnectionClosed() {
                this.services = null;
                this.availableServices = null;
                this.radios = null;
                this.socketIsOpen = false;
            }

            _sanitizeServices(services) {
                if (!services) {
                    return null;
                }
                Debug.Media.ServiceHandler && console.log(this.name, "sanitizeServices: " + services.length, services);

                for (var i = 0; i < services.length; i++) {
                    this._createUidForService(services[i]);

                    var curr = services[i];

                    switch (curr[MusicServerEnum.Attr.SERVICE.CMD]) {
                        case MusicServerEnum.Service.SPOTIFY:
                            curr[MusicServerEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/Icon-Spotify.svg";
                            curr[MusicServerEnum.Attr.SERVICE.HELPLINK] = MusicServerEnum.ServiceHelpLinks.SPOTIFY;
                            break;

                        case MusicServerEnum.Service.SOUNDSUIT:
                            curr[MusicServerEnum.Attr.SERVICE.ICON] = Icon.AudioZone.SOUNDSUIT;
                            curr[MusicServerEnum.Attr.SERVICE.HELPLINK] = MusicServerEnum.ServiceHelpLinks.SOUNDSUIT;
                            break;

                        case MusicServerEnum.Service.GOOGLE:
                            curr[MusicServerEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/Icon-Google-Play-Music.svg";
                            curr[MusicServerEnum.Attr.SERVICE.HELPLINK] = MusicServerEnum.ServiceHelpLinks.GOOGLE;
                            break;

                        case MusicServerEnum.Service.AMAZON:
                            curr[MusicServerEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/Icon-Amazon.svg";
                            break;

                        default:
                            break;
                    }

                    if (services[i].hasOwnProperty(MusicServerEnum.Attr.SERVICE.ICON)) {
                        delete services[i][MusicServerEnum.Attr.SERVICE.ICON_EXTERN];
                    }
                }

                return services;
            }

            _sanitizeRadios(radios) {
                if (!radios) {
                    return null;
                }

                for (var i = 0; i < radios.length; i++) {
                    this._createUidForService(radios[i]);

                    radios[i].contentType = MusicServerEnum.MediaContentType.SERVICE;
                    radios[i].type = MusicServerEnum.FileType.LOCAL_DIR;

                    if (!this.component.Feature.PRETTY_RADIO_ICONS) {
                        switch (radios[i][MusicServerEnum.Attr.SERVICE.CMD]) {
                            case MusicServerEnum.Radios.LOCAL:
                                radios[i][MusicServerEnum.Attr.SERVICE.ICON] = Icon.AudioZone.NEW.LOCATION_PIN;
                                break;

                            case MusicServerEnum.Radios.WORLD:
                                radios[i][MusicServerEnum.Attr.SERVICE.ICON] = Icon.AudioZone.NEW.GLOBE;
                                break;

                            case MusicServerEnum.Radios.MUSIC:
                                radios[i][MusicServerEnum.Attr.SERVICE.ICON] = Icon.AudioZone.NEW.GENRE;
                                break;

                            case MusicServerEnum.Radios.NEWS:
                                radios[i][MusicServerEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/icon-radio-news.svg";
                                break;

                            case MusicServerEnum.Radios.PODCAST:
                                radios[i][MusicServerEnum.Attr.SERVICE.ICON] = Icon.AudioZone.NEW.PODCAST;
                                break;

                            case MusicServerEnum.Radios.CUSTOM:
                                radios[i][MusicServerEnum.Attr.SERVICE.ICON] = Icon.AudioZone.NEW.STREAM;
                                break;

                            default:
                                break;
                        }
                    }

                    if (radios[i].hasOwnProperty(MusicServerEnum.Attr.SERVICE.ICON)) {
                        delete radios[i][MusicServerEnum.Attr.SERVICE.ICON_EXTERN];
                    }
                }

                return radios;
            }

            _createUidForService(service) {
                if (service[MusicServerEnum.Attr.SERVICE.CMD] === MusicServerEnum.Service.SOUNDSUIT) {
                    // TODO-woessto: check docs if michael agreed on the proposed change.
                    service[MusicServerEnum.Attr.SERVICE.UID] = service[MusicServerEnum.Attr.SERVICE.CMD];
                    service[MusicServerEnum.Attr.SERVICE.UID] += "/" + service[MusicServerEnum.Attr.SERVICE.ID];
                } else {

                    service[MusicServerEnum.Attr.SERVICE.UID] = service[MusicServerEnum.Attr.SERVICE.CMD];

                    if (service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.USER)) {
                        service[MusicServerEnum.Attr.SERVICE.UID] += "/" + service[MusicServerEnum.Attr.SERVICE.USER];
                    } else {
                        service[MusicServerEnum.Attr.SERVICE.UID] += "/" + MusicServerEnum.NOUSER;
                    }

                }
            }

            _showServiceEditor(errorServiceEvent) {
                Debug.Media.ServiceHandler && console.log(this.name, "_showServiceEditor: " + JSON.stringify(errorServiceEvent));

                if (!this.services) {
                    // no services yet, download them first
                    Debug.Media.ServiceHandler && console.log("   no services yet, download them first");
                    this.getCurrentServices().done(function (services) {
                        Debug.Media.ServiceHandler && console.log("   services downloaded.");

                        var serviceObj = this._findService(services, errorServiceEvent[MusicServerEnum.Attr.SERVICE.CMD], errorServiceEvent[MusicServerEnum.Attr.SERVICE.USER]);

                        this._showEditorWithService(serviceObj);
                    }.bind(this));
                } else {
                    var service = this._findService(this.services, errorServiceEvent[MusicServerEnum.Attr.SERVICE.CMD], errorServiceEvent[MusicServerEnum.Attr.SERVICE.USER]);

                    this._showEditorWithService(service);
                }
            }

            _findService(services, cmd, user) {
                var service, i;

                for (i = 0; i < services.length; i++) {
                    if (services[i][MusicServerEnum.Attr.SERVICE.CMD] === cmd && services[i][MusicServerEnum.Attr.SERVICE.USER] === user) {
                        service = services[i];
                        break;
                    }
                }

                return service;
            }

            _showEditorWithService(service) {
                Debug.Media.ServiceHandler && console.log(this.name, "_showEditorWithService: " + JSON.stringify(service));
                var targetScreen = Controls.AudioZoneV2Control.Enums.ScreenState.SERVICE_LOGIN;
                var details = {
                    service: service
                };
                NavigationComp.showState(targetScreen, details);
            }

        }

        Components.Audioserver.extensions.ServiceHandlerExt = ServiceHandlerExt;
    }
    return Components;
}(window.Components || {});
